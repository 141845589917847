import useAuth from '@HOOKS/useAuth';
import { useMemo } from 'react';
import { GlobalPermission } from '@SERVICES';
import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

function createGlobalPermissionsHook() {
    return function useGlobalPermissions() {
        const { state: { permissions: { isSuperuser, global: globalPermissions } } } = useAuth();

        return useMemo(() => {
            function canActivate(permissions: GlobalPermission[]) {
                return (codename: GLOBAL_PERMISSIONS) => {
                    const foundPermission = permissions.find(
                        (p) => p.codename === codename,
                    );

                    return !!foundPermission;
                };
            }

            function wrap(fn: (arg: (codename: GLOBAL_PERMISSIONS) => boolean) => boolean) {
                if (!globalPermissions) return false;

                if (isSuperuser) return true;

                const activate = canActivate(globalPermissions);

                return fn(activate);
            }

            return {
                canCreateDocuments: wrap((activate) => activate(GLOBAL_PERMISSIONS.CAN_CREATE_DOCUMENTS)),
                canManageVendors: wrap((activate) => activate(GLOBAL_PERMISSIONS.CAN_MANAGE_VENDORS)),
                canViewLogs: wrap((activate) => activate(GLOBAL_PERMISSIONS.CAN_VIEW_LOGS)),
                canManagePermissions: wrap((activate) => activate(GLOBAL_PERMISSIONS.CAN_MANAGE_PERMISSIONS)),
                canEditDocumentDownloadPermissions: wrap((activate) => activate(GLOBAL_PERMISSIONS.CAN_EDIT_DOCUMENT_DOWNLOAD_PERMISSIONS)),
            };
        }, [isSuperuser, globalPermissions]);
    };
}

export default createGlobalPermissionsHook();
