import React from 'react';

import classnames from 'classnames/bind';

import useVariantHistoryModal from '@HOOKS/store/modals/useVariantHistoryModal';

import BaseModal from '@MODALS/BaseModal';
import SimpleLargeModalHeader from '@COMPONENTS/SHARED/SimpleLargeModalHeader';
import BasicHistoryList from '@MODALS/History/components/BasicHistoryList';

import styles from './VariantHistoryModal.module.scss';

const cx: CX = classnames.bind(styles);

function VariantHistoryModal() {
    const {
        state: { data: modalParams },
        actions: variantHistoryModalActions,
    } = useVariantHistoryModal();

    const { variant, isLandscape } = modalParams!;

    function handleClose() {
        variantHistoryModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('variant-history-modal')}>
                <div className={cx('header-wrapper')}>
                    <SimpleLargeModalHeader
                        modalTitle="Variant history"
                        title={variant.name}
                        onClose={() => handleClose()}
                    />
                </div>
                <div className={cx('content')}>
                    <BasicHistoryList
                        versions={variant.versions}
                        isLandscape={isLandscape}
                        paginationLimit={5}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default VariantHistoryModal;
