/* eslint-disable class-methods-use-this */

const VERSION = 10;

const STEPS_KEY = `steps-${process.env.NODE_ENV}`;

interface Data {
    payload: any;

    VERSION: number;
}

class StepsRepository {
    getData(): any {
        const data = localStorage.getItem(STEPS_KEY);

        if (!data) return;

        try {
            const parsedData: Data = JSON.parse(data);

            if (parsedData.VERSION !== VERSION) {
                return this.clear();
            }

            return parsedData.payload;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    setData(data: any): void {
        if (!data) return;

        localStorage.setItem(STEPS_KEY, JSON.stringify({
            payload: data,
            VERSION,
        }));
    }

    clear(): void {
        localStorage.removeItem(STEPS_KEY);
    }
}

export default new StepsRepository();
