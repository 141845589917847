import { AdminSingleChapter } from '@INTERFACES/admin-api/chapters';

export default function getFilteredBySearchData(
    chapterData: AdminSingleChapter,
    searchValue: string,
): AdminSingleChapter {
    const val = searchValue.toLowerCase().trim();

    if (chapterData.data && val !== '') {
        const { data, meta } = chapterData;
        const { sections } = data;

        const filtered = {
            ...data,
            sections: sections.filter((section) => {
                try {
                    return section.name.toLowerCase().match(
                        val,
                    );
                } catch (e) {
                    return false;
                }
            }),
        };

        return {
            data: filtered,
            meta,
        };
    }

    return chapterData;
}
