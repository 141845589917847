import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useLocation } from 'react-router-dom';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import scrollToVariant from '@PAGES/admin/edit-section/helpers/scrollToVariant';
import PageHeader from '@PAGES/admin/edit-section/components/PageHeader';
import VariantsStickyHeader from '@PAGES/admin/edit-section/components/VariantsStickyHeader';
import BaseTextStickyHeader from '@PAGES/admin/edit-section/components/BaseTextStickyHeader';
import Variant from '@PAGES/admin/edit-section/components/Variant';
import SectionVendors from '@PAGES/admin/edit-section/components/SectionVendors';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import FloatingPublishButton from '@PAGES/admin/edit-section/components/FloatingPublishButton';
import Mandatory from '@PAGES/admin/edit-section/components/Mandatory';
import BaseText from '@PAGES/admin/edit-section/components/BaseText';
import SectionTags from '@PAGES/admin/edit-section/components/SectionTags';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        view, top, sectionData, sectionMeta,
    } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions: newVariantModalActions,
    } = useNewVariantModal();

    const { hash } = useLocation();

    const { variants } = sectionData;

    const [isMandatoryOpen, setMandatoryOpen] = useState<boolean>(false);
    const [isSectionTagsOpen, setSectionTagsOpen] = useState<boolean>(false);
    const [isSectionVendorsOpen, setSectionVendorsOpen] = useState<boolean>(false);
    const [isBaseTextOpen, setBaseTextOpen] = useState<boolean>(false);
    const [openVariantsIds, setOpenVariantsIds] = useState<Set<number>>(new Set(hash ? [] : variants.map((v) => v.id)));

    useEffect(() => {
        if (hash) {
            const id = Number(hash.replace('#variant-', ''));

            if (!Number.isNaN(id)) {
                setOpenVariantsIds((set) => {
                    const newSet = new Set(set);

                    newSet.add(id);

                    return newSet;
                });
            }
        }
    }, [hash]);

    useEffect(() => {
        const id = Number(hash.replace('#variant-', ''));

        let timer: any;

        if (!Number.isNaN(id)) {
            timer = setTimeout(() => {
                scrollToVariant(id);
            }, 150);
        }

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cx('content')}>
            <PageHeader
                sectionData={sectionData}
                sectionMeta={sectionMeta}
            />

            {/* <div> */}
            {/*     <StickyHeader */}
            {/*         top={top} */}
            {/*     > */}
            {/*         <div className={cx('mandatory-subheader', 'container')}> */}
            {/*             <div className={cx('title')}> */}
            {/*                 Mandatory */}
            {/*             </div> */}
            {/*             <OpenCloseButton */}
            {/*                 isOpen={isMandatoryOpen} */}
            {/*                 backgroundColor={COLOR['marriott-light-grey']} */}
            {/*                 iconColor={COLOR['app-white']} */}
            {/*                 onClick={() => { */}
            {/*                     setMandatoryOpen((val) => !val); */}
            {/*                 }} */}
            {/*             /> */}
            {/*         </div> */}
            {/*     </StickyHeader> */}

            {/*     { */}
            {/*         isMandatoryOpen */}
            {/*         && ( */}
            {/*             <Mandatory */}
            {/*                 sectionData={sectionData} */}
            {/*                 sectionMeta={sectionMeta} */}
            {/*             /> */}
            {/*         ) */}
            {/*     } */}
            {/* </div> */}

            <div>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('section-tags-subheader', 'container')}>
                        <div className={cx('title')}>
                            Section tags
                        </div>
                        <OpenCloseButton
                            isOpen={isSectionTagsOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setSectionTagsOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isSectionTagsOpen
                    && (
                        <SectionTags
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                        />
                    )
                }
            </div>

            <div>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('section-vendors-subheader', 'container')}>
                        <div className={cx('title')}>
                            Section vendors
                        </div>
                        <OpenCloseButton
                            isOpen={isSectionVendorsOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setSectionVendorsOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isSectionVendorsOpen
                    && (
                        <SectionVendors
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                        />
                    )
                }
            </div>

            <FloatingPublishButton
                sectionData={sectionData}
                sectionMeta={sectionMeta}
                top={top}
                isBaseTextOpen={isBaseTextOpen}
            />

            <div className={cx('base-text-wrapper')}>
                <BaseTextStickyHeader
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                    top={top}
                    isBaseTextOpen={isBaseTextOpen}
                    setBaseTextOpen={setBaseTextOpen}
                />

                {
                    isBaseTextOpen
                    && (
                        <BaseText
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                        />
                    )
                }
            </div>

            <VariantsStickyHeader
                top={top}
                sectionData={sectionData}
                setOpenVariantsIds={setOpenVariantsIds}
            />
            <div className="container">
                <div className={cx('variants-list')}>
                    <div className={cx('add-variant-button')}>
                        <AddButton
                            locked={!canEditDocument}
                            title="New variant"
                            fontSize={36}
                            onClick={() => {
                                newVariantModalActions.open({
                                    documentId: sectionData.document.id,
                                    chapterId: sectionData.chapter.id,
                                    sectionId: sectionData.id,
                                    canEditDocument,
                                    sectionMeta,
                                });
                            }}
                        />
                    </div>

                    {
                        variants.map((variant) => {
                            const key = variant.id;

                            const isVariantOpen = openVariantsIds.has(variant.id);

                            return (
                                <div
                                    key={key}
                                    className={cx('variant-item-wrapper', {
                                        'is-close': !isVariantOpen,
                                    })}
                                >
                                    <Variant
                                        variant={variant}
                                        sectionData={sectionData}
                                        sectionMeta={sectionMeta}
                                        openVariantsIds={openVariantsIds}
                                        setOpenVariantsIds={setOpenVariantsIds}
                                        view={view}
                                    />

                                    {view === 'options' && <div className={cx('delimiter')} />}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

Content.propTypes = {
    view: PropTypes.oneOf(['options', 'sections']).isRequired,
    top: PropTypes.number.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default Content;
