/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleSectionData } from '@INTERFACES/admin-api/sections';

export interface SectionBaseTextHistoryModalState {
    isOpen: boolean;

    data: {
        sectionData: AdminSingleSectionData,
    } | null;
}

export interface SectionBaseTextHistoryModalDispatchParams {
    sectionData: AdminSingleSectionData,
}

const initialState: SectionBaseTextHistoryModalState = {
    isOpen: false,
    data: null,
};

export const sectionBaseTextHistoryModalSlice = createSlice({
    name: 'section-base-text-history-modal',
    initialState,
    reducers: {
        open(state: SectionBaseTextHistoryModalState, action: PayloadAction<SectionBaseTextHistoryModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: SectionBaseTextHistoryModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
