import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';

import styles from './ChapterIntroductionText.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterIntroductionText(props: Props) {
    const { body } = props;

    return (
        <div className={cx('chapter-introduction-text')}>
            <div className={cx('header')}>
                <div className={cx('title')}>
                    Introduction text
                </div>
            </div>
            <div className={cx('body-wrapper')}>
                <DocumentPreviewPage
                    body={body}
                    isLandscape={false}
                />
            </div>
        </div>
    );
}

ChapterIntroductionText.propTypes = {
    body: PropTypes.string.isRequired,
};

type Props = InferProps<typeof ChapterIntroductionText.propTypes>;

export default ChapterIntroductionText;
