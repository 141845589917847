import React, { useEffect, useMemo, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';
import { useAddTagToVariant, useGetAllTags } from '@SERVICES';

import useNewTagInVariantModal from '@HOOKS/store/modals/useNewTagInVariantModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';
import ApplySelectButton from '@COMPONENTS/COMMON/buttons/ApplySelectButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './VariantTagsControls.module.scss';

const cx: CX = classnames.bind(styles);

function VariantTagsControls(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        data: tagsData = { data: [] },
        isLoading: isTagsLoading,
    } = useGetAllTags({
        documentId: sectionData.document.id,
    });

    const [
        addTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useAddTagToVariant();

    const {
        actions: newTagInVariantModalActions,
    } = useNewTagInVariantModal(() => true);

    const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

    useEffect(() => {
        if (isSuccess) {
            notify.info('New tag has been added', {
                toastId: 'add-tag-to-variant',
            });

            setSelectedTagId(null);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-tag-to-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-tag-to-variant-error',
                });
            }
        }
    }, [isError, error]);

    const options = useMemo(() => tagsData.data
        .filter((tag) => !variant.tags.find((t) => t.id === tag.id))
        .map((tag) => ({
            ...tag,
            value: tag.id,
            label: tag.name,
        })), [variant, tagsData.data]);

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedTagId) || null,
        [options, selectedTagId],
    );

    return (
        <div className={cx('variant-tags-controls')}>
            <div className={cx('select-wrapper')}>
                <BasicSelect
                    title="Add tag"
                    placeholder="Select tag"
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="top"
                    isLoading={isTagsLoading}
                    onChange={(val: SingleValue<any>) => {
                        setSelectedTagId(val?.id || null);
                    }}
                />
            </div>
            <div className={cx('buttons-wrapper')}>
                <div className={cx('button')}>
                    <ApplySelectButton
                        tooltip="Add tag"
                        locked={!canManageDocumentTags}
                        isProcessing={isLoading}
                        disabled={selectedTagId === null}
                        onClick={() => {
                            if (selectedTagId !== null) {
                                addTag({
                                    chapterId: sectionData.chapter.id,
                                    sectionId: sectionData.id,
                                    id: variant.id,
                                    tagId: selectedTagId,
                                });
                            }
                        }}
                    />
                </div>
                <div className={cx('button')}>
                    <BasicButton
                        tooltip="Create and add new tag"
                        locked={!canManageDocumentTags}
                        title="+"
                        style={{
                            height: 36,
                            width: 36,
                            fontSize: 22,
                            padding: '0',
                            backgroundColor: COLOR['app-green'],
                        }}
                        onClick={() => {
                            newTagInVariantModalActions.open({
                                documentId: sectionData.document.id,
                                chapterId: sectionData.chapter.id,
                                sectionId: sectionData.id,
                                variantId: variant.id,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantTagsControls;
