import React, {
    MutableRefObject, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { Editor } from '@tinymce/tinymce-react';

import COLOR from '@CONSTANTS/COLOR.constant';
import PDF from '@CONSTANTS/PDF.constant';

import getEditorConfig from '@HELPERS/getEditorConfig';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './IntroductionTextEditor.module.scss';

const cx: CX = classnames.bind(styles);

const PAGE_WIDTH = PDF['pdf-page-width'];

const editorConfig = getEditorConfig('portrait');

function IntroductionTextEditor(props: Props) {
    const {
        initialValue,
        onEditorChange,
    } = props;

    const editorRef: MutableRefObject<any> = useRef(null);

    const [isEditorReady, setEditorReady] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (isEditorReady) {
            const width = PAGE_WIDTH;

            editorRef.current.contentAreaContainer.style.width = `${width}px`;
            editorRef.current.contentAreaContainer.style.maxWidth = `${width}px`;
        }
    }, [isEditorReady]);

    return (
        <>
            <div
                className={cx('introduction-text-editor', {
                    show: isEditorReady,
                })}
            >
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;

                        setEditorReady(true);

                        onEditorChange(editor.getContent());
                    }}
                    initialValue={initialValue}
                    onEditorChange={(content) => {
                        onEditorChange(content || '');
                    }}
                    init={{
                        ...editorConfig,

                        height: 700,
                        min_height: 700,
                    }}
                />
            </div>
            {
                !isEditorReady
                && (
                    <div className={cx('editor-loading')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-primary']}
                            size={40}
                            thickness={4}
                        />
                    </div>
                )
            }
        </>
    );
}

IntroductionTextEditor.propTypes = {
    initialValue: PropTypes.string.isRequired,
    onEditorChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof IntroductionTextEditor.propTypes>;

export default IntroductionTextEditor;
