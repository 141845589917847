import React from 'react';

import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import { useUpdateChapter } from '@SERVICES';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';

function ChapterNameModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useChapterNameModal();

    const [updateChapter, {
        isLoading, isSuccess, isError, error,
    }] = useUpdateChapter();

    const { chapterData } = modalParams!;

    const onSave = (formValues: FormValues) => {
        updateChapter({
            id: chapterData.id,
            documentId: chapterData.document.id,
            name: formValues.name,
            body: chapterData.introductionText.body,
            isMandatory: chapterData.isMandatory,
            image: '',
        });
    };

    return (
        <NameInputModal
            modalTitle="Update chapter name"
            itemName="Chapter"
            inputPlaceholder="Chapter name"
            successMessage="Chapter name has been updated!"
            initialInputValue={chapterData.name}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default ChapterNameModal;
