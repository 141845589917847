import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { SectionHistoryData } from '@SERVICES';

import BaseTextInfo from '@MODALS/History/SectionHistoryModal/components/BaseTextInfo';
import VariantInfo from '@MODALS/History/SectionHistoryModal/components/VariantInfo';
import BasicHistoryList from '@MODALS/History/components/BasicHistoryList';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const { historyData, isLandscape } = props;

    const { id: sectionId, baseText, variants } = historyData;

    return (
        <div className={cx('modal-body')}>
            <div className={cx('pseudo-sidebar')} />

            <div className={cx('history')}>
                <div className={cx('item-wrapper')}>
                    <div className={cx('item-info-wrapper', 'container-left')}>
                        <BaseTextInfo
                            sectionId={sectionId}
                            baseText={baseText}
                        />
                    </div>
                    <div className={cx('item-history-wrapper')}>
                        <BasicHistoryList
                            key={sectionId}
                            versions={baseText.versions}
                            isLandscape={isLandscape}
                            paginationLimit={3}
                        />
                    </div>
                </div>

                {
                    variants.map((variant) => {
                        const key = variant.id;

                        return (
                            <div
                                key={key}
                                className={cx('item-wrapper')}
                            >
                                <div className={cx('item-info-wrapper', 'container-left')}>
                                    <VariantInfo
                                        sectionId={sectionId}
                                        variant={variant}
                                    />
                                </div>
                                <div className={cx('item-history-wrapper')}>
                                    <BasicHistoryList
                                        versions={variant.versions}
                                        isLandscape={isLandscape}
                                        paginationLimit={3}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

ModalBody.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    historyData: SectionHistoryData,
};

export default ModalBody;
