/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleSectionDataVariant } from '@INTERFACES/admin-api/sections';

export interface DeleteVariantModalState {
    isOpen: boolean;

    data: {
        id: number;

        documentId: number;

        chapterId: number;

        sectionId: number;

        variant: AdminSingleSectionDataVariant;
    } | null;
}

export interface DeleteVariantModalDispatchParams {
    id: number;

    documentId: number;

    chapterId: number;

    sectionId: number;

    variant: AdminSingleSectionDataVariant;
}

const initialState: DeleteVariantModalState = {
    isOpen: false,
    data: null,
};

export const deleteVariantModalSlice = createSlice({
    name: 'delete-variant-modal',
    initialState,
    reducers: {
        open(state: DeleteVariantModalState, action: PayloadAction<DeleteVariantModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: DeleteVariantModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
