import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

type AdminRoles = {
    data: {
        id: number;

        name: string;

        value?: number;

        label?: string;
    } [];
};

type RolesApiResponse = {
    id: number;

    name: string;

    value?: number;

    label?: string;
};

function transformGetAllResponse(raw: RolesApiResponse[]) {
    return {
        data: raw.map((role) => ({
            id: role.id,
            name: role.name,
            value: role.id,
            label: role.name,
        })),
    };
}

export const rolesAdminApi = createApi({
    reducerPath: 'roles-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<AdminRoles, void>({
            query: (params) => ({
                url: '/roles/',
                method: 'GET',
            }),
            transformResponse(raw: RolesApiResponse[]) {
                return transformGetAllResponse(raw);
            },
        }),
        create: builder.mutation<any | any[], any>({
            query: (params) => ({
                url: '/roles/add/',
                method: 'POST',
                body: params,
            }),
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllRoles,
    useCreateMutation: useCreateRole,
} = rolesAdminApi;
