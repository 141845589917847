/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PublishChapterIntroductionTextModalState {
    isOpen: boolean;

    data: {
        id: number;

        body: string;

        documentId: number;
    } | null;
}

export interface PublishChapterIntroductionTextModalDispatchParams {
    id: number;

    body: string;

    documentId: number;
}

const initialState: PublishChapterIntroductionTextModalState = {
    isOpen: false,
    data: null,
};

export const publishChapterIntroductionTextModalSlice = createSlice({
    name: 'publish-chapter-introduction-text-modal',
    initialState,
    reducers: {
        open(state: PublishChapterIntroductionTextModalState, action: PayloadAction<PublishChapterIntroductionTextModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PublishChapterIntroductionTextModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
