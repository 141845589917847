import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import getDate from '@HELPERS/getDate';

import styles from './VersionInfo.module.scss';

const cx: CX = classnames.bind(styles);

function VersionInfo(props: Props) {
    const {
        includedToVersionControl,
        version,
    } = props;

    return (
        <div className={cx('version-info')}>
            {
                includedToVersionControl
                    ? (
                        <div className={cx('included-to-version-control')}>
                            Included to version control
                        </div>
                    ) : (
                        <div className={cx('included-to-version-control', 'not-in-control')}>
                            Not included to version control
                        </div>
                    )
            }

            <div className={cx('info')}>
                <div className={cx('info-item')}>
                    <div className={cx('info-title')}>
                        Author
                    </div>

                    <div className={cx('info-data')}>
                        {version?.author.fullName}
                    </div>

                    <div className={cx('info-data')}>
                        {version?.author.email}
                    </div>
                </div>

                {
                    includedToVersionControl
                    && (
                        <div className={cx('info-item')}>
                            <div className={cx('info-title')}>
                                Description
                            </div>

                            <div className={cx('info-data')}>
                                {version?.description}
                            </div>
                        </div>
                    )
                }

                <div className={cx('info-item')}>
                    <div className={cx('info-title')}>
                        Date
                    </div>

                    <div className={cx('info-data')}>
                        {`Published: ${getDate(version?.createdAt)}`}
                    </div>
                </div>
            </div>
        </div>
    );
}

VersionInfo.propTypes = {
    includedToVersionControl: PropTypes.bool.isRequired,
    version: PropTypes.exact({
        description: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        author: PropTypes.exact({
            fullName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

type Props = InferProps<typeof VersionInfo.propTypes>;

export default VersionInfo;
