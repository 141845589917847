/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleSectionData } from '@INTERFACES/admin-api/sections';
import { formatVersion, publishAdminApi } from '@SERVICES';

export interface PublishSectionModalState {
    isOpen: boolean;

    data: {
        sectionData: AdminSingleSectionData,
    } | null;
}

export interface PublishSectionModalDispatchParams {
    sectionData: AdminSingleSectionData,
}

const initialState: PublishSectionModalState = {
    isOpen: false,
    data: null,
};

export const publishSectionModalSlice = createSlice({
    name: 'publish-section-modal',
    initialState,
    reducers: {
        open(state: PublishSectionModalState, action: PayloadAction<PublishSectionModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PublishSectionModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            publishAdminApi.endpoints.publishAll.matchFulfilled,
            (state, action) => {
                const { data } = state;

                const { payload, meta } = action;

                const { arg: { originalArgs } } = meta;

                const { type, data: paramsData } = originalArgs;

                const sectionId = paramsData.sections[0]?.id || paramsData.variants[0]?.sectionId;

                if (type === 'section' && data !== null && sectionId) {
                    const {
                        sections: publishedSections,
                        variants: publishedVariants,
                    } = payload;

                    const variantsIds = publishedVariants.map((v) => v.id);

                    const publishedSection = publishedSections.find((s) => s.id === sectionId);

                    let newSectionVersion;

                    if (publishedSection) {
                        const { version } = publishedSection;

                        newSectionVersion = formatVersion(version);
                    }

                    const newSectionData: AdminSingleSectionData = {
                        ...data.sectionData,
                        baseText: newSectionVersion ? {
                            body: newSectionVersion.body || '',
                            isPublished: true,
                            versions: [...data.sectionData.baseText.versions, newSectionVersion],
                        } : data.sectionData.baseText,
                        variants: data.sectionData.variants.map((variant) => {
                            if (variantsIds.includes(variant.id)) {
                                const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                const { version } = publishedVariant;

                                const newVariantVersion = formatVersion(version);

                                return {
                                    ...variant,
                                    body: newVariantVersion.body || '',
                                    isPublished: true,
                                    versions: [...variant.versions, newVariantVersion],
                                };
                            }

                            return variant;
                        }),
                    };

                    state.data = {
                        sectionData: newSectionData,
                    };
                }
            },
        );
    },
});
