/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminUser } from '@SERVICES';

export interface AddEditUserModalState {
    isOpen: boolean;

    data: {
        user: AdminUser | any
    } | null;
}

export interface AddEditUserModalDispatchParams {
    user: AdminUser | any | null;
}

const initialState: AddEditUserModalState = {
    isOpen: false,
    data: null,
};

export const addEditUserModalSlice = createSlice({
    name: 'add-edit-user-modal',
    initialState,
    reducers: {
        open(state: AddEditUserModalState, action: PayloadAction<AddEditUserModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: AddEditUserModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
