import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminVendorsData } from '@SERVICES';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './VendorsStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VendorsStickyHeader(props: Props) {
    const { top, vendorsData, setOpenVendorsIds } = props;

    return (
        <StickyHeader
            top={top}
        >
            <div className={cx('vendors-sticky-header', 'container')}>
                <div className={cx('vendors-title-wrapper')}>
                    <PairToggleButtons
                        titleOn="Show all"
                        titleOff="Hide all"
                        onClickOn={() => {
                            const vendorsIds = vendorsData.map((v) => v.id);

                            const set = new Set<number>(vendorsIds);

                            setOpenVendorsIds(set);
                        }}
                        onClickOff={() => {
                            setOpenVendorsIds(new Set());
                        }}
                    />
                </div>
            </div>
        </StickyHeader>
    );
}

VendorsStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    setOpenVendorsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VendorsStickyHeader.propTypes> & {
    vendorsData: AdminVendorsData,
};

export default VendorsStickyHeader;
