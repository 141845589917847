import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { deleteChapterModalSlice, DeleteChapterModalDispatchParams, DeleteChapterModalState } from '@REDUCERS';

function createDeleteChapterModalHook() {
    return function useDeleteChapterModal(equalityFn?: EqualityFn<DeleteChapterModalState>) {
        const state = useAppSelector((store) => store.deleteChapterModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DeleteChapterModalDispatchParams) => dispatch(deleteChapterModalSlice.actions.open(params)),
            close: () => dispatch(deleteChapterModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDeleteChapterModalHook();
