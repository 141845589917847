import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminPreviewChapterSection } from '@SERVICES';

import styles from './SectionStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function SectionStickyHeader(props: Props) {
    const { top, section } = props;

    return (
        <div
            className={cx('section-sticky-header')}
            style={{
                top,
            }}
        >
            <div className={cx('header-wrapper')}>
                <div className={cx('title')}>
                    <span className={cx('title-prefix')}>
                        Section
                    </span>

                    {section.name}
                </div>
            </div>
        </div>
    );
}

SectionStickyHeader.defaultProps = {
    top: 0,
};

SectionStickyHeader.propTypes = {
    top: PropTypes.number,
};

type Props = InferProps<typeof SectionStickyHeader.propTypes> & typeof SectionStickyHeader.defaultProps & {
    section: AdminPreviewChapterSection,
};

export default SectionStickyHeader;
