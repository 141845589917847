import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@STORE/index';

import { tagNameModalSlice, TagNameModalDispatchParams, TagNameModalState } from '@REDUCERS';

function createTagNameModalHook() {
    return function useTagNameModal(equalityFn?: EqualityFn<TagNameModalState>) {
        const state = useAppSelector((store) => store.tagNameModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: TagNameModalDispatchParams) => dispatch(tagNameModalSlice.actions.open(params)),
            close: () => dispatch(tagNameModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createTagNameModalHook();
