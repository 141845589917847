import React from 'react';

import classnames from 'classnames/bind';

import { AdminSingleChapterDataSectionVariant } from '@INTERFACES/admin-api/chapters';

import Warning from '@COMPONENTS/SHARED/Warning';

import styles from './Variant.module.scss';

const cx: CX = classnames.bind(styles);

function getVariantWarning(variant: AdminSingleChapterDataSectionVariant) {
    if (variant.latestVersion === null) {
        return 'Variant is not published';
    }

    return 'Variant has unpublished changes';
}

function Variant(props: Props) {
    const {
        variant,
    } = props;

    return (
        <div className={cx('variant')}>

            {
                !variant.isPublished
                && (
                    <div className={cx('variant-warning-wrapper')}>
                        <Warning text={getVariantWarning(variant)} />
                    </div>
                )
            }

            <div className={cx('variant-item-header')}>
                <div className={cx('variant-item-title')}>
                    <div className={cx('title')}>
                        {variant.name}
                    </div>
                </div>
                {
                    variant.isDefault
                    && (
                        <div className={cx('is-default-title')}>
                            Default
                        </div>
                    )
                }
            </div>

            {
                !variant.isDefault
                && (
                    <div className={cx('variant-item-properties')}>
                        {
                            [
                                {
                                    title: 'Brand',
                                    data: variant.brand,
                                },
                                {
                                    title: 'Region',
                                    data: variant.region,
                                },
                                {
                                    title: 'Area',
                                    data: variant.area,
                                },
                                {
                                    title: 'Country',
                                    data: variant.country,
                                },
                                {
                                    title: 'Type',
                                    data: variant.propertyType,
                                },
                            ].filter(({ data }) => data !== null)
                                .map((property) => {
                                    const { title, data } = property;

                                    const propertyKey = `${data!.name}-${data!.id}`;

                                    return (
                                        <div
                                            key={propertyKey}
                                            className={cx('property')}
                                        >
                                            <div className={cx('property-title')}>
                                                {title}
                                            </div>
                                            <div className={cx('property-value')}>
                                                {data!.name}
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                )
            }

            {
                variant.tags.length > 0
                && (
                    <div className={cx('variant-item-tags')}>
                        <div className={cx('tags-title')}>
                            Tags
                        </div>
                        <div className={cx('tags')}>
                            {
                                variant.tags.map((tag) => {
                                    const tagKey = tag.id;

                                    return (
                                        <div
                                            key={tagKey}
                                            className={cx('tag-item')}
                                        >
                                            {tag.name}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}
type Props = {
    variant: AdminSingleChapterDataSectionVariant,
};

export default Variant;
