/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewTagInChapterModalState {
    isOpen: boolean;

    data: {
        documentId: number;

        chapterId: number;
    } | null;
}

export interface NewTagInChapterModalDispatchParams {
    documentId: number;

    chapterId: number;
}

const initialState: NewTagInChapterModalState = {
    isOpen: false,
    data: null,
};

export const newTagInChapterModalSlice = createSlice({
    name: 'new-tag-in-chapter-modal',
    initialState,
    reducers: {
        open(state: NewTagInChapterModalState, action: PayloadAction<NewTagInChapterModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: NewTagInChapterModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
