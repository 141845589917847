import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import ViewMode from '@MODALS/Previews/components/ViewMode';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './ModalHeader.module.scss';

const cx: CX = classNames.bind(styles);

function ModalHeader(props: Props) {
    const {
        isPublished,
        showUnpublished,
        setShowUnpublished,
    } = props;

    const {
        state: { data: modalParams },
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const { chapterData, canGenerateDocument } = modalParams!;

    function handleClose() {
        previewChapterModalActions.close();
    }

    return (
        <div className={cx('modal-header')}>
            <div className={cx('title-wrapper')}>
                <div className={cx('title')}>
                    Chapter preview
                </div>
                <ViewMode
                    disabled={isPublished}
                    showUnpublished={showUnpublished}
                    setShowUnpublished={setShowUnpublished}
                />
            </div>
            <div className={cx('chapter-name')}>
                {chapterData.name}
            </div>
            <div className={cx('right-block')}>
                <div className={cx('pdf-button-wrapper')}>
                    <GeneratePreviewPDFButton
                        isProcessing={isLoading}
                        canGenerateDocument={canGenerateDocument}
                        onClick={() => {
                            const variants: number[] = [];
                            const sections: number[] = [];

                            chapterData.sections.forEach((s) => {
                                sections.push(s.id);

                                s.variants.forEach((v) => {
                                    variants.push(v.id);
                                });
                            });

                            downloadDocumentPreview({
                                document: chapterData.document.id,
                                variants,
                                sections,
                                chapters: [chapterData.id],
                                isUnpublished: showUnpublished,
                            });
                        }}
                    />
                </div>
                <div className={cx('close-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'close-button')}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
}

ModalHeader.propTypes = {
    isPublished: PropTypes.bool.isRequired,
    showUnpublished: PropTypes.bool.isRequired,
    setShowUnpublished: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ModalHeader.propTypes>;

export default ModalHeader;
