/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface NewDocumentModalState {
    isOpen: boolean;
}

const initialState: NewDocumentModalState = {
    isOpen: false,
};

export const newDocumentModalSlice = createSlice({
    name: 'new-document-modal',
    initialState,
    reducers: {
        open(state: NewDocumentModalState) {
            state.isOpen = true;
        },
        close(state: NewDocumentModalState) {
            state.isOpen = false;
        },
    },
});
