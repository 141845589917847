import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import Crumb from '@COMPONENTS/HEADERS/breadcrumbs/common/Crumb/Crumb.component';

import useStepsValidation from '@VALIDATION/hooks/useStepsValidation';

import styles from './StepBreadcrumbs.module.scss';

const cx: CX = classnames.bind(styles);

const breadcrumbs = [
    {
        name: 'Document',
        step: GenerationSteps.DOCUMENT,
    },
    // {
    //     name: 'Brand',
    //     step: GenerationSteps.BRAND,
    // },
    // {
    //     name: 'Region',
    //     step: GenerationSteps.REGION,
    // },
    // {
    //     name: 'Area',
    //     step: GenerationSteps.AREA,
    // },
    // {
    //     name: 'Country',
    //     step: GenerationSteps.COUNTRY,
    // },
    // {
    //     name: 'Type',
    //     step: GenerationSteps.TYPE,
    // },
    {
        name: 'Options',
        step: GenerationSteps.OPTIONS,
    },
    {
        name: 'Select content',
        step: GenerationSteps.SELECT_CONTENT,
    },
    {
        name: 'Preview',
        step: GenerationSteps.PREVIEW,
    },
    {
        name: 'Download Document',
        step: GenerationSteps.DOWNLOAD_DOCUMENT,
    },
];

function StepBreadcrumbs() {
    const canActivateStep = useStepsValidation();

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={cx('step-breadcrumbs')}>
            <div className="container max-constraint center">
                <div className={cx('content-wrapper')}>
                    {
                        breadcrumbs.map((crumb, i, list) => {
                            const key = crumb.step;
                            const isActive = crumb.step === location.pathname;
                            const isLast = i === list.length - 1;

                            return (
                                <Crumb
                                    key={key}
                                    name={crumb.name}
                                    isActive={isActive}
                                    isLast={isLast}
                                    onClick={() => {
                                        const {
                                            canActivate,
                                        } = canActivateStep(crumb.step);

                                        if (canActivate) {
                                            navigate(crumb.step);
                                        } else {
                                            notify.error('Not all required steps completed');
                                        }
                                    }}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default StepBreadcrumbs;
