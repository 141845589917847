import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { notify } from '@NOTIFICATION/Notificator';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import BaseModal from '@MODALS/BaseModal';

import styles from './DeleteItemModal.module.scss';

const cx: CX = classnames.bind(styles);

function DeleteItemModal(props: Props) {
    const {
        modalTitle,
        name,
        itemName,
        description,
        onDelete,
        onClose,
        isLoading,
        isSuccess,
        isError,
        error,
    } = props;

    useEffect(() => {
        if (isSuccess) {
            notify.info(`${itemName} has been removed`, {
                toastId: 'delete-document',
            });

            onClose();
        }
    }, [itemName, isSuccess, onClose]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-document-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-document-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <BaseModal
            style={{
                top: '30%',
                transform: 'translate(-50%, -30%)',
            }}
            onClose={onClose}
        >
            <div className={cx('delete-item-modal')}>
                <div className={cx('title')}>
                    {modalTitle}
                </div>
                <div className={cx('question')}>
                    Do you really want to
                    {' '}
                    <span className={cx('delete-highlight')}>delete</span>
                    {' '}
                    {itemName.toLowerCase()}
                    {' '}
                    <span className={cx('item-name')}>{name}</span>
                    ?
                </div>
                {
                    description
                    && (
                        <div className={cx('description')}>
                            {description}
                        </div>
                    )
                }
                <div className={cx('answers')}>
                    <div className={cx('button-wrapper')}>
                        <BasicButton
                            title="Yes"
                            isProcessing={isLoading}
                            style={{
                                height: 40,
                                width: '100%',
                                fontSize: 14,
                                backgroundColor: COLOR['marriott-orange'],
                            }}
                            onClick={onDelete}
                        />
                    </div>
                    <div className={cx('button-wrapper')}>
                        <BasicButton
                            title="No"
                            style={{
                                height: 40,
                                width: '100%',
                                fontSize: 14,
                                backgroundColor: COLOR['marriott-light-grey-2'],
                            }}
                            onClick={onClose}
                        />
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}

DeleteItemModal.defaultProps = {
    description: '',
};

DeleteItemModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    description: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof DeleteItemModal.propTypes> & typeof DeleteItemModal.defaultProps & {
    error: FetchBaseQueryError | SerializedError | null | undefined,
};

export default DeleteItemModal;
