import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import BaseTextInfo from '@MODALS/Previews/components/BaseTextInfo';
import VariantInfo from '@MODALS/Previews/components/VariantInfo';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const {
        section,
        showUnpublished,
    } = props;

    const { baseText, variants, isLandscape } = section;

    const baseTextBody = showUnpublished
        ? (baseText.body || '')
        : (baseText.latestVersion?.body || '');

    return (
        <div className={cx('modal-body')}>
            <div className={cx('pseudo-sidebar')} />

            <div className={cx('pages')}>
                <div className={cx('pages-wrapper')}>
                    <div className={cx('item-wrapper')}>
                        <div className={cx('info-wrapper', 'container-left')}>
                            <BaseTextInfo
                                section={section}
                            />
                        </div>

                        <div className={cx('page-wrapper')}>
                            {
                                baseTextBody
                                    ? (
                                        <DocumentPreviewPage
                                            key={section.id + showUnpublished.toString()}
                                            body={baseTextBody}
                                            isLandscape={isLandscape}
                                        />
                                    ) : (
                                        <div className={cx('page-placeholder')} />
                                    )
                            }
                        </div>
                    </div>

                    {
                        variants.map((variant) => {
                            const key = variant.id;

                            const variantBody = showUnpublished
                                ? (
                                    baseTextBody + (variant.body || '')
                                ) : (
                                    baseTextBody + (variant.latestVersion?.body || '')
                                );

                            return (
                                <div
                                    key={key}
                                    className={cx('item-wrapper')}
                                >
                                    <div className={cx('info-wrapper', 'container-left')}>
                                        <VariantInfo
                                            variant={variant}
                                            sectionId={section.id}
                                        />
                                    </div>
                                    <div className={cx('page-wrapper')}>
                                        {
                                            variantBody
                                                ? (

                                                    <DocumentPreviewPage
                                                        key={showUnpublished.toString()}
                                                        body={variantBody}
                                                        isLandscape={isLandscape}
                                                    />
                                                ) : (
                                                    <div className={cx('page-placeholder')} />
                                                )
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

ModalBody.propTypes = {
    showUnpublished: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    section: AdminSingleChapterDataSection,
};

export default ModalBody;
