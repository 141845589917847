/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import {
    authApi, User, UserPermissions, usersAdminApi,
} from '@SERVICES';

import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import userPermissionsRepository from '@REPOSITORIES/local-repository/user-permissions-repository';

type JwtPayload = {
    id: number;

    is_superuser: boolean;

    full_name: string;

    email: string;

    country: string;

    mobile_phone: string;

    office_phone: string;

    title: string;

    permissions: {
        id: number;

        name: string;

        codename: GLOBAL_PERMISSIONS,
    }[],
};

export type AuthState = {
    user: User | undefined;

    permissions: UserPermissions;
};

export function decodeUserToken(token: string | undefined) {
    if (!token) {
        return {
            user: undefined,

            permissions: {
                isSuperuser: false,

                global: [],
            },
        };
    }

    const decoded: JwtPayload = jwt_decode(token);

    return {
        user: {
            id: decoded.id,
            fullName: decoded.full_name,
            email: decoded.email,
            country: decoded.country,
            mobilePhone: decoded.mobile_phone,
            officePhone: decoded.office_phone,
            title: decoded.title,
        },
        permissions: {
            isSuperuser: decoded.is_superuser,
            global: decoded.permissions.map((permission) => ({
                id: permission.id,
                name: permission.name,
                codename: permission.codename,
            })),
        },
    };
}

const initialState: AuthState = {
    user: undefined,

    permissions: {
        isSuperuser: false,

        global: [],
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.user = undefined;
            state.permissions = initialState.permissions;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, action) => {
                const { token } = action.payload;

                const decodedToken = decodeUserToken(token);

                userRepository.setData(token);

                userPermissionsRepository.setData(decodedToken.permissions);

                state.user = decodedToken.user;
                state.permissions = decodedToken.permissions;
            },
        );

        builder.addMatcher(
            usersAdminApi.endpoints.updatePermissions.matchFulfilled,
            (state, action) => {
                if (state.user) {
                    if (state.user.id === action.payload.id) {
                        const token = action.payload?.token;

                        const decodedToken = decodeUserToken(token);

                        userPermissionsRepository.setData(decodedToken.permissions);

                        state.permissions = decodedToken.permissions;
                    }
                }
            },
        );
    },
});
