import React, { MouseEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './TextButton.module.scss';

const cx: CX = classnames.bind(styles);

function TextButton(props: Props) {
    const {
        title, color, fontSize, fontWeight, padding, onClick,
    } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        onClick(event);
    }

    return (
        <button
            className={cx('text-button', 'clear-button')}
            type="button"
            style={{
                color,
                fontSize,
                fontWeight,
                padding,
            }}
            onClick={handleClick}
        >
            {title}
        </button>
    );
}

TextButton.defaultProps = {
    padding: '0 15px',
};

TextButton.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    fontWeight: PropTypes.number.isRequired,
    padding: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof TextButton.propTypes> & typeof TextButton.defaultProps;

export default TextButton;
