import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import styles from './ResultItemTitle.module.scss';

const cx: CX = classnames.bind(styles);

function ResultItemTitle(props: Props) {
    const {
        title,
        onClick,
    } = props;

    return (
        <div className={cx('result-item-title')}>
            <button
                type="button"
                onClick={onClick}
                className={cx('button', 'clear-button')}
            >
                <div className={cx('title')}>
                    {title}
                </div>

                <div className={cx('edit-icon')}>
                    <EditIcon
                        width={16}
                        height={16}
                        fill={COLOR['marriott-light-grey']}
                    />
                </div>
            </button>
        </div>
    );
}

ResultItemTitle.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ResultItemTitle.propTypes>;

export default ResultItemTitle;
