/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleDocumentData } from '@SERVICES';

export interface PreviewDocumentModalState {
    isOpen: boolean;

    data: {
        canGenerateDocument: boolean;

        documentData: AdminSingleDocumentData;
    } | null;
}

export interface PreviewDocumentModalDispatchParams {
    canGenerateDocument: boolean;

    documentData: AdminSingleDocumentData;
}

const initialState: PreviewDocumentModalState = {
    isOpen: false,

    data: null,
};

export const previewDocumentModalSlice = createSlice({
    name: 'preview-document-modal',
    initialState,
    reducers: {
        open(state: PreviewDocumentModalState, action: PayloadAction<PreviewDocumentModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PreviewDocumentModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
