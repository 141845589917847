import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import DocumentResults from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/DocumentResults';
import ChapterResults from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ChapterResults';
import SectionResults from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/SectionResults';

const results = {
    document: DocumentResults,
    chapter: ChapterResults,
    section: SectionResults,
};

function ResultSet(props: Props) {
    const {
        id,
        q,
        searchType,
        setLoading,
        isFocused,
        setFocused,
    } = props;

    const Results = results[searchType];

    return (
        <Results
            id={id}
            q={q}
            setLoading={setLoading}
            isFocused={isFocused}
            setFocused={setFocused}
        />
    );
}

ResultSet.propTypes = {
    id: PropTypes.number.isRequired,
    q: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    setFocused: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ResultSet.propTypes> & {
    searchType: 'document' | 'chapter' | 'section',
};

export default ResultSet;
