import React from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import useAuth from '@HOOKS/useAuth';

import { ReactComponent as LogoutIcon } from '@ICONS/logout-icon.svg';
import { ReactComponent as MarriottLogo } from '@ICONS/Montage-LOGO-NEW.svg';

import styles from './MainHeader.module.scss';

const cx: CX = classnames.bind(styles);

function MainHeader() {
    const {
        actions, state: { user },
    } = useAuth();

    return (
        <div className={cx('main-header')}>
            <div className="container max-constraint center">
                <div className={cx('content-wrapper')}>
                    <div className={cx('title')}>
                        <Link to="/">
                            <MarriottLogo
                                width={120}
                                height="100%"
                            />
                        </Link>
                    </div>

                    <div className={cx('user-wrapper')}>
                        <div className={cx('user-name')}>
                            {user ? user.fullName : '?'}
                        </div>

                        <button
                            type="button"
                            className={cx('clear-button', 'logout-button')}
                            onClick={() => {
                                actions.logout();
                            }}
                        >
                            <LogoutIcon
                                fill={COLOR['marriott-dark-blue']}
                                width={20}
                                height={20}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainHeader;
