import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { sectionsAdminApi } from './sections.admin-api';

export type AdminVendors = {
    data: AdminVendorsData
};

export type AdminVendorsData = AdminVendor[];

export type AdminVendor = {
    id: number;

    name: string;

    company: string;

    address: string;

    email: string;

    phone: string;

    isVisible: boolean;

    products: AdminVendorProduct[];
};

export type AdminVendorProduct = {
    id: number;

    name: string;
};

type GetAllAdminApiResponse = {
    id: number;

    name: string;

    company: string;

    address: string;

    email: string;

    phone: string;

    is_visible: boolean;

    products: {
        id: number;

        name: string;
    }[];
};

type CreateParams = {
    name: string;

    company: string;

    address: string;

    email: string;

    phone: string;

    isVisible: boolean;
};

type UpdateParams = {
    id: number;

    name: string;

    company: string;

    address: string;

    email: string;

    phone: string;

    isVisible: boolean;
};

type DeleteParams = {
    id: number;
};

function transformGetAllResponse(raw: GetAllAdminApiResponse[]): AdminVendors {
    return {
        data: raw.map((vendor) => ({
            id: vendor.id,
            name: vendor.name,
            company: vendor.company,
            address: vendor.address,
            email: vendor.email,
            phone: vendor.phone,
            isVisible: vendor.is_visible,
            products: vendor.products.map((product) => ({
                id: product.id,
                name: product.name,
            })),
        })),
    };
}

export const vendorsAdminApi = createApi({
    reducerPath: 'vendors-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<AdminVendors, void>({
            query: () => ({
                url: '/vendors/',
                method: 'GET',
            }),
            transformResponse(raw: GetAllAdminApiResponse[]) {
                return transformGetAllResponse(raw);
            },
        }),
        create: builder.mutation<GetAllAdminApiResponse, CreateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('name', params.name);
                formData.append('company', params.company);
                formData.append('address', params.address);
                formData.append('email', params.email);
                formData.append('phone', params.phone);

                return ({
                    url: '/vendors/',
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // ADD VENDOR TO VENDORS PAGE
                    dispatch(
                        vendorsAdminApi.util.updateQueryData('getAll', undefined, (draft) => ({
                            data: [...draft.data, {
                                id: queryData.id,
                                name: queryData.name,
                                company: queryData.company,
                                address: queryData.address,
                                email: queryData.email,
                                phone: queryData.phone,
                                isVisible: queryData.is_visible,
                                products: [],
                            }],
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation<GetAllAdminApiResponse, UpdateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('name', params.name);
                formData.append('company', params.company);
                formData.append('address', params.address);
                formData.append('email', params.email);
                formData.append('phone', params.phone);
                formData.append('is_visible', params.isVisible.toString());

                return ({
                    url: `/vendors/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                });
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // UPDATE VENDOR IN VENDORS PAGE
                    dispatch(
                        vendorsAdminApi.util.updateQueryData('getAll', undefined, (draft) => ({
                            data: draft.data.map((vendor) => {
                                if (vendor.id === id) {
                                    return {
                                        ...vendor,
                                        name: queryData.name,
                                        company: queryData.company,
                                        address: queryData.address,
                                        email: queryData.email,
                                        phone: queryData.phone,
                                        isVisible: queryData.is_visible,
                                    };
                                }

                                return vendor;
                            }),
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation<void, DeleteParams>({
            query: (params) => ({
                url: `/vendors/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // DELETE VENDOR FROM VENDORS PAGE
                    dispatch(
                        vendorsAdminApi.util.updateQueryData('getAll', undefined, (draft) => ({
                            data: draft.data.filter((vendor) => vendor.id !== id),
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllVendors,
    useCreateMutation: useCreateVendor,
    useUpdateMutation: useUpdateVendor,
    useDeleteMutation: useDeleteVendor,
} = vendorsAdminApi;
