import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './ResultsCounter.module.scss';

const cx: CX = classnames.bind(styles);

function ResultsCounter(props: Props) {
    const {
        count,
        resultsQuery,
    } = props;

    return (
        <div className={cx('results-counter')}>
            <div className={cx('results-counter-wrapper', 'container')}>
                <div className={cx('count')}>
                    Found pages:
                    {' '}
                    {count}
                </div>

                <div className={cx('results-query')}>
                    Query:
                    {' '}
                    &quot;
                    {resultsQuery}
                    &quot;
                </div>
            </div>
        </div>
    );
}

ResultsCounter.propTypes = {
    count: PropTypes.number.isRequired,
    resultsQuery: PropTypes.string.isRequired,
};

type Props = InferProps<typeof ResultsCounter.propTypes>;

export default ResultsCounter;
