import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ContentChapter } from '@INTERFACES/api/content';

import SquareCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/SquareCheckbox';

import onlyNotMandatorySections from '@PAGES/manager/select-content/helpers/onlyNotMandatorySections';

import styles from './Chapters.module.scss';

const cx: CX = classnames.bind(styles);

function Chapters(props: Props) {
    const {
        chaptersData,
        selectedChaptersIds,
        selectedSectionsIds,
        selectedVariantsIds,
        setSelectedChaptersIds,
        setSelectedSectionsIds,
        setSelectedVariantsIds,
        isPartial,
    } = props;

    return (
        <div className={cx('chapters', 'container')}>
            <div className={cx('chapters-title')}>
                Chapters
            </div>

            <div className={cx('grid')}>
                {
                    chaptersData.map((chapter, i) => {
                        const key = chapter.id;

                        const { sections } = chapter;

                        const checked = selectedChaptersIds.has(chapter.id);

                        const hasMandatory = chapter.isMandatory || chapter.sections.some((s) => s.isMandatory);

                        return (
                            <div
                                key={key}
                                className={cx('grid-item')}
                            >

                                <SquareCheckbox
                                    header={`Chapter ${i + 1}`}
                                    title={chapter.name}
                                    isMandatory={hasMandatory && !isPartial}
                                    checked={checked}
                                    onChange={(val: boolean) => {
                                        const chaptersSet = new Set(selectedChaptersIds);

                                        if (val) {
                                            chaptersSet.add(chapter.id);

                                            setSelectedChaptersIds(chaptersSet);
                                        } else if (!hasMandatory || isPartial) {
                                            chaptersSet.delete(chapter.id);

                                            const sectionsIds = onlyNotMandatorySections(
                                                sections,
                                                isPartial,
                                                chapter.isMandatory,
                                            );

                                            const sectionsSet = new Set(selectedSectionsIds);

                                            sectionsIds.forEach((id) => {
                                                if (sectionsSet.has(id)) {
                                                    sectionsSet.delete(id);
                                                }
                                            });

                                            const availableVariantsIds = sections
                                                .filter((s) => sectionsIds.includes(s.id))
                                                .reduce((acc: number[], section) => {
                                                    const { variants } = section;

                                                    return acc.concat(variants.map((v) => v.id));
                                                }, []);

                                            const variantsSet = new Set(selectedVariantsIds);

                                            availableVariantsIds.forEach((id) => {
                                                variantsSet.delete(id);
                                            });

                                            setSelectedChaptersIds(chaptersSet);
                                            setSelectedSectionsIds(sectionsSet);
                                            setSelectedVariantsIds(variantsSet);
                                        }
                                    }}
                                />
                            </div>

                        );
                    })
                }
            </div>
        </div>
    );
}

Chapters.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedChaptersIds: PropTypes.func.isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
    isPartial: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Chapters.propTypes> & { chaptersData: ContentChapter[] };

export default Chapters;
