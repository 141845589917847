import React, { ChangeEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import styles from './RoundedCheckbox.module.scss';

const cx: CX = classnames.bind(styles);

function RoundedCheckbox(props: Props) {
    const {
        color, checked, disabled, onChange,
    } = props;

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event);
        }
    }

    return (
        <div className={cx('rounded-checkbox')}>
            <label
                className={cx({ disabled })}
            >
                <input
                    className="visually-hidden"
                    type="checkbox"
                    checked={disabled ? false : checked}
                    onChange={handleChange}
                />

                <div
                    className={cx('circle')}
                    style={{
                        backgroundColor: checked ? color : undefined,
                    }}
                />
            </label>
        </div>
    );
}

RoundedCheckbox.defaultProps = {
    color: COLOR['marriott-primary'],
    disabled: false,
};

RoundedCheckbox.propTypes = {
    color: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof RoundedCheckbox.propTypes> & typeof RoundedCheckbox.defaultProps;

export default RoundedCheckbox;
