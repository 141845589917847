import React, { useLayoutEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import ReactPaginate from 'react-paginate';
import classnames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import styles from './Pagination.module.scss';
import COLOR from '@CONSTANTS/COLOR.constant';

const cx: CX = classnames.bind(styles);

function Pagination(props: Props) {
    const {
        pageCount,
        forcePage,
        onPageChange,
    } = props;

    const [page, setPage] = useState<number>(forcePage);

    useLayoutEffect(() => {
        setPage(forcePage);
    }, [forcePage]);

    const pageRangeDisplayed = 3;
    let marginPagesDisplayed = 1;

    // start
    if (page === 0) {
        marginPagesDisplayed = 3;
    } else if (page === 1 || page === 2) {
        marginPagesDisplayed = 2;
    }

    // end
    if (page === pageCount - 3) {
        marginPagesDisplayed = 2;
    } else if (page === pageCount - 2 || page === pageCount - 1) {
        marginPagesDisplayed = 3;
    }

    return (
        <div className={cx('pagination')}>
            <ReactPaginate
                containerClassName={cx('paginate')}
                activeClassName={cx('selected')}
                breakClassName={cx('break')}
                disabledClassName={cx('disabled')}
                breakLabel="..."
                previousLabel={(
                    <div className={cx('prev-icon')}>
                        <ArrowIcon
                            width={14}
                            height={14}
                            fill={COLOR['marriott-dark-grey']}
                        />
                    </div>
                )}
                nextLabel={(
                    <div className={cx('next-icon')}>
                        <ArrowIcon
                            width={14}
                            height={14}
                            fill={COLOR['marriott-dark-grey']}
                        />
                    </div>
                )}
                pageCount={pageCount}
                forcePage={page}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={marginPagesDisplayed}
                onPageChange={({ selected }) => {
                    setPage(selected);

                    onPageChange(selected);
                }}
                renderOnZeroPageCount={() => null}
            />
        </div>
    );
}

Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    forcePage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Pagination.propTypes>;

export default Pagination;
