import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './ChapterStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterStickyHeader(props: Props) {
    const {
        name,
        top,
        zIndex,
    } = props;

    return (
        <StickyHeader
            top={top}
            zIndex={zIndex}
        >
            <div className={cx('chapter-sticky-header', 'container')}>
                <div className={cx('title')}>
                    Chapter
                </div>
                <div className={cx('name')}>
                    {name}
                </div>
            </div>
        </StickyHeader>
    );
}

ChapterStickyHeader.propTypes = {
    name: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
    zIndex: PropTypes.number.isRequired,
};

type Props = InferProps<typeof ChapterStickyHeader.propTypes>;

export default ChapterStickyHeader;
