import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import { ReactComponent as CheckboxIcon } from '@ICONS/checkbox-icon.svg';

import styles from './SectionSubheader.module.scss';

const cx: CX = classnames.bind(styles);

function TrueIcon(props: TrueIconProps) {
    const { color } = props;

    return (
        <div className={cx('true-icon')}>
            <div
                className={cx('icon-wrapper')}
                style={{
                    backgroundColor: color,
                }}
            >
                <CheckboxIcon
                    width={12}
                    fill={COLOR['app-white']}
                />
            </div>
        </div>
    );
}

TrueIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

type TrueIconProps = InferProps<typeof TrueIcon.propTypes>;

function FalseIcon() {
    return (
        <div className={cx('false-icon')}>
            <div className={cx('icon-wrapper')}>
                <div className={cx('icon')}>
                    &times;
                </div>
            </div>
        </div>
    );
}

function SectionSubheader(props: Props) {
    const { section } = props;

    const isSectionPartiallyMandatory = !section.isMandatory && section.hasMandatoryConditions;

    return (
        <div className={cx('section-subheader')}>
            <div className={cx('left-part')}>
                <div className={cx('options')}>
                    {/* <div className={cx('option')}> */}
                    {/*    <div className={cx('title-wrapper')}> */}
                    {/*        <div */}
                    {/*            className={cx('checkbox-title', { */}
                    {/*                active: section.isMandatory || section.hasMandatoryConditions, */}
                    {/*            })} */}
                    {/*        > */}
                    {/*            Mandatory */}
                    {/*        </div> */}

                    {/*        <div className={cx('checkbox-value')}> */}
                    {/*            { */}
                    {/*                section.isMandatory || section.hasMandatoryConditions */}
                    {/*                    ? ( */}
                    {/*                        <TrueIcon */}
                    {/*                            color={isSectionPartiallyMandatory ? COLOR['app-yellow'] : COLOR['app-green']} */}
                    {/*                        /> */}
                    {/*                    ) : ( */}
                    {/*                        <FalseIcon /> */}
                    {/*                    ) */}
                    {/*            } */}
                    {/*        </div> */}
                    {/*    </div> */}
                    {/*    { */}
                    {/*        isSectionPartiallyMandatory */}
                    {/*        && ( */}
                    {/*            <div className={cx('partially-mandatory')}> */}
                    {/*                * partially */}
                    {/*            </div> */}
                    {/*        ) */}
                    {/*    } */}
                    {/* </div> */}
                    <div className={cx('option')}>
                        <div className={cx('title-wrapper')}>
                            <div
                                className={cx('checkbox-title', {
                                    active: section.isPageBreak,
                                })}
                            >
                                Page brake
                            </div>

                            <div className={cx('checkbox-value')}>
                                {
                                    section.isPageBreak
                                        ? (
                                            <TrueIcon
                                                color={COLOR['app-green']}
                                            />
                                        ) : (
                                            <FalseIcon />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={cx('option')}>
                        <div className={cx('title-wrapper')}>
                            <div
                                className={cx('checkbox-title', {
                                    active: section.isLandscape,
                                })}
                            >
                                Landscape
                            </div>

                            <div className={cx('checkbox-value')}>
                                {
                                    section.isLandscape
                                        ? (
                                            <TrueIcon
                                                color={COLOR['app-green']}
                                            />
                                        ) : (
                                            <FalseIcon />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx('right-part')} />
        </div>
    );
}

type Props = { section: AdminSingleChapterDataSection };

export default SectionSubheader;
