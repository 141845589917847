import React from 'react';
import BaseEditor from '@PAGES/admin/edit-section/components/editors/BaseEditor';
import PropTypes, { InferProps } from 'prop-types';
import { AdminSingleSectionData, AdminSingleSectionDataBaseTextVersion } from '@INTERFACES/admin-api/sections';
import { SingleValue } from 'react-select';

function BaseTextEditor(props: Props) {
    const {
        sectionData,
        selectedVersion,
        onEditorChange,
    } = props;

    const { baseText, isLandscape } = sectionData;

    return (
        <BaseEditor
            initialValue={(selectedVersion ? selectedVersion.body : baseText.body) || ''}
            isLandscape={isLandscape}
            onEditorChange={onEditorChange}
        />
    );
}

BaseTextEditor.propTypes = {
    onEditorChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseTextEditor.propTypes> & {
    sectionData: AdminSingleSectionData,
    selectedVersion: SingleValue<AdminSingleSectionDataBaseTextVersion | undefined>,
};

export default BaseTextEditor;
