import React, { useLayoutEffect, useState } from 'react';

import classnames from 'classnames/bind';

import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import { AdminSingleDocumentData, AdminUser } from '@SERVICES';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';
import DocumentPermissionsControls from '@PAGES/admin/document/components/DocumentPermissionsControls';

import styles from './DocumentPermissions.module.scss';

const cx: CX = classnames.bind(styles);

const permissions = [
    {
        id: 1,
        name: 'Can generate document',
        codename: DOCUMENT_PERMISSIONS.CAN_GENERATE_DOCUMENT,
    },
    {
        id: 2,
        name: 'Can edit document',
        codename: DOCUMENT_PERMISSIONS.CAN_EDIT_DOCUMENT,
    },
    {
        id: 3,
        name: 'Can delete document',
        codename: DOCUMENT_PERMISSIONS.CAN_DELETE_DOCUMENT,
    },
    {
        id: 4,
        name: 'Can publish document',
        codename: DOCUMENT_PERMISSIONS.CAN_PUBLISH_DOCUMENT,
    },
    {
        id: 5,
        name: 'Can manage document tags',
        codename: DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_TAGS,
    },
    {
        id: 6,
        name: 'Can manage document vendors',
        codename: DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_VENDORS,
    },
];

function copyPermissions(data: any) {
    return data.map((user: any) => ({
        ...user,
        permissions: new Set(user.permissions),
    }));
}

export type PermissionItem = {
    id: number;

    fullName: string;

    email: string;

    permissions: Set<DOCUMENT_PERMISSIONS>
};

function DocumentPermissions(props: Props) {
    const {
        documentData,
    } = props;

    const [selectedPermissions, setSelectedPermissions] = useState<PermissionItem[]>([]);

    useLayoutEffect(() => {
        setSelectedPermissions(
            documentData.users.map((user) => ({
                ...user,
                permissions: new Set(user.permissions.map((p) => p.codename)),
            })),
        );
    }, [documentData]);

    const addUser = (user: AdminUser) => {
        const copy = copyPermissions(selectedPermissions);

        copy.push({
            ...user,
            permissions: new Set(permissions.map((p) => p.codename)),
        });

        copy.sort((a: PermissionItem, b: PermissionItem) => a.id - b.id);

        setSelectedPermissions(copy);
    };

    return (
        <div className={cx('document-permissions', 'container')}>
            <div className={cx('permissions-wrapper')}>
                <div className={cx('controls-wrapper')}>
                    <DocumentPermissionsControls
                        documentData={documentData}
                        selectedPermissions={selectedPermissions}
                        addUser={addUser}
                    />
                </div>

                {
                    selectedPermissions.length > 0
                        ? (
                            <div className={cx('users-list')}>
                                {
                                    selectedPermissions.map((user) => {
                                        const userKey = user.id;

                                        return (
                                            <div
                                                key={userKey}
                                                className={cx('user')}
                                            >
                                                <button
                                                    type="button"
                                                    className={cx('clear-button', 'delete-button')}
                                                    onClick={() => {
                                                        const copy = copyPermissions(selectedPermissions);

                                                        setSelectedPermissions(
                                                            copy.filter((u: PermissionItem) => u.id !== user.id),
                                                        );
                                                    }}
                                                >
                                                    &times;
                                                </button>

                                                <div className={cx('user-info')}>
                                                    <div className={cx('user-info-title')}>
                                                        User Info
                                                    </div>

                                                    <div className={cx('data')}>
                                                        {
                                                            [
                                                                {
                                                                    title: 'Name',
                                                                    value: user.fullName,
                                                                },
                                                                {
                                                                    title: 'Email',
                                                                    value: user.email,
                                                                },
                                                            ].map((item) => {
                                                                const key = item.title;

                                                                return (
                                                                    <div
                                                                        key={key}
                                                                        className={cx('data-item')}
                                                                    >
                                                                        <div className={cx('data-title')}>
                                                                            {item.title}
                                                                        </div>
                                                                        <div className={cx('data-value')}>
                                                                            {item.value || '-'}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={cx('permissions-list')}>
                                                    <div className={cx('permissions-title')}>
                                                        Permissions
                                                    </div>

                                                    {
                                                        permissions.map((permission) => {
                                                            const permissionKey = permission.id;

                                                            const checked = user.permissions.has(permission.codename);

                                                            return (
                                                                <div
                                                                    key={permissionKey}
                                                                    className={cx('permission-wrapper')}
                                                                >
                                                                    <BasicCheckbox
                                                                        title={permission.name}
                                                                        checked={checked}
                                                                        onChange={() => {
                                                                            const newList = copyPermissions(selectedPermissions)
                                                                                .map((u: any) => {
                                                                                    if (u.id === user.id) {
                                                                                        if (checked) {
                                                                                            u.permissions.delete(permission.codename);
                                                                                        } else {
                                                                                            u.permissions.add(permission.codename);
                                                                                        }
                                                                                    }

                                                                                    return u;
                                                                                });

                                                                            setSelectedPermissions(newList);
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        ) : (
                            <div className={cx('no-users')}>
                                No users
                            </div>
                        )
                }
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocumentData,
};

export default DocumentPermissions;
