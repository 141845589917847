import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import styles from './OpenCloseButton.module.scss';

const cx: CX = classnames.bind(styles);

function OpenCloseButton(props: Props) {
    const {
        isOpen, onClick, iconColor, backgroundColor,
    } = props;

    function handleClick() {
        onClick();
    }

    return (
        <button
            type="button"
            className={cx('open-close-button', 'clear-button')}
            style={{
                backgroundColor,
            }}
            onClick={handleClick}
        >
            {
                isOpen
                    ? (
                        <div
                            className={cx('collapse')}
                            style={{
                                backgroundColor: iconColor,
                            }}
                        />
                    ) : (
                        <div
                            className={cx('expand')}
                            style={{
                                backgroundColor: iconColor,
                            }}
                        />
                    )
            }
        </button>
    );
}

OpenCloseButton.defaultProps = {
    iconColor: COLOR['marriott-light-grey'],
    backgroundColor: COLOR['app-white'],
};

OpenCloseButton.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string,
};

type Props = InferProps<typeof OpenCloseButton.propTypes> & typeof OpenCloseButton.defaultProps;

export default OpenCloseButton;
