const IS_DEV = process.env.NODE_ENV !== 'production';

export default function getOrigin() {
    const { protocol, hostname } = window.location;

    if (IS_DEV) {
        return `${protocol}//${hostname}:${8000}`;
    }

    return `${protocol}//${hostname}`;
}
