import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import RoundIconButton from '@COMPONENTS/COMMON/buttons/RoundIconButton';

import styles from './TripleCardButtons.module.scss';

const cx: CX = classNames.bind(styles);

function TripleCardButtons(props: Props) {
    const {
        onEdit,
        onCopy,
        onDelete,
        locked,
        processing,
        tooltips,
    } = props;

    const isProcessing = processing?.copy || processing?.edit || processing?.delete;

    return (
        <div className={cx('triple-card-buttons')}>
            <div className={cx('button-wrapper')}>
                <RoundIconButton
                    tooltipTitle={tooltips.edit}
                    locked={locked.edit}
                    icon="edit"
                    onClick={onEdit}
                    isProcessing={processing?.edit}
                    disabled={isProcessing}
                />
            </div>
            <div className={cx('button-wrapper')}>
                <RoundIconButton
                    tooltipTitle={tooltips.copy}
                    locked={locked.copy}
                    icon="copy"
                    onClick={onCopy}
                    isProcessing={processing?.copy}
                    disabled={isProcessing}
                />
            </div>
            <div className={cx('button-wrapper')}>
                <RoundIconButton
                    tooltipTitle={tooltips.delete}
                    locked={locked.delete}
                    icon="delete"
                    onClick={onDelete}
                    isProcessing={processing?.delete}
                    disabled={isProcessing}
                />
            </div>
        </div>
    );
}

TripleCardButtons.defaultProps = {
    locked: {
        copy: false,
        edit: false,
        delete: false,
    },
    processing: {
        copy: false,
        edit: false,
        delete: false,
    },
};

TripleCardButtons.propTypes = {
    locked: PropTypes.exact({
        copy: PropTypes.bool.isRequired,
        edit: PropTypes.bool.isRequired,
        delete: PropTypes.bool.isRequired,
    }),
    onEdit: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    processing: PropTypes.exact({
        copy: PropTypes.bool.isRequired,
        edit: PropTypes.bool.isRequired,
        delete: PropTypes.bool.isRequired,
    }),
    tooltips: PropTypes.exact({
        copy: PropTypes.string.isRequired,
        edit: PropTypes.string.isRequired,
        delete: PropTypes.string.isRequired,
    }).isRequired,
};

type Props = InferProps<typeof TripleCardButtons.propTypes> & typeof TripleCardButtons.defaultProps;

export default TripleCardButtons;
