/* eslint-disable @typescript-eslint/naming-convention */

export enum GLOBAL_PERMISSIONS {
    CAN_CREATE_DOCUMENTS = 'can_create_documents',
    // CAN_DELETE_DOCUMENTS = 'can_delete_documents',
    // CAN_EDIT_DOCUMENTS = 'can_edit_documents', // without publishing
    // CAN_GENERATE_DOCUMENTS = 'can_generate_documents',
    // CAN_PUBLISH_VARIANTS = 'can_publish_variants',
    // CAN_MANAGE_TAGS = 'can_manage_tags', // add/change/delete
    CAN_MANAGE_VENDORS = 'can_manage_vendors', // add/change/delete
    CAN_VIEW_LOGS = 'can_view_logs',
    CAN_MANAGE_PERMISSIONS = 'can_manage_permissions',
    CAN_EDIT_DOCUMENT_DOWNLOAD_PERMISSIONS = 'can_edit_document_download_permissions',
}

export enum DOCUMENT_PERMISSIONS {
    CAN_GENERATE_DOCUMENT = 'can_generate_document',
    CAN_EDIT_DOCUMENT = 'can_edit_document',
    CAN_DELETE_DOCUMENT = 'can_delete_document',
    CAN_PUBLISH_DOCUMENT = 'can_publish_document',
    CAN_MANAGE_DOCUMENT_TAGS = 'can_manage_document_tags',
    CAN_MANAGE_DOCUMENT_VENDORS = 'can_manage_document_vendors',
}
