import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useCreateVariant } from '@SERVICES';

import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';

import BaseModal from '@MODALS/BaseModal';

import VariantForm, { VariantFormValues } from '@PAGES/admin/edit-section/components/VariantForm/VariantForm.component';

import styles from './NewVariantModal.module.scss';

const cx: CX = classnames.bind(styles);

function NewVariantModal() {
    const {
        state: { data: modalParams },
        actions: newVariantModalActions,
    } = useNewVariantModal();

    const [
        createVariant,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateVariant();

    const { sectionMeta, canEditDocument } = modalParams!;

    useEffect(() => {
        if (isSuccess) {
            notify.info('New variant has been created', {
                toastId: 'add-new-variant',
            });

            newVariantModalActions.close();
        }
    }, [isSuccess, newVariantModalActions]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-new-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-new-variant-error',
                });
            }
        }
    }, [isError, error]);

    function handleClose() {
        newVariantModalActions.close();
    }

    return (
        <BaseModal
            style={{ top: 140 }}
            onClose={() => handleClose()}
        >
            <div className={cx('new-variant-modal')}>
                <div className={cx('title')}>
                    Create new variant
                </div>

                <VariantForm
                    locked={!canEditDocument}
                    buttonTitle="Create"
                    isLoading={isLoading}
                    defaultValues={{
                        isDefault: false,
                        brand: null,
                        region: null,
                        area: null,
                        country: null,
                        propertyType: null,
                    }}
                    sectionMeta={sectionMeta}
                    onSave={(formValues: VariantFormValues) => {
                        const { isDefault } = formValues;

                        createVariant({
                            documentId: modalParams!.documentId,
                            chapterId: modalParams!.chapterId,
                            sectionId: modalParams!.sectionId,
                            isVisible: false,
                            body: '',
                            isDefault,
                            brandId: isDefault ? null : formValues.brand,
                            regionId: isDefault ? null : formValues.region,
                            areaId: isDefault ? null : formValues.area,
                            countryId: isDefault ? null : formValues.country,
                            propertyTypeId: isDefault ? null : formValues.propertyType,
                        });
                    }}
                />
            </div>
        </BaseModal>
    );
}

export default NewVariantModal;
