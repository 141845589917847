import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import ResultSet from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultSet';
import SearchbarInput from '@COMPONENTS/COMMON/inputs/input/SearchInput';

import styles from './Searchbar.module.scss';

const cx: CX = classnames.bind(styles);

function Searchbar(props: Props) {
    const {
        id,
        searchType,
    } = props;

    const [value, setValue] = useState<string>('');
    const [debouncedValue, setDebouncedValue] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isFocused, setFocused] = useState<boolean>(false);

    useLayoutEffect(() => {
        setValue('');
        setDebouncedValue('');
    }, [id, searchType]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [value]);

    if (searchType === null) return null;

    function getPlaceholder(): string {
        if (searchType === 'document') {
            return 'Search inside document';
        }

        if (searchType === 'chapter') {
            return 'Search inside chapter';
        }

        if (searchType === 'section') {
            return 'Search inside section';
        }

        return '';
    }

    return (
        <>
            <div className={cx('searchbar')}>
                <SearchbarInput
                    isLoading={isLoading}
                    placeholder={getPlaceholder()}
                    value={value}
                    onChange={(val: string) => {
                        setValue(val);
                    }}
                    onFocus={() => {
                        setFocused(true);
                    }}
                />
            </div>
            <ResultSet
                id={id}
                q={debouncedValue}
                searchType={searchType}
                isFocused={isFocused}
                setFocused={setFocused}
                setLoading={setLoading}
            />
        </>
    );
}

Searchbar.propTypes = {
    id: PropTypes.number.isRequired,
};

type Props = InferProps<typeof Searchbar.propTypes> & {
    searchType: 'document' | 'chapter' | 'section' | null,
};

export default Searchbar;
