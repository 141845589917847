import React from 'react';
import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import { useCreateAndAddTagToVariant } from '@SERVICES';
import useNewTagInVariantModal from '@HOOKS/store/modals/useNewTagInVariantModal';

function NewTagInVariantModal() {
    const {
        state: { data: modalParams },
        actions: newTagInVariantModalActions,
    } = useNewTagInVariantModal();

    const [
        createAndAddTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateAndAddTagToVariant();

    const onSave = (formValues: FormValues) => {
        createAndAddTag({
            documentId: modalParams!.documentId,
            chapterId: modalParams!.chapterId,
            sectionId: modalParams!.sectionId,
            id: modalParams!.variantId,
            tagName: formValues.name,
        });
    };

    return (
        <NameInputModal
            modalTitle="Add new tag"
            itemName="Tag"
            inputPlaceholder="Tag name"
            successMessage="New tag has been created and added to variant!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                newTagInVariantModalActions.close();
            }}
        />
    );
}

export default NewTagInVariantModal;
