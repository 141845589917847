import Mandatory from '@PAGES/manager/select-content/helpers/mandatoryPredicate';
import { ContentChapter } from '@INTERFACES/api/content';

export default function onlyMandatorySections(data: ContentChapter[]) {
    return data.map((chapter: ContentChapter) => {
        const mandatorySections = (
            chapter.isMandatory
                ? chapter.sections
                : chapter.sections.filter(Mandatory)
        );

        return {
            id: chapter.id,
            name: chapter.name,
            order: chapter.order,
            hasBody: chapter.hasBody,
            isMandatory: chapter.isMandatory,
            tags: chapter.tags,
            sections: mandatorySections,
        };
    });
}
