import { useMemo } from 'react';
import { useLogin, useReset } from '@SERVICES';
import { useAppDispatch, useAppSelector } from '@STORE/index';
import { EqualityFn } from 'react-redux';
import { authSlice, AuthState } from '@REDUCERS';

function createAuthHook() {
    return function useAuth(equalityFn?: EqualityFn<AuthState>) {
        const [login, loginData] = useLogin({
            fixedCacheKey: 'login',
        });

        const [reset, resetData] = useReset({
            fixedCacheKey: 'reset',
        });

        const { user, permissions } = useAppSelector((store) => store.auth, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            logout: () => dispatch(authSlice.actions.logout()),
        }), [dispatch]);

        return {
            actions: {
                login,
                reset,
                logout: actions.logout,
            },
            state: {
                user,
                permissions,
                loginData,
                resetData,
            },
        };
    };
}

export default createAuthHook();
