import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminUnpublishedDocument } from '@SERVICES';

import PairStickyHeader from '@MODALS/Publications/PublishDocumentModal/components/PairStickyHeader';
import Section from '@MODALS/Publications/PublishDocumentModal/components/Section';
import IntroductionTextStickyHeader
    from '@MODALS/Publications/PublishDocumentModal/components/IntroductionTextStickyHeader';
import IntroductionText from '@MODALS/Publications/PublishDocumentModal/components/IntroductionText';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        documentData,
        selectedChaptersIds,
        setSelectedChaptersIds,
        selectedSectionsIds,
        setSelectedSectionsIds,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    const { chapters } = documentData;

    return (
        <div className={cx('content')}>
            {
                chapters.map((chapter) => {
                    const chapterKey = chapter.id;

                    const { sections } = chapter;

                    return (
                        <div
                            key={chapterKey}
                        >
                            {
                                !chapter.introductionText.isPublished
                                && (
                                    <div>
                                        <IntroductionTextStickyHeader chapterName={chapter.name} />

                                        <IntroductionText
                                            chapter={chapter}
                                            selectedChaptersIds={selectedChaptersIds}
                                            setSelectedChaptersIds={setSelectedChaptersIds}
                                        />
                                    </div>
                                )
                            }

                            {
                                sections.map((section) => {
                                    const key = section.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('item-wrapper')}
                                        >
                                            <PairStickyHeader
                                                sectionName={section.name}
                                                chapterName={chapter.name}
                                            />
                                            <Section
                                                section={section}
                                                selectedSectionsIds={selectedSectionsIds}
                                                setSelectedSectionsIds={setSelectedSectionsIds}
                                                selectedVariantsIds={selectedVariantsIds}
                                                setSelectedVariantsIds={setSelectedVariantsIds}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

Content.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedChaptersIds: PropTypes.func.isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    documentData: AdminUnpublishedDocument,
};

export default Content;
