import React, { MouseEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './WideButton.module.scss';

const cx: CX = classnames.bind(styles);

function WideButton(props: Props) {
    const { withSidebar, title, onClick } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        onClick(event);
    }

    return (
        <div
            className={cx('wide-button', {
                'with-sidebar': withSidebar,
            })}
        >
            <button
                className="clear-button"
                type="button"
                onClick={(e) => handleClick(e)}
            >
                {title}
            </button>
        </div>
    );
}

WideButton.defaultProps = {
    withSidebar: false,
};

WideButton.propTypes = {
    withSidebar: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof WideButton.propTypes> & typeof WideButton.defaultProps;

export default WideButton;
