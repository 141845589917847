import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import Warning from '@COMPONENTS/SHARED/Warning';
import LatestUpdates from '@MODALS/Previews/components/LatestUpdates';

import styles from './IntroductionTextInfo.module.scss';

const cx: CX = classnames.bind(styles);

type Chapter = {
    id: number;

    introductionText: IntroductionText;
};

type IntroductionText = {
    isPublished: boolean;

    latestVersion: Version;
};

type Version = {
    id: number;

    description: string;

    body: string;

    createdAt: string;

    author: {
        id: number;

        fullName: string;

        email: string;
    };
} | null;

function IntroductionTextInfo(props: Props) {
    const { top, chapter } = props;

    const { introductionText: { isPublished, latestVersion } } = chapter;

    function getWarning() {
        if (latestVersion === null) {
            return 'Introduction text is not published';
        }

        return 'Introduction text has unpublished changes';
    }

    return (
        <div className={cx('introduction-text-info')}>
            <div
                className={cx('info')}
                style={{
                    top,
                }}
            >

                {
                    !isPublished
                    && (
                        <>
                            <div className={cx('warning-wrapper')}>
                                <Warning text={getWarning()} />
                            </div>
                            <div className={cx('delimiter')} />
                        </>
                    )
                }

                <div className={cx('title')}>
                    Introduction text
                </div>

                {
                    latestVersion
                    && (
                        <>
                            <div className={cx('delimiter')} />

                            <LatestUpdates
                                includedToVersionControl={!!latestVersion!.description}
                                version={{
                                    description: latestVersion!.description || '',
                                    createdAt: latestVersion!.createdAt,
                                    author: {
                                        fullName: latestVersion!.author.fullName,
                                        email: latestVersion!.author.email,
                                    },
                                }}
                            />
                        </>
                    )
                }
            </div>
        </div>
    );
}

IntroductionTextInfo.defaultProps = {
    top: 30,
};

IntroductionTextInfo.propTypes = {
    top: PropTypes.number,
};

type Props = InferProps<typeof IntroductionTextInfo.propTypes> & typeof IntroductionTextInfo.defaultProps & {
    chapter: Chapter,
};

export default IntroductionTextInfo;
