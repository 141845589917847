import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { SectionHistoryVariant } from '@SERVICES';

import classNames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import Warning from '@COMPONENTS/SHARED/Warning';

import styles from './VariantInfo.module.scss';

const cx: CX = classNames.bind(styles);

function VariantInfo(props: Props) {
    const { sectionId, variant } = props;

    const navigate = useNavigate();

    const variantOptions = useMemo(() => [
        {
            title: 'Brand',
            data: variant.brand,
        },
        {
            title: 'Region',
            data: variant.region,
        },
        {
            title: 'Area',
            data: variant.area,
        },
        {
            title: 'Country',
            data: variant.country,
        },
        {
            title: 'Type',
            data: variant.propertyType,
        },
    ].filter(({ data }) => data !== null), [variant]);

    function getWarning() {
        if (variant.versions.length === 0) {
            return 'Variant is not published';
        }

        return 'Variant has unpublished changes';
    }

    return (
        <div className={cx('variant-info')}>
            <div className={cx('info')}>
                {
                    !variant.isPublished
                    && (
                        <>
                            <div className={cx('warning-wrapper')}>
                                <Warning text={getWarning()} />
                            </div>
                            <div className={cx('delimiter')} />
                        </>
                    )
                }

                <div className={cx('edit-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'edit-button')}
                        onClick={() => {
                            navigate(ROUTES.admin.sections.id.get(sectionId, variant.id));
                        }}
                    >
                        <EditIcon
                            width={20}
                            height={20}
                        />
                        <div className={cx('edit-button-title')}>
                            Edit variant
                        </div>
                    </button>
                </div>

                {
                    !variant.isDefault
                        ? (
                            <div className={cx('variant-item-properties')}>
                                {
                                    variantOptions.map((property) => {
                                        const { title, data } = property;

                                        const propertyKey = `${data!.name}-${data!.id}`;

                                        return (
                                            <div
                                                key={propertyKey}
                                                className={cx('property')}
                                            >
                                                <div className={cx('property-title')}>
                                                    {title}
                                                </div>
                                                <div className={cx('property-value')}>
                                                    {data!.name}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        ) : (
                            <div className={cx('default-variant')}>
                                Default
                            </div>
                        )
                }
            </div>
        </div>
    );
}

VariantInfo.propTypes = {
    sectionId: PropTypes.number.isRequired,
};

type Props = InferProps<typeof VariantInfo.propTypes> & {
    variant: SectionHistoryVariant,
};

export default VariantInfo;
