import React from 'react';

import classnames from 'classnames/bind';

import styles from './PermissionDenied.module.scss';

const cx: CX = classnames.bind(styles);

function PermissionDenied() {
    return (
        <p className={cx('permission-denied')}>
            Permission denied
        </p>
    );
}

export default PermissionDenied;
