import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@STORE/index';

import { documentNameModalSlice, DocumentNameModalDispatchParams, DocumentNameModalState } from '@REDUCERS';

function createDocumentNameModalHook() {
    return function useDocumentNameModal(equalityFn?: EqualityFn<DocumentNameModalState>) {
        const state = useAppSelector((store) => store.documentNameModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DocumentNameModalDispatchParams) => dispatch(documentNameModalSlice.actions.open(params)),
            close: () => dispatch(documentNameModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDocumentNameModalHook();
