import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { SingleValue } from 'react-select';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant,
    AdminSingleSectionDataVariantVersion,
} from '@INTERFACES/admin-api/sections';

import BaseEditor from '@PAGES/admin/edit-section/components/editors/BaseEditor';

function VariantEditor(props: Props) {
    const {
        variant,
        sectionData,
        selectedVersion,
        onEditorChange,
    } = props;

    const { isLandscape } = sectionData;

    return (
        <BaseEditor
            initialValue={(selectedVersion ? selectedVersion.body : variant.body) || ''}
            isLandscape={isLandscape}
            onEditorChange={onEditorChange}
        />
    );
}

VariantEditor.propTypes = {
    onEditorChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantEditor.propTypes> & {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    selectedVersion: SingleValue<AdminSingleSectionDataVariantVersion | undefined>,
};

export default VariantEditor;
