import React, { useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import { useDeleteTagFromChapter } from '@SERVICES';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import ChapterTagsControls from '@PAGES/admin/edit-chapter/components/ChapterTagsControls';
import TagItem from '@PAGES/admin/edit-section/components/TagItem';

import styles from './ChapterTags.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterTags(props: Props) {
    const { chapterData, chapterMeta } = props;

    const { tags } = chapterData;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagOriginalArgs,
        },
    ] = useDeleteTagFromChapter();

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-chapter',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-chapter-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    return (
        <div className={cx('chapter-tags', 'container')}>
            {
                tags.length > 0
                    ? (
                        <div className={cx('tags-list')}>
                            {
                                tags.map((tag) => {
                                    const key = tag.id;

                                    const isProcessing = deleteTagOriginalArgs?.tagId === tag.id && isDeleteTagLoading;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item-wrapper')}
                                        >
                                            <TagItem
                                                tag={tag}
                                                isProcessing={isProcessing}
                                                locked={!canManageDocumentTags}
                                                onClick={() => {
                                                    deleteTag({
                                                        id: chapterData.id,
                                                        documentId: chapterData.document.id,
                                                        tagId: tag.id,
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : (
                        <div className={cx('no-tags')}>No tags</div>
                    )
            }
            <div className={cx('controls-wrapper')}>
                <ChapterTagsControls
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default ChapterTags;
