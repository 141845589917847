import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSingleDocumentMetaDocument } from '@SERVICES';

import RouteButton from '@COMPONENTS/COMMON/buttons/RouteButton';

import styles from './DocumentSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentSidebar(props: Props) {
    const { documents } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <div className={cx('document-sidebar')}>
            <div className={cx('container-left')}>
                <div className={cx('documents-title')}>
                    Documents
                </div>
                <div className={cx('documents-list')}>
                    {
                        documents.map((doc) => {
                            const key = doc.id;

                            return (
                                <div
                                    key={key}
                                    className={cx('document-list-item')}
                                >
                                    <RouteButton
                                        title={doc.name}
                                        active={+id! === doc.id}
                                        onClick={() => {
                                            navigate(ROUTES.admin.documents.id.get(doc.id));
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    documents: AdminSingleDocumentMetaDocument[],
};

export default DocumentSidebar;
