import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import {
    sectionBaseTextHistoryModalSlice,
    SectionBaseTextHistoryModalDispatchParams,
    SectionBaseTextHistoryModalState,
} from '@REDUCERS';

function createHistoryModalHook() {
    return function useSectionBaseTextHistoryModal(equalityFn?: EqualityFn<SectionBaseTextHistoryModalState>) {
        const state = useAppSelector((store) => store.sectionBaseTextHistoryModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: SectionBaseTextHistoryModalDispatchParams) => dispatch(sectionBaseTextHistoryModalSlice.actions.open(params)),
            close: () => dispatch(sectionBaseTextHistoryModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createHistoryModalHook();
