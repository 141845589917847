import { AdminUsers } from '@SERVICES';

export default function getFilteredBySearchData(
    usersData: AdminUsers,
    searchValue: string,
): AdminUsers {
    const val = searchValue.toLowerCase().trim();

    if (usersData.data && val !== '') {
        const { data } = usersData;

        const filtered = data.filter((user) => {
            try {
                return user.fullName.toLowerCase().match(
                    val,
                );
            } catch (e) {
                return false;
            }
        });

        return {
            data: filtered,
        };
    }

    return usersData;
}
