import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import styles from './ScrollToTopButton.module.scss';

const cx: CX = classnames.bind(styles);

const LIMIT = 1000;

function ScrollToTopButton() {
    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        function onScroll() {
            // if modal open
            if (document.body.style.position === 'fixed') return;

            const { scrollY } = window;

            if (scrollY > LIMIT) {
                if (!isVisible) {
                    setVisible(true);
                }
            } else if (isVisible) {
                setVisible(false);
            }
        }

        onScroll();

        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [isVisible]);

    return (
        <div
            id="scroll-to-top-button"
            className={cx('scroll-to-top-button', 'container', {
                visible: isVisible,
            })}
        >
            <button
                type="button"
                className={cx('clear-button', 'button')}
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
            >
                <ArrowIcon
                    width={20}
                />
            </button>
        </div>
    );
}

export default ScrollToTopButton;
