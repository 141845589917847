/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';
import { formatVersion, publishAdminApi } from '@SERVICES';

export interface PublishChapterModalState {
    isOpen: boolean;

    data: {
        chapterData: AdminSingleChapterData;
    } | null;
}

export interface PublishChapterModalDispatchParams {
    chapterData: AdminSingleChapterData;
}

const initialState: PublishChapterModalState = {
    isOpen: false,
    data: null,
};

export const publishChapterModalSlice = createSlice({
    name: 'publish-chapter-modal',
    initialState,
    reducers: {
        open(state: PublishChapterModalState, action: PayloadAction<PublishChapterModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PublishChapterModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            publishAdminApi.endpoints.publishAll.matchFulfilled,
            (state, action) => {
                const { data } = state;

                const { payload, meta } = action;

                const { arg: { originalArgs } } = meta;

                const { type, data: paramsData } = originalArgs;

                const chapterId = paramsData.chapters[0]?.id || paramsData.sections[0]?.chapterId || paramsData.variants[0]?.chapterId;

                if (type === 'chapter' && data !== null && chapterId) {
                    const {
                        chapters: publishedChapters,
                        sections: publishedSections,
                        variants: publishedVariants,
                    } = payload;

                    const sectionsIds = publishedSections.map((s) => s.id);
                    const variantsIds = publishedVariants.map((v) => v.id);

                    const publishedChapter = publishedChapters.find((c) => c.id === chapterId);

                    let newChapterVersion;

                    if (publishedChapter) {
                        const { version } = publishedChapter;

                        newChapterVersion = formatVersion(version);
                    }

                    const newChapterData: AdminSingleChapterData = {
                        ...data.chapterData,
                        // UPDATE CHAPTER INTRODUCTION TEXT
                        introductionText: newChapterVersion ? {
                            body: newChapterVersion.body || '',
                            isPublished: true,
                            versions: [...data.chapterData.introductionText.versions, newChapterVersion],
                        } : data.chapterData.introductionText,
                        // UPDATE SECTION BASE TEXT
                        sections: data.chapterData.sections.map((section) => {
                            if (sectionsIds.includes(section.id)) {
                                const publishedSection = publishedSections.find((s) => s.id === section.id)!;

                                const { version } = publishedSection;

                                const newSectionVersion = formatVersion(version);

                                return {
                                    ...section,
                                    baseText: {
                                        body: newSectionVersion.body || '',
                                        isPublished: true,
                                        latestVersion: newSectionVersion,
                                    },
                                };
                            }

                            return section;
                        }).map((section) => ({
                            ...section,
                            // UPDATE VARIANTS
                            variants: section.variants.map((variant) => {
                                if (variantsIds.includes(variant.id)) {
                                    const publishedVariant = publishedVariants.find((v) => v.id === variant.id)!;

                                    const { version } = publishedVariant;

                                    const newVariantVersion = formatVersion(version);

                                    return {
                                        ...variant,
                                        body: newVariantVersion.body || '',
                                        isPublished: true,
                                        latestVersion: newVariantVersion,
                                    };
                                }

                                return variant;
                            }),
                        })),
                    };

                    state.data = {
                        chapterData: newChapterData,
                    };
                }
            },
        );
    },
});
