import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleDocument } from '@SERVICES';

import usePublishDocumentModal from '@HOOKS/store/modals/usePublishDocumentModal';
import useOrderChaptersDrawer from '@HOOKS/store/drawers/useOrderChaptersDrawer';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import ChangeOrderButton from '@COMPONENTS/COMMON/buttons/ChangeOrderButton';
import PublishAllButton from '@COMPONENTS/COMMON/buttons/PublishAllButton';

import styles from './ChaptersStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ChaptersStickyHeader(props: Props) {
    const { documentData, top } = props;

    const {
        canEditDocument,
        canPublishDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions: publishDocumentModalActions,
    } = usePublishDocumentModal(() => true);

    const {
        actions: orderChaptersDrawerActions,
    } = useOrderChaptersDrawer(() => true);

    const { chapters } = documentData.data;

    const isPublished = useMemo(() => chapters.every((chapter) => {
        const { introductionText, sections } = chapter;

        return (
            introductionText.isPublished
            && sections.every((section) => {
                const { baseText, variants } = section;

                return (
                    variants.every((variant) => variant.isPublished)
                    && baseText.isPublished
                );
            })
        );
    }), [chapters]);

    return (
        <StickyHeader
            top={top}
        >
            <div className={cx('chapters-sticky-header', 'container')}>
                <div className={cx('title')}>
                    Chapters
                </div>
                <div className={cx('buttons-wrapper')}>
                    <div className={cx('order-chapters-button')}>
                        <ChangeOrderButton
                            onClick={() => {
                                orderChaptersDrawerActions.open({
                                    documentId: documentData.data.id,
                                    chapters: documentData.data.chapters,
                                    canEditDocument,
                                });
                            }}
                        />
                    </div>

                    <div className={cx('delimiter')} />

                    <div className={cx('publish-wrapper')}>
                        <PublishAllButton
                            disabled={isPublished}
                            canPublishDocument={canPublishDocument}
                            onClick={() => {
                                if (!isPublished) {
                                    publishDocumentModalActions.open({
                                        documentData: documentData.data,
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </StickyHeader>
    );
}

ChaptersStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
};

type Props = InferProps<typeof ChaptersStickyHeader.propTypes> & {
    documentData: AdminSingleDocument,
};

export default ChaptersStickyHeader;
