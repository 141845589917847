import {
    Action, Dispatch, Middleware,
} from '@reduxjs/toolkit';

import stepsRepository from '@REPOSITORIES/local-repository/steps-repository';
import { RootState } from '@STORE/index';

const stepsPersistMiddleware: Middleware = ({ getState }) => (next: Dispatch) => (action: Action) => {
    const result = next(action);

    const state: RootState = getState();

    stepsRepository.setData(state.documentGenerationSteps.steps);

    return result;
};

export default stepsPersistMiddleware;
