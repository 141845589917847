import React, { RefObject, useEffect, useRef } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { usePublishChapterIntroductionText } from '@SERVICES';
import usePublishChapterIntroductionTextModal from '@HOOKS/store/modals/usePublishChapterIntroductionTextModal';
import useAuth from '@HOOKS/useAuth';

import BaseModal from '@MODALS/BaseModal';
import PublicationForm, { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './PublishChapterIntroductionTextModal.module.scss';

const cx: CX = classnames.bind(styles);

function PublishChapterIntroductionTextModal() {
    const { state: { user } } = useAuth();

    const {
        state: { data: modalParams },
        actions: publishChapterIntroductionTextModalActions,
    } = usePublishChapterIntroductionTextModal(() => true);

    const [
        publishChapterIntroductionText,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = usePublishChapterIntroductionText();

    const {
        id: chapterId, body, documentId,
    } = modalParams!;

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section base text has been published', {
                toastId: 'publish-chapter-introduction-text',
            });

            publishChapterIntroductionTextModalActions.close();
        }
    }, [isSuccess, publishChapterIntroductionTextModalActions]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'publish-chapter-introduction-text-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'publish-chapter-introduction-text-error',
                });
            }
        }
    }, [isError, error]);

    function onSave(formValues: PublishFormValues, includeToVersionControl: boolean) {
        let description = null;

        if (includeToVersionControl) {
            description = formValues.description;
        }

        publishChapterIntroductionText({
            id: chapterId,
            body,
            description,
            userId: user!.id,
            documentId,
        });
    }

    function handleClose() {
        publishChapterIntroductionTextModalActions.close();
    }

    return (
        <BaseModal
            style={{ top: 160 }}
            onClose={() => handleClose()}
        >
            <div className={cx('publish-chapter-introduction-text-modal')}>
                <div className={cx('title')}>
                    Publish chapter introduction text
                </div>

                <PublicationForm
                    onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                        onSave(formValues, includeToVersionControl);
                    }}
                >
                    <HiddenFormButton ref={formButtonRef} />
                </PublicationForm>

                <div className={cx('footer')}>
                    <BasicButton
                        title="Publish"
                        isProcessing={isLoading}
                        style={{
                            height: 40,
                            width: '100%',
                            fontSize: 14,
                            backgroundColor: COLOR['app-green'],
                        }}
                        onClick={() => {
                            formButtonRef.current?.click();
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PublishChapterIntroductionTextModal;
