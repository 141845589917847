import React, { MouseEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './SquareButton.module.scss';

const cx: CX = classnames.bind(styles);

function SquareButton(props: Props) {
    const {
        title, onClick, highlighted, locked,
    } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        if (!locked) {
            onClick(event);
        }
    }

    return (
        <div
            className={cx({
                'square-button': true,
                highlighted,
                locked,
            })}
        >
            {
                locked
                && (
                    <div className={cx('lock-wrapper')}>
                        <Lock size="large" />
                    </div>
                )
            }

            <button
                className="clear-button"
                type="button"
                onClick={(e) => handleClick(e)}
            >
                <div className={cx('content')}>
                    {title}
                </div>
            </button>
        </div>
    );
}

SquareButton.defaultProps = {
    highlighted: false,
    locked: false,
};

SquareButton.propTypes = {
    locked: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    highlighted: PropTypes.bool,
};

type Props = InferProps<typeof SquareButton.propTypes> & typeof SquareButton.defaultProps;

export default SquareButton;
