/* eslint-disable class-methods-use-this */

const USER_KEY = `user-${process.env.NODE_ENV}`;

const VERSION = 5;

const hour = 60 * 60 * 1000;

interface Data {
    payload: string;

    VERSION: number;

    EXPIRY: number;
}

class UserRepository {
    getData(): string | undefined {
        const data = localStorage.getItem(USER_KEY);

        if (!data) return;

        try {
            const parsedData: Data = JSON.parse(data);

            if (parsedData.VERSION !== VERSION) {
                this.clear();

                return;
            }

            return parsedData.payload;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    setData(data: string): void {
        if (!data) return;

        const now = new Date();

        const EXPIRY = now.getTime() + hour;

        localStorage.setItem(USER_KEY, JSON.stringify({
            payload: data,
            VERSION,
            EXPIRY,
        }));
    }

    isExpired(): boolean {
        const data = localStorage.getItem(USER_KEY);

        if (data) {
            try {
                const now = new Date();

                const parsedData: Data = JSON.parse(data);

                return now.getTime() > parsedData.EXPIRY;
            } catch (e) {
                //
            }
        }

        return true;
    }

    clear(): void {
        localStorage.removeItem(USER_KEY);
    }
}

export default new UserRepository();
