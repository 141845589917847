/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewTagInVariantModalState {
    isOpen: boolean;

    data: {
        documentId: number;

        chapterId: number;

        sectionId: number;

        variantId: number;
    } | null;
}

export interface NewTagInVariantModalDispatchParams {
    documentId: number;

    chapterId: number;

    sectionId: number;

    variantId: number;
}

const initialState: NewTagInVariantModalState = {
    isOpen: false,
    data: null,
};

export const newTagInVariantModalSlice = createSlice({
    name: 'new-tag-in-variant-modal',
    initialState,
    reducers: {
        open(state: NewTagInVariantModalState, action: PayloadAction<NewTagInVariantModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: NewTagInVariantModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
