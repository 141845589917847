import React from 'react';
import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import useNewTagInChapterModal from '@HOOKS/store/modals/useNewTagInChapterModal';
import { useCreateAndAddTagToChapter } from '@SERVICES';

function NewTagInChapterModal() {
    const {
        state: { data: modalParams },
        actions: newTagInChapterModalActions,
    } = useNewTagInChapterModal();

    const [
        createAndAddTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateAndAddTagToChapter();

    const onSave = (formValues: FormValues) => {
        createAndAddTag({
            documentId: modalParams!.documentId,
            id: modalParams!.chapterId,
            tagName: formValues.name,
        });
    };

    return (
        <NameInputModal
            modalTitle="Add new tag"
            itemName="Tag"
            inputPlaceholder="Tag name"
            successMessage="New tag has been created and added to chapter!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                newTagInChapterModalActions.close();
            }}
        />
    );
}

export default NewTagInChapterModal;
