import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newChapterModalSlice, NewChapterModalDispatchParams, NewChapterModalState } from '@REDUCERS';

function createNewChapterModalHook() {
    return function useNewChapterModal(equalityFn?: EqualityFn<NewChapterModalState>) {
        const state = useAppSelector((store) => store.newChapterModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: NewChapterModalDispatchParams) => dispatch(newChapterModalSlice.actions.open(params)),
            close: () => dispatch(newChapterModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewChapterModalHook();
