/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';

export interface ChapterIntroductionTextHistoryModalState {
    isOpen: boolean;

    data: {
        chapterData: AdminSingleChapterData,
    } | null;
}

export interface ChapterIntroductionTextHistoryModalDispatchParams {
    chapterData: AdminSingleChapterData,
}

const initialState: ChapterIntroductionTextHistoryModalState = {
    isOpen: false,
    data: null,
};

export const chapterIntroductionTextHistoryModalSlice = createSlice({
    name: 'chapter-introduction-text-history-modal',
    initialState,
    reducers: {
        open(state: ChapterIntroductionTextHistoryModalState, action: PayloadAction<ChapterIntroductionTextHistoryModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: ChapterIntroductionTextHistoryModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
