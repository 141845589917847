/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleDocumentData } from '@SERVICES';

export interface PublishDocumentModalState {
    isOpen: boolean;

    data: {
        documentData: AdminSingleDocumentData;
    } | null;
}

export interface PublishDocumentModalDispatchParams {
    documentData: AdminSingleDocumentData;
}

const initialState: PublishDocumentModalState = {
    isOpen: false,
    data: null,
};

export const publishDocumentModalSlice = createSlice({
    name: 'publish-document-modal',
    initialState,
    reducers: {
        open(state: PublishDocumentModalState, action: PayloadAction<PublishDocumentModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PublishDocumentModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
