import React from 'react';
import EditUserFormModal from '@MODALS/common/EditUserFormModal';
import NewUserFormModal from '@MODALS/common/NewUserFormModal';
import useAddEditUserModal from '@HOOKS/store/modals/useAddEditUserModal';

function AddEditUserModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useAddEditUserModal();

    return (
        <div>
            {modalParams?.user?.id && (
                <EditUserFormModal
                    user={modalParams?.user}
                    modalTitle="Edit user"
                    onClose={() => {
                        actions.close();
                    }}
                />
            )}
            {!modalParams?.user?.id && (
                <NewUserFormModal
                    modalTitle="Create user"
                    onClose={() => {
                        actions.close();
                    }}
                />
            )}
        </div>
    );
}

export default AddEditUserModal;
