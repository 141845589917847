/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';

export interface PreviewChapterModalState {
    isOpen: boolean;

    data: {
        canGenerateDocument: boolean;

        chapterData: AdminSingleChapterData;
    } | null;
}

export interface PreviewChapterModalDispatchParams {
    canGenerateDocument: boolean;

    chapterData: AdminSingleChapterData;
}

const initialState: PreviewChapterModalState = {
    isOpen: false,

    data: null,
};

export const previewChapterModalSlice = createSlice({
    name: 'preview-chapter-modal',
    initialState,
    reducers: {
        open(state: PreviewChapterModalState, action: PayloadAction<PreviewChapterModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PreviewChapterModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
