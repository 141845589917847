import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './VariantHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VariantHeader(props: Props) {
    const { variant } = props;

    const variantOptions = useMemo(() => [
        {
            id: 1,
            title: 'Brand',
            data: variant.brand,
        },
        {
            id: 2,
            title: 'Region',
            data: variant.region,
        },
        {
            id: 3,
            title: 'Area',
            data: variant.area,
        },
        {
            id: 4,
            title: 'Country',
            data: variant.country,
        },
        {
            id: 5,
            title: 'Type',
            data: variant.propertyType,
        },
    ], [variant]);

    return (
        <div className={cx('variant-header')}>
            {
                !variant.isDefault
                    ? (
                        <div className={cx('variant-properties')}>
                            {
                                variantOptions.map((property) => {
                                    const { id, title, data } = property;

                                    return (
                                        <div
                                            key={id}
                                            className={cx('property')}
                                        >
                                            <div className={cx('property-title')}>
                                                {title}
                                            </div>
                                            <div className={cx('property-value')}>
                                                {data || '-'}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : (
                        <div className={cx('default-variant')}>
                            Default
                        </div>
                    )
            }
        </div>
    );
}

VariantHeader.propTypes = {
    variant: PropTypes.exact({
        isDefault: PropTypes.bool.isRequired,
        brand: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        area: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        propertyType: PropTypes.string.isRequired,
    }).isRequired,
};

type Props = InferProps<typeof VariantHeader.propTypes>;

export default VariantHeader;
