import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as FilterIcon } from '@ICONS/filter-icon.svg';

import styles from './SidebarHeader.module.scss';

const cx: CX = classnames.bind(styles);

function SidebarHeader(props: Props) {
    const { view, setView } = props;

    return (
        <div className={cx('sidebar-header', 'container-left')}>
            <label>
                <input
                    type="radio"
                    name="modal-sidebar-view"
                    checked={view === 'info'}
                    className="visually-hidden"
                    onChange={() => {
                        setView('info');
                    }}
                />
                <div className={cx('content')}>
                    Info
                </div>
            </label>

            <label>
                <input
                    type="radio"
                    name="modal-sidebar-view"
                    checked={view === 'filters'}
                    className="visually-hidden"
                    onChange={() => {
                        setView('filters');
                    }}
                />
                <div className={cx('content')}>
                    <div className={cx('filter-icon')}>
                        <FilterIcon
                            width={16}
                            height={16}
                        />
                    </div>
                    Filters
                </div>
            </label>
        </div>
    );
}

SidebarHeader.propTypes = {
    view: PropTypes.oneOf(['info', 'filters']).isRequired,
    setView: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SidebarHeader.propTypes>;

export default SidebarHeader;
