import Mandatory from '@PAGES/manager/select-content/helpers/mandatoryPredicate';
import { ContentChapter } from '@INTERFACES/api/content';

export default function getInitialChaptersIds(data: ContentChapter[], isPartial: boolean) {
    return data.reduce((acc: number[], chapter: ContentChapter) => {
        const { sections } = chapter;

        const mandatorySections = sections.filter(Mandatory);

        if ((chapter.isMandatory || mandatorySections.length > 0) && !isPartial) {
            acc.push(chapter.id);
        }

        return acc;
    }, []);
}
