import React, { useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './TagCheckbox.module.scss';

const cx: CX = classnames.bind(styles);

function TagCheckbox(props: Props) {
    const { title, checked, onChange } = props;

    const inputId = useId();

    return (
        <div className={cx('tag-checkbox')}>
            <input
                id={inputId}
                type="checkbox"
                className="visually-hidden"
                checked={checked}
                onChange={() => {
                    onChange(!checked);
                }}
            />
            <label htmlFor={inputId}>
                <span>
                    {title}
                </span>
            </label>
        </div>
    );
}

TagCheckbox.propTypes = {
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof TagCheckbox.propTypes>;

export default TagCheckbox;
