import React from 'react';

import { useLocation } from 'react-router-dom';

import classnames from 'classnames/bind';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';
import {
    // AreaStep,
    // BrandStep,
    // CountryStep,
    DocumentStep,
    OptionsStep,
    // RegionStep,
    // TypeStep,
} from '@REDUCERS';

import { generationModes } from '@PAGES/manager/options/Options.page';

import styles from './StepSidebar.module.scss';

const cx: CX = classnames.bind(styles);

const steps = [
    GenerationSteps.DOCUMENT,
    // GenerationSteps.BRAND,
    // GenerationSteps.REGION,
    // GenerationSteps.AREA,
    // GenerationSteps.COUNTRY,
    // GenerationSteps.TYPE,
    GenerationSteps.OPTIONS,
];

function StepSidebar() {
    const {
        services,
    } = useDocumentGenerationSteps();

    const location = useLocation();

    const documentStep: DocumentStep = services.getStepData(GenerationSteps.DOCUMENT);
    // const brandStep: BrandStep = services.getStepData(GenerationSteps.BRAND);
    // const regionStep: RegionStep = services.getStepData(GenerationSteps.REGION);
    // const areaStep: AreaStep = services.getStepData(GenerationSteps.AREA);
    // const countryStep: CountryStep = services.getStepData(GenerationSteps.COUNTRY);
    // const typeStep: TypeStep = services.getStepData(GenerationSteps.TYPE);
    const optionsStep: OptionsStep = services.getStepData(GenerationSteps.OPTIONS);

    const path = (step: GenerationSteps) => {
        let activeDot = false;
        let activeLine = false;

        const activeStepIndex = steps.indexOf(location.pathname as GenerationSteps);
        const currentStepIndex = steps.indexOf(step);

        if (activeStepIndex > currentStepIndex) {
            activeLine = true;
        }

        if (activeStepIndex >= currentStepIndex) {
            activeDot = true;
        }

        return (
            <>
                <div
                    className={cx('dot', {
                        current: step === location.pathname,
                        active: activeDot,
                    })}
                />
                <div
                    className={cx('line', {
                        active: activeLine,
                    })}
                />
            </>
        );
    };

    return (
        <div className={cx('step-sidebar')}>
            <div className={cx('rows')}>
                <div className={cx('row', 'document')}>
                    {path(GenerationSteps.DOCUMENT)}

                    <div className={cx('title')}>
                        Document
                    </div>
                    <div className={cx('values')}>
                        {documentStep.data?.name || '-'}
                    </div>
                </div>
                {/* <div className={cx('row', 'brand')}> */}
                {/*     {path(GenerationSteps.BRAND)} */}

                {/*     <div className={cx('title')}> */}
                {/*         Brand */}
                {/*     </div> */}
                {/*     <div className={cx('values')}> */}
                {/*         {brandStep.data?.name || '-'} */}
                {/*     </div> */}
                {/* </div> */}
                {/* <div className={cx('row', 'region')}> */}
                {/*     {path(GenerationSteps.REGION)} */}

                {/*     <div className={cx('title')}> */}
                {/*         Region */}
                {/*     </div> */}
                {/*     <div className={cx('values')}> */}
                {/*         {regionStep.data?.name || '-'} */}
                {/*     </div> */}
                {/* </div> */}
                {/* <div className={cx('row', 'area')}> */}
                {/*     {path(GenerationSteps.AREA)} */}

                {/*     <div className={cx('title')}> */}
                {/*         Area */}
                {/*     </div> */}
                {/*     <div className={cx('values')}> */}
                {/*         {areaStep.data?.name || '-'} */}
                {/*     </div> */}
                {/* </div> */}
                {/* <div className={cx('row', 'country')}> */}
                {/*     {path(GenerationSteps.COUNTRY)} */}

                {/*     <div className={cx('title')}> */}
                {/*         Country */}
                {/*     </div> */}
                {/*     <div className={cx('values')}> */}
                {/*         {countryStep.data?.name || '-'} */}
                {/*     </div> */}
                {/* </div> */}
                {/* <div className={cx('row', 'type')}> */}
                {/*     {path(GenerationSteps.TYPE)} */}

                {/*     <div className={cx('title')}> */}
                {/*         Type */}
                {/*     </div> */}
                {/*     <div className={cx('values')}> */}
                {/*         {typeStep.data?.name || '-'} */}
                {/*     </div> */}
                {/* </div> */}
                <div className={cx('row', 'options')}>
                    {path(GenerationSteps.OPTIONS)}

                    <div className={cx('title')}>
                        Options
                    </div>
                    <div className={cx('values')}>
                        <div className={cx('value')}>
                            {optionsStep.isComplete ? generationModes.find((m) => m.id === optionsStep.data.id)!.name : '-'}
                        </div>
                        {
                            optionsStep.data.propertyName
                            && (
                                <div className={cx('value', 'property-name')}>
                                    <div className={cx('property-name-title')}>
                                        Property Name
                                    </div>
                                    <div className={cx('property-name-value')}>
                                        {optionsStep.data.propertyName}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(StepSidebar, () => true);
