import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionMeta,
    AdminSingleSectionDataVariant,
    AdminSingleSectionData,
} from '@INTERFACES/admin-api/sections';

import VariantHeader from '@PAGES/admin/edit-section/components/VariantHeader';
import VariantBody from '@PAGES/admin/edit-section/components/VariantBody';

import VariantSidebar from '@COMPONENTS/SIDEBARS/VariantSidebar';

import styles from './Variant.module.scss';

const cx: CX = classnames.bind(styles);

function Variant(props: Props) {
    const {
        variant,
        sectionData,
        sectionMeta,
        openVariantsIds,
        setOpenVariantsIds,
        view,
    } = props;

    const isOpen = openVariantsIds.has(variant.id);

    const hideSidebar = view !== 'options';

    return (
        <>
            <div className={cx('hidden-title')}>
                {variant.name}
            </div>

            <div
                data-variant={variant.id}
                className={cx('variant')}
            >
                <div
                    id={`variant-${variant.id}`}
                    className={cx('anchor')}
                />

                {
                    isOpen
                    && (
                        <div
                            className={cx('fly-sidebar', {
                                hide: hideSidebar,
                            })}
                        >
                            <div className={cx('sticky-element')}>
                                <div className={cx('sticky-element-content')}>
                                    <VariantSidebar
                                        variant={variant}
                                        sectionData={sectionData}
                                        sectionMeta={sectionMeta}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={cx('variant-wrapper')}>
                    <div className={cx('variant-header')}>
                        <VariantHeader
                            variant={variant}
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                            isOpen={isOpen}
                            onOpenToggle={(val: boolean) => {
                                const set = new Set(openVariantsIds);

                                if (val) {
                                    set.add(variant.id);
                                } else {
                                    set.delete(variant.id);
                                }

                                setOpenVariantsIds(set);
                            }}
                        />
                    </div>
                    {
                        isOpen
                        && (
                            <div className={cx('variant-body-wrapper')}>
                                <VariantBody
                                    variant={variant}
                                    sectionData={sectionData}
                                    sectionMeta={sectionMeta}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

Variant.propTypes = {
    openVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setOpenVariantsIds: PropTypes.func.isRequired,
    view: PropTypes.oneOf(['options', 'sections']).isRequired,
};

type Props = InferProps<typeof Variant.propTypes> & {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default Variant;
