import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import getDate from '@HELPERS/getDate';

import styles from './ComparisonPair.module.scss';

const cx: CX = classNames.bind(styles);

function ComparisonPair(props: Props) {
    const {
        createdAt,
        authorName,
        description,
        isLandscape,
        isLast,
        pair,
    } = props;

    const { oldVersion, newVersion } = pair;

    return (
        <div
            className={cx('comparison-pair', {
                'is-last': isLast,
            })}
        >
            <StickyHeader
                top={1}
            >
                <div className={cx('pair-header', 'container')}>
                    <div className={cx('left')}>
                        <div className={cx('date')}>
                            {getDate(createdAt)}
                        </div>
                    </div>
                    <div className={cx('right')}>
                        <div className={cx('author')}>
                            {authorName}
                        </div>
                        {
                            description
                                ? (
                                    <div className={cx('version-control')}>
                                        Included to version control
                                    </div>
                                ) : (
                                    <div className={cx('version-control', 'not-in-control')}>
                                        Not included to version control
                                    </div>
                                )
                        }
                    </div>
                </div>
            </StickyHeader>
            <div className={cx('body-wrapper')}>
                <div className={cx('body')}>
                    <div className={cx('old')}>
                        <div className={cx('pair-body-header')}>
                            Old text
                        </div>
                        <div className={cx('page')}>
                            {
                                oldVersion
                                    ? (
                                        <DocumentPreviewPage
                                            body={oldVersion || ''}
                                            isLandscape={isLandscape}
                                            isPreview
                                        />
                                    ) : (
                                        <div
                                            className={cx('page-placeholder', {
                                                'is-landscape': isLandscape,
                                            })}
                                        />
                                    )
                            }
                        </div>
                    </div>
                    <div className={cx('arrow-wrapper')}>
                        <div className={cx('arrow')}>
                            <ArrowIcon
                                width={18}
                                height={18}
                            />
                        </div>
                    </div>
                    <div className={cx('new')}>
                        <div className={cx('pair-body-header')}>
                            New text
                        </div>
                        <div className={cx('page')}>
                            <DocumentPreviewPage
                                body={newVersion}
                                isLandscape={isLandscape}
                                isPreview
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ComparisonPair.defaultProps = {
    description: '',
    isLast: false,
};

ComparisonPair.propTypes = {
    createdAt: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    description: PropTypes.string,
    isLandscape: PropTypes.bool.isRequired,
    isLast: PropTypes.bool,
};

type Props = InferProps<typeof ComparisonPair.propTypes> & {
    pair: {
        oldVersion: string | null,
        newVersion: string,
    },
};

export default ComparisonPair;
