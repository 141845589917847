import React, { Children, isValidElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Grid.module.scss';

const cx: CX = classnames.bind(styles);

function Grid(props: Props) {
    const {
        width, aspectRatio, children, hasSidebar,
    } = props;

    const childArray = Children.toArray(children).filter(isValidElement);

    return (
        <div
            style={{
                width,
            }}
            className={cx('grid')}
        >
            <div
                className={cx('grid-container', {
                    'has-sidebar': hasSidebar,
                    default: !hasSidebar,
                })}
            >
                {
                    childArray.map((child, i) => {
                        const key = `ku-${i}`;

                        return (
                            <div
                                style={{
                                    aspectRatio,
                                }}
                                className={cx('child-wrap')}
                                key={key}
                            >
                                {child}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

Grid.defaultProps = {
    width: '100%',
    aspectRatio: '1',
    hasSidebar: false,
};

Grid.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    aspectRatio: PropTypes.string,
    hasSidebar: PropTypes.bool,
};

type Props = InferProps<typeof Grid.propTypes> & typeof Grid.defaultProps & OnlyChildrenProps;

export default Grid;
