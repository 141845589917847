import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { SingleValue } from 'react-select';
import classNames from 'classnames/bind';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';
import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';

import styles from './BasicFilters.module.scss';

const cx: CX = classNames.bind(styles);

type Option = {
    value: number;
    label: string;
};

type Filters = {
    brands: Option[],
    regions: Option[],
    areas: Option[],
    countries: Option[],
    propertyTypes: Option[],
};

export type SelectedOptions = {
    brand: SingleValue<Option>;
    region: SingleValue<Option>;
    area: SingleValue<Option>;
    country: SingleValue<Option>;
    propertyType: SingleValue<Option>;
};

function BasicFilters(props: Props) {
    const {
        isDefaultOnly,
        setDefaultOnly,
        filters,
        selectedOptions,
        setSelectedOptions,
    } = props;

    return (
        <div className={cx('basic-filters', 'container-left')}>
            <div className={cx('title')}>
                Filters
            </div>

            <div className={cx('default-only')}>
                <BasicCheckbox
                    title="Default only"
                    fontSize={14}
                    fontWeight={600}
                    checked={isDefaultOnly}
                    onChange={() => {
                        setDefaultOnly((val: boolean) => !val);
                    }}
                />
            </div>

            <div className={cx('properties')}>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Brand"
                        placeholder="Choose Brand"
                        isDisabled={isDefaultOnly}
                        options={filters.brands}
                        value={selectedOptions.brand}
                        onChange={(val: SingleValue<Option>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                brand: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Region"
                        placeholder="Choose Region"
                        isDisabled={isDefaultOnly}
                        options={filters.regions}
                        value={selectedOptions.region}
                        onChange={(val: SingleValue<Option>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                region: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Area"
                        placeholder="Choose Area"
                        isDisabled={isDefaultOnly}
                        options={filters.areas}
                        value={selectedOptions.area}
                        onChange={(val: SingleValue<Option>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                area: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Country"
                        placeholder="Choose Country"
                        isDisabled={isDefaultOnly}
                        options={filters.countries}
                        isSearchable
                        value={selectedOptions.country}
                        onChange={(val: SingleValue<Option>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                country: val,
                            });
                        }}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Type"
                        placeholder="Choose Property Type"
                        isDisabled={isDefaultOnly}
                        options={filters.propertyTypes}
                        value={selectedOptions.propertyType}
                        onChange={(val: SingleValue<Option>) => {
                            setSelectedOptions({
                                ...selectedOptions,
                                propertyType: val,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

BasicFilters.propTypes = {
    isDefaultOnly: PropTypes.bool.isRequired,
    setDefaultOnly: PropTypes.func.isRequired,
    setSelectedOptions: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BasicFilters.propTypes> & {
    filters: Filters,
    selectedOptions: SelectedOptions,
};

export default BasicFilters;
