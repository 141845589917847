import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleDocumentData, useDocumentPreview } from '@SERVICES';

import getFilteredByOptionsPreviewVariants from '@MODALS/Previews/helpers/getFilteredByOptionsPreviewVariants';
import SidebarHeader from '@MODALS/Previews/components/SidebarHeader';
import Content from '@MODALS/Previews/PreviewDocumentModal/compontents/Content';
import BasicFilters from '@MODALS/Previews/components/BasicFilters';
import ChaptersFilters from '@MODALS/Previews/components/ChaptersFilters';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const {
        documentData,
        showUnpublished,
    } = props;

    const {
        data, isLoading, isError, error,
    } = useDocumentPreview(
        documentData.id,
    );

    const previewData = data?.data;
    const previewMeta = data?.meta;

    const [view, setView] = useState<'info' | 'filters'>('info');

    const [isDefaultOnly, setDefaultOnly] = useState<boolean>(false);

    const [selectedOptions, setSelectedOptions] = useState({
        brand: null,
        region: null,
        area: null,
        country: null,
        propertyType: null,
    });

    const [selectedChaptersIds, setSelectedChaptersIds] = useState<Set<number>>(new Set());

    useLayoutEffect(() => {
        if (previewData) {
            setSelectedChaptersIds(
                new Set(previewData.chapters.map((c) => c.id)),
            );
        }
    }, [previewData]);

    const filteredByChaptersIdsData = useMemo(() => {
        if (!previewData) return previewData;

        return {
            ...previewData,
            chapters: previewData.chapters.filter((chapter) => selectedChaptersIds.has(chapter.id)),
        };
    }, [previewData, selectedChaptersIds]);

    const filters = useMemo(() => {
        if (!previewMeta) return previewMeta;

        const { filters: fi } = previewMeta;

        function transform(properties: any[]) {
            return properties.map((property) => ({
                label: property.name,
                value: property.id,
            }));
        }

        return {
            brands: transform(fi.brands),
            regions: transform(fi.regions),
            areas: transform(fi.areas),
            countries: transform(fi.countries),
            propertyTypes: transform(fi.propertyTypes),
        };
    }, [previewMeta]);

    const filteredByOptionsData = useMemo(() => {
        if (!filteredByChaptersIdsData) return filteredByChaptersIdsData;

        const optionsIsNotSelected = (
            selectedOptions.brand === null
            && selectedOptions.region === null
            && selectedOptions.area === null
            && selectedOptions.country === null
            && selectedOptions.propertyType === null
        );

        return {
            ...filteredByChaptersIdsData,
            chapters: filteredByChaptersIdsData.chapters.map((chapter) => ({
                ...chapter,
                sections: chapter.sections.map((section) => {
                    const { variants } = section;

                    return {
                        ...section,
                        variants: getFilteredByOptionsPreviewVariants(variants, selectedOptions),
                    };
                }).filter((section) => (
                    optionsIsNotSelected
                        ? true
                        : section.variants.length > 0
                )),
            })),
        };
    }, [filteredByChaptersIdsData, selectedOptions]);

    const filteredByDefaultData = useMemo(() => {
        if (!filteredByChaptersIdsData || !isDefaultOnly) return filteredByChaptersIdsData;

        return {
            ...filteredByChaptersIdsData,
            chapters: filteredByChaptersIdsData.chapters.map((chapter) => ({
                ...chapter,
                sections: chapter.sections.map((section) => ({
                    ...section,
                    variants: section.variants.filter((v) => v.isDefault),
                })).filter((section) => section.variants.length > 0),
            })),
        };
    }, [filteredByChaptersIdsData, isDefaultOnly]);

    return (
        <div className={cx('modal-body')}>
            <DataLoader
                isLoading={isLoading}
                isError={isError}
                error={error}
            >
                <div className={cx('body')}>
                    <div
                        className={cx('pseudo-sidebar', {
                            'show-filters': view === 'filters',
                        })}
                    >
                        <SidebarHeader
                            view={view}
                            setView={setView}
                        />

                        {
                            view === 'filters'
                            && (
                                <>
                                    <BasicFilters
                                        isDefaultOnly={isDefaultOnly}
                                        setDefaultOnly={setDefaultOnly}
                                        filters={filters!}
                                        selectedOptions={selectedOptions}
                                        setSelectedOptions={setSelectedOptions}
                                    />
                                    <div className={cx('delimiter')} />
                                    <ChaptersFilters
                                        chapters={previewData!.chapters}
                                        selectedChaptersIds={selectedChaptersIds}
                                        setSelectedChaptersIds={setSelectedChaptersIds}
                                    />
                                </>
                            )
                        }
                    </div>

                    <Content
                        previewData={isDefaultOnly ? filteredByDefaultData! : filteredByOptionsData!}
                        showUnpublished={showUnpublished}
                        view={view}
                    />
                </div>
            </DataLoader>
        </div>
    );
}

ModalBody.propTypes = {
    showUnpublished: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    documentData: AdminSingleDocumentData,
};

export default ModalBody;
