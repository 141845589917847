import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { PublishDocumentModalDispatchParams, publishDocumentModalSlice, PublishDocumentModalState } from '@REDUCERS';

function createPublishDocumentModalHook() {
    return function usePublishDocumentModal(equalityFn?: EqualityFn<PublishDocumentModalState>) {
        const state = useAppSelector((store) => store.publishDocumentModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PublishDocumentModalDispatchParams) => dispatch(publishDocumentModalSlice.actions.open(params)),
            close: () => dispatch(publishDocumentModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPublishDocumentModalHook();
