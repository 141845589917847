import React from 'react';
import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import { useUpdateSection } from '@SERVICES';

function SectionNameModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useSectionNameModal();

    const [updateSection, {
        isLoading, isSuccess, isError, error,
    }] = useUpdateSection();

    const { sectionData } = modalParams!;

    const onSave = (formValues: FormValues) => {
        updateSection({
            id: sectionData.id,
            name: formValues.name,
            body: sectionData.baseText.body,
            isMandatory: sectionData.isMandatory,
            isPageBreak: sectionData.isPageBreak,
            isLandscape: sectionData.isLandscape,
            chapterId: sectionData.chapter.id,
            documentId: sectionData.document.id,
        });
    };

    return (
        <NameInputModal
            modalTitle="Update section name"
            itemName="Section"
            inputPlaceholder="Section name"
            successMessage="Section name has been updated!"
            initialInputValue={sectionData.name}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default SectionNameModal;
