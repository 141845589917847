/* eslint-disable no-param-reassign,max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrderChaptersDrawerChapter = {
    id: number;

    name: string;

    order: number;
};

export interface OrderChaptersDrawerState {
    isOpen: boolean;

    data: {
        documentId: number;

        canEditDocument: boolean;

        chapters: OrderChaptersDrawerChapter[];
    } | null;
}

export interface OrderChaptersDrawerDispatchParams {
    documentId: number;

    canEditDocument: boolean;

    chapters: OrderChaptersDrawerChapter[];
}

const initialState: OrderChaptersDrawerState = {
    isOpen: false,
    data: null,
};

export const orderChaptersDrawerSlice = createSlice({
    name: 'order-chapters-drawer',
    initialState,
    reducers: {
        open(state: OrderChaptersDrawerState, action: PayloadAction<OrderChaptersDrawerDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: OrderChaptersDrawerState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
