import React, { useEffect, useMemo } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useDeleteVendorFromVariant } from '@SERVICES';
import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant, AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import VariantVendorsControls from '@PAGES/admin/edit-section/components/VariantVendorsControls';
import VendorItem from '@PAGES/admin/edit-section/components/VendorItem';

import styles from './VariantVendors.module.scss';

const cx: CX = classnames.bind(styles);

function VariantVendors(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    const {
        canManageDocumentVendors,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        deleteVendor,
        {
            isLoading: isDeleteVendorLoading,
            isSuccess: isDeleteVendorSuccess,
            isError: isDeleteVendorError,
            error: deleteVendorError,
            originalArgs: deleteVendorOriginalArgs,
        },
    ] = useDeleteVendorFromVariant();

    useEffect(() => {
        if (isDeleteVendorSuccess) {
            notify.info('Vendor has been deleted', {
                toastId: 'delete-vendor-from-variant',
            });
        }
    }, [isDeleteVendorSuccess]);

    useEffect(() => {
        if (isDeleteVendorError) {
            if ((deleteVendorError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-vendor-from-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-vendor-from-variant-error',
                });
            }
        }
    }, [isDeleteVendorError, deleteVendorError]);

    const visibleVendors = useMemo(
        () => variant.vendors.filter((vendor) => vendor.isVisible),
        [variant],
    );

    return (
        <div className={cx('variant-vendors')}>
            <div className={cx('title')}>
                Vendors
            </div>
            {
                visibleVendors.length
                    ? (
                        <div className={cx('vendors-list')}>
                            {
                                visibleVendors.map((vendor) => {
                                    const key = vendor.id;

                                    const isProcessing = deleteVendorOriginalArgs?.vendorId === vendor.id && isDeleteVendorLoading;

                                    const showWarning = !!sectionData.vendors.find((v) => v.id === vendor.id);

                                    return (
                                        <div
                                            key={key}
                                            className={cx('vendor-item-wrapper')}
                                        >
                                            <VendorItem
                                                vendor={vendor}
                                                isProcessing={isProcessing}
                                                locked={!canManageDocumentVendors}
                                                showWarning={showWarning}
                                                onClick={() => {
                                                    deleteVendor({
                                                        sectionId: sectionData.id,
                                                        id: variant.id,
                                                        vendorId: vendor.id,
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : (
                        <div className={cx('no-vendors')}>No vendors</div>
                    )
            }
            <div className={cx('new-vendor-wrapper')}>
                <VariantVendorsControls
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantVendors;
