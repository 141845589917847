import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import {
    chapterIntroductionTextHistoryModalSlice,
    ChapterIntroductionTextHistoryModalDispatchParams,
    ChapterIntroductionTextHistoryModalState,
} from '@REDUCERS';

function createHistoryModalHook() {
    return function useChapterIntroductionTextHistoryModal(equalityFn?: EqualityFn<ChapterIntroductionTextHistoryModalState>) {
        const state = useAppSelector((store) => store.chapterIntroductionTextHistoryModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: ChapterIntroductionTextHistoryModalDispatchParams) => dispatch(chapterIntroductionTextHistoryModalSlice.actions.open(params)),
            close: () => dispatch(chapterIntroductionTextHistoryModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createHistoryModalHook();
