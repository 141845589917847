import React, {
    RefObject, useMemo, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import HistoryPagination from '@COMPONENTS/SHARED/HistoryPagination';
import ComparisonPair from '@COMPONENTS/SHARED/ComparisonPair';

import styles from './BasicHistoryList.module.scss';

const cx: CX = classnames.bind(styles);

type HistoryVersionData = {
    id: number;

    body: string;

    description: string;

    createdAt: string;

    author: {
        id: number;

        fullName: string;

        email: string;
    }
};

type FormattedData = {
    oldVersion: HistoryVersionData | null,
    newVersion: HistoryVersionData,
}[];

function BasicHistoryList(props: Props) {
    const {
        versions,
        isLandscape,
        paginationLimit,
    } = props;

    const ref: RefObject<HTMLDivElement> = useRef(null);

    const formattedData = useMemo(() => {
        const data: FormattedData = [];

        [...versions].reverse().forEach((version, i, arr) => {
            if (i < arr.length - 1) {
                data.push({
                    newVersion: version,
                    oldVersion: arr[i + 1],
                });
            } else {
                data.push({
                    newVersion: version,
                    oldVersion: null,
                });
            }
        });

        return data;
    }, [versions]);

    const [page, setPage] = useState<number>(1);

    const offset = paginationLimit * (page - 1);
    const limit = page * paginationLimit;

    const partialData = formattedData.slice(offset, limit);
    return (
        <div
            ref={ref}
            className={cx('basic-history-list')}
        >
            {
                partialData.length > 0
                    ? (
                        <div className={cx('comparison-list')}>
                            {
                                formattedData.length > paginationLimit
                                && (
                                    <HistoryPagination
                                        page={page}
                                        limit={paginationLimit}
                                        dataLength={formattedData.length}
                                        onClickPrev={(val: number) => {
                                            setPage(val);
                                        }}
                                        onClickNext={(val: number) => {
                                            setPage(val);
                                        }}
                                    />
                                )
                            }

                            {
                                partialData.map((pair) => {
                                    const { oldVersion, newVersion } = pair;

                                    const key = `pair-${oldVersion?.id || 0}-${newVersion.id}`;

                                    return (
                                        <ComparisonPair
                                            key={key}
                                            createdAt={newVersion.createdAt}
                                            authorName={newVersion.author.fullName}
                                            description={newVersion.description}
                                            isLandscape={isLandscape}
                                            pair={{
                                                oldVersion: oldVersion?.body || null,
                                                newVersion: newVersion.body || '',
                                            }}
                                        />
                                    );
                                })

                            }
                        </div>
                    ) : (
                        <div className={cx('no-history')}>
                            No history
                        </div>
                    )
            }
        </div>
    );
}

BasicHistoryList.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    paginationLimit: PropTypes.number.isRequired,
};

type Props = InferProps<typeof BasicHistoryList.propTypes> & {
    versions: HistoryVersionData[],
};

export default BasicHistoryList;
