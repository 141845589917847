import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@STORE/index';

import { sectionNameModalSlice, SectionNameModalDispatchParams, SectionNameModalState } from '@REDUCERS';

function createSectionNameModalHook() {
    return function useSectionNameModal(equalityFn?: EqualityFn<SectionNameModalState>) {
        const state = useAppSelector((store) => store.sectionNameModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: SectionNameModalDispatchParams) => dispatch(sectionNameModalSlice.actions.open(params)),
            close: () => dispatch(sectionNameModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createSectionNameModalHook();
