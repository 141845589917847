import React from 'react';

import classnames from 'classnames/bind';

import { AdminUser } from '@SERVICES';

import styles from './UserInfo.module.scss';
import COLOR from '@CONSTANTS/COLOR.constant';
import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';
import { ReactComponent as DeleteIcon } from '@ICONS/delete-icon.svg';
import useDeleteUserModal from '@HOOKS/store/modals/useDeleteUserModal';
import useAddEditUserModal from '@HOOKS/store/modals/useAddEditUserModal';

const cx: CX = classnames.bind(styles);

function UserInfo(props: Props) {
    const { user } = props;

    const {
        actions: deleteUserModalActions,
    } = useDeleteUserModal(() => true);

    const {
        actions: addEditUserModalActions,
    } = useAddEditUserModal(() => true);

    return (
        <div className={cx('user-info')}>
            <div className={cx('user-info-title')}>
                User Info
                <div className={cx('icon')}>
                    <EditIcon
                        width={18}
                        height={18}
                        fill={COLOR['marriott-light-grey']}
                        onClick={() => {
                            addEditUserModalActions.open({ user });
                        }}
                    />
                </div>
                <div className={cx('icon')}>
                    <DeleteIcon
                        width={18}
                        height={18}
                        fill={COLOR['marriott-light-grey']}
                        onClick={() => {
                            deleteUserModalActions.open({
                                id: user.id,
                                name: user.fullName,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={cx('data')}>
                {
                    [
                        {
                            title: 'Name',
                            value: user.fullName,
                        },
                        {
                            title: 'Email',
                            value: user.email,
                        },
                        {
                            title: 'Country',
                            value: user.country,
                        },
                        {
                            title: 'Mobile phone',
                            value: user.mobilePhone,
                        },
                        {
                            title: 'Office phone',
                            value: user.officePhone,
                        },
                    ].map((item) => {
                        const key = item.title;

                        return (
                            <div
                                key={key}
                                className={cx('data-item')}
                            >
                                <div className={cx('data-title')}>
                                    {item.title}
                                </div>
                                <div className={cx('data-value')}>
                                    {item.value || '-'}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

type Props = {
    user: AdminUser,
};

export default UserInfo;
