import React, { useEffect } from 'react';
import {
    matchRoutes, Navigate, Outlet, RouteObject, useLocation,
} from 'react-router-dom';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import useAuth from '@HOOKS/useAuth';
import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import PermissionDenied from '@COMPONENTS/SHARED/PermissionDenied';
import { notify } from '@NOTIFICATION/Notificator';

const routes: RouteObject[] = [
    { path: ROUTES.login.path },

    { path: ROUTES.main.path },
    { path: ROUTES.chooseDocument.path },
    // { path: ROUTES.brand.path },
    // { path: ROUTES.region.path },
    // { path: ROUTES.area.path },
    // { path: ROUTES.country.path },
    // { path: ROUTES.type.path },
    { path: ROUTES.options.path },
    { path: ROUTES.selectContent.path },
    { path: ROUTES.preview.path },
    { path: ROUTES.downloadDocument.path },

    { path: ROUTES.admin.documents.path },
    { path: ROUTES.admin.documents.id.path },
    { path: ROUTES.admin.chapters.id.path },
    { path: ROUTES.admin.sections.id.path },
    { path: ROUTES.admin.manageVendors.path },
    { path: ROUTES.admin.logs.path },
    { path: ROUTES.admin.manageUsersPermissions.path },
];

function ProtectedRoute() {
    const { state: { user, loginData: { isSuccess: isAuthSuccess } } } = useAuth();

    const {
        canManageVendors,
        canViewLogs,
        canManagePermissions,
    } = useGlobalPermissions();

    const location = useLocation();

    const [{ route }] = matchRoutes(routes, location) || [{ route: { path: null } }];

    useEffect(() => {
        if (isAuthSuccess) {
            notify.info('Successfully authenticated', {
                toastId: 'successfully-authenticated',
            });
        }
    }, [isAuthSuccess]);

    if (route.path) {
        if (user && route.path === ROUTES.login.path) {
            return <Navigate to={ROUTES.main.path} replace />;
        }

        if (!user && route.path !== ROUTES.login.path) {
            return <Navigate to={ROUTES.login.path} replace />;
        }

        // TODO canGenerateDocument
        // if (!canGenerateDocuments) {
        //     const protectedRoutes = [
        //         ROUTES.chooseDocument.path,
        //         ROUTES.brand.path,
        //         ROUTES.region.path,
        //         ROUTES.area.path,
        //         ROUTES.country.path,
        //         ROUTES.type.path,
        //         ROUTES.options.path,
        //         ROUTES.selectContent.path,
        //         ROUTES.preview.path,
        //         ROUTES.downloadDocument.path,
        //     ];
        //
        //     if (protectedRoutes.includes(route.path as GenerationSteps)) {
        //         return <PermissionDenied />;
        //     }
        // }

        if (!canManageVendors && route.path === ROUTES.admin.manageVendors.path) {
            return <PermissionDenied />;
        }

        if (!canViewLogs && route.path === ROUTES.admin.logs.path) {
            return <PermissionDenied />;
        }

        if (!canManagePermissions && route.path === ROUTES.admin.manageUsersPermissions.path) {
            return <PermissionDenied />;
        }
    } else if (!user) {
        return <Navigate to={ROUTES.login.path} replace />;
    }

    return <Outlet />;
}

export default ProtectedRoute;
