import React, { useState } from 'react';

import classnames from 'classnames/bind';

import LoginForm from '@PAGES/common/login/components/LoginForm';
import ForgotForm from '@PAGES/common/login/components/ForgotForm';

import styles from './Login.module.scss';

const cx: CX = classnames.bind(styles);

function LoginPage() {
    const [isLogin, setIsLogin] = useState(true);

    return (
        <div className={cx('page')}>
            {!!isLogin && <LoginForm setIsLogin={setIsLogin} />}
            {!isLogin && <ForgotForm setIsLogin={setIsLogin} />}
        </div>
    );
}

export default LoginPage;
