import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { OrderChaptersDrawerChapter } from '@REDUCERS';

import { notify } from '@NOTIFICATION/Notificator';

import { useReorderChapters } from '@SERVICES';

import useOrderChaptersDrawer from '@HOOKS/store/drawers/useOrderChaptersDrawer';

import COLOR from '@CONSTANTS/COLOR.constant';

import DragAndDropList from '@COMPONENTS/SHARED/DragAndDropList';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './OrderChaptersDrawer.module.scss';

const cx: CX = classnames.bind(styles);

function OrderChaptersDrawer(props: Props) {
    const { onClose } = props;

    const {
        state: { data: drawerParams },
    } = useOrderChaptersDrawer();

    const [changeOrder, {
        isLoading, isSuccess, isError, error,
    }] = useReorderChapters();

    const { documentId, chapters, canEditDocument } = drawerParams!;

    const [order, setOrder] = useState(chapters.map((s) => ({
        id: s.id,
        name: s.name,
        order: s.order,
    })));

    useEffect(() => {
        if (isSuccess) {
            notify.info('Chapters order has been changed', {
                toastId: 'reorder-chapters',
            });

            onClose();
        }
    }, [isSuccess, onClose]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'reorder-chapters-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'reorder-chapters-error',
                });
            }
        }
    }, [isError, error]);

    const initialOrder = chapters.map((s) => s.order);

    function onDragEnd(items: OrderChaptersDrawerChapter[]) {
        setOrder(items.map((item, i) => ({
            id: item.id,
            name: item.name,
            order: initialOrder[i],
        })));
    }

    return (
        <div className={cx('order-chapters-drawer')}>
            <div className={cx('header')}>
                <div className={cx('title')}>
                    Change chapters order
                </div>
            </div>

            <div className={cx('chapters-wrapper')}>
                <DragAndDropList<OrderChaptersDrawerChapter>
                    droppableId="chapters"
                    list={order}
                    onDragEnd={(items) => onDragEnd(items)}
                >
                    {(item, index, dragId) => (
                        <div
                            className={cx('chapter', {
                                dragged: item.id === dragId,
                            })}
                        >
                            <div className={cx('item-number')}>{index + 1}</div>
                            <div className={cx('item-name')}>{item.name}</div>
                        </div>
                    )}
                </DragAndDropList>
            </div>

            <div className={cx('footer')}>
                <BasicButton
                    locked={!canEditDocument}
                    title="Save order"
                    isProcessing={isLoading}
                    style={{
                        backgroundColor: COLOR['app-green'],
                        height: 40,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        changeOrder({
                            documentId,
                            chapters: order.map((s) => ({
                                id: s.id,
                                order: s.order,
                            })),
                        });
                    }}
                />
            </div>
        </div>
    );
}

OrderChaptersDrawer.propTypes = {
    onClose: PropTypes.func.isRequired,
};

type Props = InferProps<typeof OrderChaptersDrawer.propTypes>;

export default OrderChaptersDrawer;
