import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useDeleteTagFromVariant } from '@SERVICES';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant, AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import TagItem from '@PAGES/admin/edit-section/components/TagItem';
import VariantTagsControls from '@PAGES/admin/edit-section/components/VariantTagsControls';

import styles from './VariantTags.module.scss';

const cx: CX = classnames.bind(styles);

function VariantTags(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagOriginalArgs,
        },
    ] = useDeleteTagFromVariant();

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-variant',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-variant-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    return (
        <div className={cx('variant-tags')}>
            <div className={cx('title')}>
                Tags
            </div>
            {
                variant.tags.length
                    ? (
                        <div className={cx('tags-list')}>
                            {
                                variant.tags.map((tag) => {
                                    const key = tag.id;

                                    const isProcessing = deleteTagOriginalArgs?.tagId === tag.id && isDeleteTagLoading;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item-wrapper')}
                                        >
                                            <TagItem
                                                tag={tag}
                                                isProcessing={isProcessing}
                                                locked={!canManageDocumentTags}
                                                onClick={() => {
                                                    deleteTag({
                                                        chapterId: sectionData.chapter.id,
                                                        sectionId: sectionData.id,
                                                        id: variant.id,
                                                        tagId: tag.id,
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>

                    ) : (
                        <div className={cx('no-tags')}>No tags</div>
                    )
            }
            <div className={cx('new-tag-wrapper')}>
                <VariantTagsControls
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantTags;
