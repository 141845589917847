import React from 'react';

import { useDeleteSection } from '@SERVICES';
import useDeleteSectionModal from '@HOOKS/store/modals/useDeleteSectionModal';

import DeleteItemModal from '@MODALS/common/DeleteItemModal';

function DeleteSectionModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteSectionModal();

    const [
        deleteSection,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteSection();

    return (
        <DeleteItemModal
            modalTitle="Delete section"
            name={modalParams!.name}
            itemName="Section"
            description="All variants of this section will also be deleted!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteSection({
                    id: modalParams!.id,
                    documentId: modalParams!.documentId,
                    chapterId: modalParams!.chapterId,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteSectionModal;
