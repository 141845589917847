import React, { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames/bind';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';

import PropTypes, { InferProps } from 'prop-types';
import { AdminSingleDocumentChapter, Role, useGetAllRoles } from '@SERVICES';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import styles from './DocumentPermissionsByRole.module.scss';

const cx: CX = classnames.bind(styles);

const defaultData = {
    data: [],
};

function DocumentPermissionsByRole(props: Props) {
    const {
        roles,
        setRolesPermissions,
        chapters,
        open,
    } = props;

    const {
        data: rolesData = defaultData, isLoading,
    } = useGetAllRoles();

    const empty = '';
    const [dataIsSetted, setDataIsSetted] = useState<boolean>(false);
    const [titles, setTitles] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        if ((rolesData.data.length === 0 || dataIsSetted || roles.length === rolesData.data.length) && !open) {
            return;
        }
        const fullArray: any[] = [];
        rolesData.data.forEach((role) => {
            const index = roles.findIndex((r: any) => r.roleId === role.id);
            if (index !== -1) {
                fullArray.push({ ...roles[index] });
            } else {
                const emptyData = {
                    roleId: role.id,
                    name: role.name,
                    chapters: {},
                };
                fullArray.push(emptyData);
            }
        });
        if (fullArray.length !== 0) {
            setRolesPermissions({ fullArray });
        }
        setDataIsSetted(true);
    }, [rolesData, roles, setRolesPermissions, dataIsSetted, open]);

    useEffect(() => {
        setTitles([]);
        chapters.forEach((chapter: any, chapterIndex: number) => {
            setTitles((prevState) => [...prevState, {
                id: `title-${chapterIndex}`,
                name: `${chapterIndex + 1}.${chapter.name}`,
                class: 'chapter',
            }]);
            chapter.sections.forEach((section: any, sectionIndex: number) => {
                setTitles((prevState) => [...prevState, {
                    id: `title-${chapterIndex}-${sectionIndex}`,
                    name: `${chapterIndex + 1}.${sectionIndex + 1}.${section.name}`,
                    class: 'section',
                }]);
            });
        });
    }, [chapters, setTitles]);

    const rowData = useCallback(
        (role: any) => {
            const dataArray: any[] = [];
            chapters.forEach((chapter: any, chapterIndex) => {
                dataArray.push({
                    id: `data-${chapterIndex}`,
                    roleId: role.roleId,
                    chapterId: chapter.id,
                    sectionId: null,
                    value: role.chapters[chapter.id]?.value || false,
                    class: 'chapter',
                });
                chapter.sections.forEach((section: any, sectionIndex: number) => {
                    dataArray.push({
                        id: `data-${chapterIndex}-${sectionIndex}`,
                        roleId: role.roleId,
                        chapterId: chapter.id,
                        sectionId: section.id,
                        value: role.chapters[chapter.id]?.sections[section.id]?.value || false,
                        class: 'section',
                    });
                });
            });

            return dataArray;
        },
        [chapters],
    );

    useEffect(() => {
        setRows([]);
        roles.forEach((role: any, index) => {
            setRows((prevState) => [...prevState, {
                roleName: role.name || role.roleName,
                id: `role-${role.id || role.roleId}-${index}`,
                roleData: rowData(role),
            }]);
        });
    }, [roles, rowData, setRows]);

    return (
        <div className={cx('document-permissions', 'container')}>
            <div className={cx('permissions-wrapper')}>

                <DataLoader
                    isLoading={!dataIsSetted || roles.length !== rolesData.data.length}
                    isFetching={!dataIsSetted || roles.length !== rolesData.data.length}
                    isError={false}
                    error={null}
                >
                    <div className={cx('table')}>
                        {dataIsSetted && roles.length === rolesData.data.length && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>{empty}</th>
                                        {titles.map((title) => (
                                            <th className={cx(title.class)} key={title.id}>{title.name}</th>))}

                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{row.roleName}</td>

                                            {row.roleData?.map((data: any) => (
                                                <td key={data.id} className={cx(data.class, 'center')}>
                                                    <BasicCheckbox
                                                        title=""
                                                        checked={data.value || false}
                                                        onChange={() => {
                                                            setRolesPermissions({
                                                                index,
                                                                roleId: data.roleId,
                                                                chapterId: data.chapterId,
                                                                sectionId: data.sectionId,
                                                            });
                                                        }}
                                                    />
                                                </td>
                                            ))}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>

                </DataLoader>
            </div>
        </div>
    );
}

DocumentPermissionsByRole.propTypes = {
    setRolesPermissions: PropTypes.func.isRequired,
};

type Props = InferProps<typeof DocumentPermissionsByRole.propTypes> & {
    roles: Role[],
    chapters: AdminSingleDocumentChapter[],
    open: boolean
};

export default DocumentPermissionsByRole;
