import React from 'react';
import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import useNewTagModal from '@HOOKS/store/modals/useNewTagModal';
import { useCreateTag } from '@SERVICES';

function NewTagModal() {
    const {
        state: { data: modalParams },
        actions: newTagModalActions,
    } = useNewTagModal();

    const [
        createTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateTag();

    const onSave = (formValues: FormValues) => {
        createTag({
            documentId: modalParams!.documentId,
            name: formValues.name,
        });
    };

    return (
        <NameInputModal
            modalTitle="Create new tag"
            itemName="Tag"
            inputPlaceholder="Tag name"
            successMessage="New tag has been created!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                newTagModalActions.close();
            }}
        />
    );
}

export default NewTagModal;
