import { useCallback } from 'react';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import validateSteps from '@VALIDATION/helpers/validateSteps';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

function createStepsValidationHook() {
    return function useStepsValidation() {
        const {
            state: { steps },
        } = useDocumentGenerationSteps();

        return useCallback(
            (current: GenerationSteps) => validateSteps(steps, current),
            [steps],
        );
    };
}

export default createStepsValidationHook();
