import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminUnpublishedDocumentChapterSection } from '@SERVICES';

import OnlyTitleStickyHeader from '@MODALS/Publications/components/OnlyTitleStickyHeader';
import Variant from '@MODALS/Publications/PublishDocumentModal/components/Variant';
import VariantsStickyHeader from '@MODALS/Publications/PublishDocumentModal/components/VariantsStickyHeader';
import BaseText from '@MODALS/Publications/PublishDocumentModal/components/BaseText';

import styles from './Section.module.scss';

const cx: CX = classnames.bind(styles);

function Section(props: Props) {
    const {
        section,
        selectedSectionsIds,
        setSelectedSectionsIds,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    const { baseText, variants } = section;

    return (
        <div className={cx('section')}>
            {
                !baseText.isPublished
                && (
                    <div className={cx('base-text-wrapper')}>
                        <OnlyTitleStickyHeader title="Base text" top={35} />

                        <BaseText
                            section={section}
                            selectedSectionsIds={selectedSectionsIds}
                            setSelectedSectionsIds={setSelectedSectionsIds}
                        />
                    </div>
                )
            }

            {
                variants.length > 0
                && (
                    <div>
                        <VariantsStickyHeader
                            variants={variants}
                            selectedVariantsIds={selectedVariantsIds}
                            setSelectedVariantsIds={setSelectedVariantsIds}
                        />

                        <div className={cx('variants-list')}>
                            {
                                variants.map((variant) => {
                                    const key = variant.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('variant-wrapper')}
                                        >
                                            <Variant
                                                variant={variant}
                                                isLandscape={section.isLandscape}
                                                selectedVariantsIds={selectedVariantsIds}
                                                setSelectedVariantsIds={setSelectedVariantsIds}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

Section.propTypes = {
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Section.propTypes> & {
    section: AdminUnpublishedDocumentChapterSection,
};

export default Section;
