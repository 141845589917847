import React from 'react';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionDataVariant,
    AdminSingleSectionData, AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import VariantTags from '@PAGES/admin/edit-section/components/VariantTags';
import VariantVendors from '@PAGES/admin/edit-section/components/VariantVendors';

import styles from './VariantBodyFooter.module.scss';

const cx: CX = classnames.bind(styles);

function VariantBodyFooter(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    return (
        <div className={cx('variant-body-footer')}>
            <div className={cx('tags-wrapper')}>
                <VariantTags
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
            <div className={cx('vendors-wrapper')}>
                <VariantVendors
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantBodyFooter;
