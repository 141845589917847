import React, { RefObject, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import PublicationForm, { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';

import styles from './BaseModalSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function BaseModalSidebar(props: Props) {
    const {
        isLoading,
        hasSelected,
        onSave,
    } = props;

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    return (
        <div className={cx('base-modal-sidebar')}>
            <div className={cx('form')}>
                <div className={cx('form-wrapper')}>
                    <div className={cx('title')}>
                        Publish
                    </div>
                    <PublicationForm
                        onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                            onSave(formValues, includeToVersionControl);
                        }}
                    >
                        <HiddenFormButton ref={formButtonRef} />
                    </PublicationForm>
                </div>

                <div className={cx('publish-button-wrapper')}>
                    <BasicButton
                        title="Publish"
                        isProcessing={isLoading}
                        style={{
                            pointerEvents: hasSelected ? 'all' : 'none',
                            height: 40,
                            width: '100%',
                            fontSize: 14,
                            backgroundColor: hasSelected ? COLOR['app-green'] : COLOR['marriott-light-grey'],
                        }}
                        onClick={() => {
                            if (hasSelected) {
                                formButtonRef.current?.click();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

BaseModalSidebar.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasSelected: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseModalSidebar.propTypes>;

export default BaseModalSidebar;
