import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import styles from './RouteButton.module.scss';

const cx: CX = classnames.bind(styles);

function RouteButton(props: Props) {
    const { title, active, onClick } = props;

    return (
        <div
            className={cx('route-button', {
                active,
            })}
        >
            <button
                type="button"
                className="clear-button"
                onClick={() => {
                    if (!active) {
                        onClick();
                    }
                }}
            >
                <div className={cx('edit-icon')}>
                    <EditIcon
                        width={18}
                        height={18}
                        fill={COLOR['marriott-light-grey']}
                    />
                </div>
                <div className={cx('title')}>
                    {title}
                </div>
            </button>
        </div>
    );
}

RouteButton.defaultProps = {
    active: false,
};

RouteButton.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof RouteButton.propTypes> & typeof RouteButton.defaultProps;

export default RouteButton;
