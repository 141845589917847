/* eslint-disable class-methods-use-this */

import { UserPermissions } from '@SERVICES';

const USER_PERMISSIONS_KEY = `user-permissions-${process.env.NODE_ENV}`;

const VERSION = 3;

interface Data {
    payload: UserPermissions;

    VERSION: number;
}

const empty = {
    isSuperuser: false,
    global: [],
    documents: [],
};

class UserPermissionsRepository {
    getData(): UserPermissions {
        const data = localStorage.getItem(USER_PERMISSIONS_KEY);

        if (!data) {
            return empty;
        }

        try {
            const parsedData: Data = JSON.parse(atob(data));

            if (parsedData.VERSION !== VERSION) {
                this.clear();

                return empty;
            }

            return parsedData.payload;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        return empty;
    }

    setData(data: UserPermissions | undefined) {
        if (!data) return;

        localStorage.setItem(USER_PERMISSIONS_KEY, btoa(JSON.stringify({
            payload: data,
            VERSION,
        })));
    }

    clear(): void {
        localStorage.removeItem(USER_PERMISSIONS_KEY);
    }
}

export default new UserPermissionsRepository();
