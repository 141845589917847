import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './IntroductionTextStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function IntroductionTextStickyHeader(props: Props) {
    const { chapterName } = props;

    return (
        <StickyHeader
            top={1}
            zIndex={6}
        >
            <div className={cx('introduction-text-sticky-header')}>
                <div className={cx('left')}>
                    <div className={cx('introduction-text-title')}>
                        Introduction text
                    </div>
                </div>

                <div className={cx('delimiter')} />

                <div className={cx('right')}>
                    <div className={cx('chapter-prefix')}>
                        Chapter
                    </div>
                    <div className={cx('chapter-name')}>
                        {chapterName}
                    </div>
                    <div />
                </div>
            </div>
        </StickyHeader>
    );
}

IntroductionTextStickyHeader.propTypes = {
    chapterName: PropTypes.string.isRequired,
};

type Props = InferProps<typeof IntroductionTextStickyHeader.propTypes>;

export default IntroductionTextStickyHeader;
