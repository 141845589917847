import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleChapterData, AdminSingleChapterDataIntroductionText } from '@INTERFACES/admin-api/chapters';

import ModalSidebar from '@MODALS/Publications/PublishChapterModal/components/ModalSidebar';
import Section from '@MODALS/Publications/PublishChapterModal/components/Section';
import OnlyTitleStickyHeader from '@MODALS/Publications/components/OnlyTitleStickyHeader';
import IntroductionText from '@MODALS/Publications/PublishChapterModal/components/IntroductionText';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const {
        chapterData,
        introductionText,
        includeIntroductionText,
        setIncludeIntroductionText,
        selectedSectionsIds,
        setSelectedSectionsIds,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    return (
        <div className={cx('modal-body')}>
            <div className={cx('sidebar')}>
                <ModalSidebar
                    chapterData={chapterData}
                    introductionText={introductionText}
                    includeIntroductionText={includeIntroductionText}
                    selectedSectionsIds={selectedSectionsIds}
                    selectedVariantsIds={selectedVariantsIds}
                />
            </div>

            <div className={cx('body')}>
                {
                    introductionText !== null
                    && (
                        <div>
                            <OnlyTitleStickyHeader title="Introduction text" top={1} />

                            <div className={cx('introduction-text-wrapper')}>
                                <IntroductionText
                                    introductionText={introductionText}
                                    includeIntroductionText={includeIntroductionText}
                                    setIncludeIntroductionText={setIncludeIntroductionText}
                                />
                            </div>
                        </div>
                    )
                }

                {
                    chapterData.sections.map((section) => {
                        const key = section.id;

                        return (
                            <div
                                key={key}
                                className={cx('section-wrapper')}
                            >
                                <Section
                                    section={section}
                                    selectedSectionsIds={selectedSectionsIds}
                                    setSelectedSectionsIds={setSelectedSectionsIds}
                                    selectedVariantsIds={selectedVariantsIds}
                                    setSelectedVariantsIds={setSelectedVariantsIds}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

ModalBody.propTypes = {
    includeIntroductionText: PropTypes.bool.isRequired,
    setIncludeIntroductionText: PropTypes.func.isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    chapterData: AdminSingleChapterData,
    introductionText: AdminSingleChapterDataIntroductionText | null,
};

export default ModalBody;
