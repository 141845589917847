import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { orderChaptersDrawerSlice, OrderChaptersDrawerDispatchParams, OrderChaptersDrawerState } from '@REDUCERS';

function createOrderChaptersDrawerHook() {
    return function useOrderChaptersDrawer(equalityFn?: EqualityFn<OrderChaptersDrawerState>) {
        const state = useAppSelector((store) => store.orderChaptersDrawer, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: OrderChaptersDrawerDispatchParams) => dispatch(orderChaptersDrawerSlice.actions.open(params)),
            close: () => dispatch(orderChaptersDrawerSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createOrderChaptersDrawerHook();
