import React from 'react';
import Drawer from '@DRAWERS/Drawer';
import OrderSectionsDrawer from '@DRAWERS/OrderSectionsDrawer';
import useOrderSectionsDrawer from '@HOOKS/store/drawers/useOrderSectionsDrawer';
import useOrderChaptersDrawer from '@HOOKS/store/drawers/useOrderChaptersDrawer';
import OrderChaptersDrawer from '@DRAWERS/OrderChaptersDrawer';

interface Params {
    onClose(): void;
}

function DrawersContainer() {
    const {
        state: { isOpen: isOrderChaptersOpen },
        actions: orderChaptersActions,
    } = useOrderChaptersDrawer();

    const {
        state: { isOpen: isOrderSectionsOpen },
        actions: orderSectionsActions,
    } = useOrderSectionsDrawer();

    return (
        <>
            <Drawer
                isOpen={isOrderChaptersOpen}
                onClose={() => {
                    orderChaptersActions.close();
                }}
            >
                {
                    (params: Params) => (
                        <OrderChaptersDrawer onClose={params.onClose} />
                    )
                }
            </Drawer>

            <Drawer
                isOpen={isOrderSectionsOpen}
                onClose={() => {
                    orderSectionsActions.close();
                }}
            >
                {
                    (params: Params) => (
                        <OrderSectionsDrawer onClose={params.onClose} />
                    )
                }
            </Drawer>
        </>
    );
}

export default DrawersContainer;
