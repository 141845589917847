import React, { useLayoutEffect, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import usePublishChapterModal from '@HOOKS/store/modals/usePublishChapterModal';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import BaseModal from '@MODALS/BaseModal';
import SimpleLargeModalHeader from '@COMPONENTS/SHARED/SimpleLargeModalHeader';
import ModalBody from '@MODALS/Publications/PublishChapterModal/components/ModalBody';

import styles from './PublishChapterModal.module.scss';

const cx: CX = classnames.bind(styles);

function PublishChapterModal() {
    const {
        state: { data: modalParams },
        actions: publishChapterModalActions,
    } = usePublishChapterModal();

    const { chapterData } = modalParams!;

    const unpublishedIntroductionText = !chapterData.introductionText.isPublished ? chapterData.introductionText : null;

    const unpublishedChapterData = useMemo(() => ({
        ...chapterData,
        sections: chapterData.sections.map((section) => ({
            ...section,
            variants: section.variants.filter((variant) => !variant.isPublished),
        })).filter((section) => {
            const { baseText, variants } = section;

            return (
                !baseText.isPublished
                || variants.length > 0
            );
        }),
    }), [chapterData]);

    const [includeIntroductionText, setIncludeIntroductionText] = useState<boolean>(false);
    const [selectedSectionsIds, setSelectedSectionsIds] = useState<Set<number>>(new Set());
    const [selectedVariantsIds, setSelectedVariantsIds] = useState<Set<number>>(new Set());

    useLayoutEffect(() => {
        setIncludeIntroductionText(
            unpublishedIntroductionText !== null
            && !isContentEmptyCheck(unpublishedIntroductionText.body),
        );

        setSelectedSectionsIds(
            new Set(
                unpublishedChapterData.sections
                    .filter((section) => !section.baseText.isPublished)
                    .filter((section) => !isContentEmptyCheck(section.baseText.body))
                    .map((section) => section.id),
            ),
        );

        setSelectedVariantsIds(
            new Set(
                unpublishedChapterData.sections.reduce((acc: number[], section) => {
                    const { variants } = section;

                    return [
                        ...acc,
                        ...variants
                            .filter((v) => !isContentEmptyCheck(v.body))
                            .map((v) => v.id),
                    ];
                }, []),
            ),
        );
    }, [unpublishedIntroductionText, unpublishedChapterData]);

    function handleClose() {
        publishChapterModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('publish-chapter-modal')}>
                <div className={cx('header-wrapper')}>
                    <SimpleLargeModalHeader
                        modalTitle="Publish chapter"
                        title={chapterData.name}
                        onClose={() => handleClose()}
                    />
                </div>
                <div className={cx('content')}>
                    <ModalBody
                        chapterData={unpublishedChapterData}
                        introductionText={unpublishedIntroductionText}
                        includeIntroductionText={includeIntroductionText}
                        setIncludeIntroductionText={setIncludeIntroductionText}
                        selectedSectionsIds={selectedSectionsIds}
                        setSelectedSectionsIds={setSelectedSectionsIds}
                        selectedVariantsIds={selectedVariantsIds}
                        setSelectedVariantsIds={setSelectedVariantsIds}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PublishChapterModal;
