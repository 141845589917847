import React from 'react';
import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import { useUpdateTag } from '@SERVICES';
import useTagNameModal from '@HOOKS/store/modals/useTagNameModal';

function TagNameModal() {
    const {
        state: { data: modalParams },
        actions: tagNameModalActions,
    } = useTagNameModal();

    const [
        updateTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateTag();

    const onSave = (formValues: FormValues) => {
        updateTag({
            id: modalParams!.id,
            name: formValues.name,
            documentId: modalParams!.documentId,
        });
    };

    return (
        <NameInputModal
            modalTitle="Update tag name"
            itemName="Tag"
            inputPlaceholder="Tag name"
            successMessage="Tag name has been updated!"
            initialInputValue={modalParams!.name}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                tagNameModalActions.close();
            }}
        />
    );
}

export default TagNameModal;
