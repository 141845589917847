import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

import classnames from 'classnames/bind';

import PageDataLoading from '@COMPONENTS/SHARED/PageDataLoading';

import styles from './DataLoader.module.scss';

const cx: CX = classnames.bind(styles);

function DataLoader(props: Props) {
    const {
        isLoading, isFetching, isError, error, children,
    } = props;

    let child;

    if (isLoading) {
        child = (
            <div className={cx('loading-loader')}>
                <PageDataLoading />
            </div>
        );
    } else if (isError) {
        if (error && (error as FetchBaseQueryError).status === 403) {
            child = (
                <div className={cx('error')}>
                    Permission denied!
                </div>
            );
        }

        child = (
            <div className={cx('error')}>
                Error! Something Wrong!
            </div>
        );
    } else {
        child = children;
    }

    return (
        <div className={cx('data-loader')}>
            {
                (isFetching && !isLoading)
                && (
                    <div className={cx('fetching-loader')}>
                        <div className={cx('loader')}>
                            <PageDataLoading />
                        </div>
                    </div>
                )
            }
            {child}
        </div>
    );
}

DataLoader.defaultProps = {
    isFetching: false,
};

DataLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool,
    isError: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof DataLoader.propTypes> & typeof DataLoader.defaultProps & OnlyChildrenProps & {
    error: FetchBaseQueryError | SerializedError | undefined | null,
};

export default DataLoader;
