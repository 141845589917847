import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import useScrollLock from '@HOOKS/useScrollLock';

import ResultsCounter from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultsCounter';

import styles from './ResultSetWrapper.module.scss';

const cx: CX = classnames.bind(styles);

function ResultSetWrapper(props: Props) {
    const {
        setFocused,
        count,
        resultsQuery,
        children,
    } = props;

    const { lock, unlock } = useScrollLock();

    useEffect(() => {
        lock();

        const scrollToTopButton = document.getElementById('scroll-to-top-button')!;

        scrollToTopButton.style.display = 'none';

        return () => {
            unlock();

            scrollToTopButton.style.display = 'flex';
        };
    }, [lock, unlock]);

    return (
        <>
            <div
                role="button"
                tabIndex={0}
                className={cx('bg')}
                onClick={() => {
                    setFocused(false);
                }}
            />
            <div className={cx('result-set-header')}>
                <ResultsCounter
                    count={count}
                    resultsQuery={resultsQuery}
                />
            </div>
            <div className={cx('result-set-wrapper')}>
                <div className={cx('body')}>
                    {children}
                </div>
            </div>
        </>
    );
}

ResultSetWrapper.propTypes = {
    setFocused: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    resultsQuery: PropTypes.string.isRequired,
};

type Props = InferProps<typeof ResultSetWrapper.propTypes> & OnlyChildrenProps;

export default ResultSetWrapper;
