import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { deleteDocumentModalSlice, DeleteDocumentModalDispatchParams, DeleteDocumentModalState } from '@REDUCERS';

function createDeleteDocumentModalHook() {
    return function useDeleteDocumentModal(equalityFn?: EqualityFn<DeleteDocumentModalState>) {
        const state = useAppSelector((store) => store.deleteDocumentModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DeleteDocumentModalDispatchParams) => dispatch(deleteDocumentModalSlice.actions.open(params)),
            close: () => dispatch(deleteDocumentModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDeleteDocumentModalHook();
