import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleSectionDataBaseText } from '@INTERFACES/admin-api/sections';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';
import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';

import styles from './BaseText.module.scss';

const cx: CX = classnames.bind(styles);

function BaseText(props: Props) {
    const {
        baseText,
        isLandscape,
        includeBaseText,
        setIncludeBaseText,
    } = props;

    const oldVersion = (
        baseText.versions.length > 0
            ? baseText.versions[baseText.versions.length - 1].body
            : null
    );

    const newVersion = baseText.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('base-text')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={includeBaseText}
                        onChange={() => {
                            setIncludeBaseText((val: boolean) => !val);
                        }}
                    />
                </div>
            </div>
            <div className={cx('base-text-body')}>
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={isLandscape}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

BaseText.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    includeBaseText: PropTypes.bool.isRequired,
    setIncludeBaseText: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseText.propTypes> & {
    baseText: AdminSingleSectionDataBaseText,
};

export default BaseText;
