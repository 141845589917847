import React, { useLayoutEffect, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import usePublishSectionModal from '@HOOKS/store/modals/usePublishSectionModal';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import BaseModal from '@MODALS/BaseModal';
import SimpleLargeModalHeader from '@COMPONENTS/SHARED/SimpleLargeModalHeader';
import ModalBody from '@MODALS/Publications/PublishSectionModal/components/ModalBody';

import styles from './PublishSectionModal.module.scss';

const cx: CX = classnames.bind(styles);

function PublishSectionModal() {
    const {
        state: { data: modalParams },
        actions: publishSectionModalActions,
    } = usePublishSectionModal();

    const { sectionData } = modalParams!;

    const {
        baseText,
        variants,
        isLandscape,
    } = sectionData;

    const unpublishedBaseText = !baseText.isPublished ? baseText : null;

    const unpublishedVariants = useMemo(
        () => variants.filter((variant) => !variant.isPublished),
        [variants],
    );

    const [includeBaseText, setIncludeBaseText] = useState<boolean>(false);
    const [selectedVariantsIds, setSelectedVariantsIds] = useState<Set<number>>(new Set());

    useLayoutEffect(() => {
        setIncludeBaseText(
            unpublishedBaseText !== null
            && !isContentEmptyCheck(unpublishedBaseText.body),
        );

        setSelectedVariantsIds(
            new Set(
                unpublishedVariants
                    .filter((v) => !isContentEmptyCheck(v.body))
                    .map((v) => v.id),
            ),
        );
    }, [unpublishedBaseText, unpublishedVariants]);

    function handleClose() {
        publishSectionModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('publish-section-modal')}>
                <div className={cx('header-wrapper')}>
                    <SimpleLargeModalHeader
                        modalTitle="Publish section"
                        title={sectionData.name}
                        onClose={() => handleClose()}
                    />
                </div>
                <div className={cx('content')}>
                    <ModalBody
                        isLandscape={isLandscape}
                        sectionData={sectionData}
                        variants={unpublishedVariants}
                        baseText={unpublishedBaseText}
                        includeBaseText={includeBaseText}
                        setIncludeBaseText={setIncludeBaseText}
                        selectedVariantsIds={selectedVariantsIds}
                        setSelectedVariantsIds={setSelectedVariantsIds}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PublishSectionModal;
