import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import StickyBox from 'react-sticky-box';

import classnames from 'classnames/bind';

import StickySidebar from '@COMPONENTS/SIDEBARS/StickySidebar';

import styles from './ContentWithStickySidebar.module.scss';

const cx: CX = classnames.bind(styles);

function ContentWithStickySidebar(props: Props) {
    const {
        sidebar, offsetTop, offsetBottom, children,
    } = props;

    return (
        <div className={cx('content-with-sidebar')}>
            <div className={cx('layout')}>
                <StickySidebar
                    offsetTop={offsetTop}
                    offsetBottom={offsetBottom}
                >
                    {sidebar}
                </StickySidebar>

                <StickyBox
                    offsetTop={offsetTop}
                    offsetBottom={offsetBottom}
                    className={cx('content-wrapper')}
                >
                    {children}
                </StickyBox>
            </div>
        </div>
    );
}

ContentWithStickySidebar.defaultProps = {
    //
};

ContentWithStickySidebar.propTypes = {
    sidebar: PropTypes.element.isRequired,
    offsetTop: PropTypes.number.isRequired,
    offsetBottom: PropTypes.number.isRequired,
};

type Props =
    InferProps<typeof ContentWithStickySidebar.propTypes>
    & typeof ContentWithStickySidebar.defaultProps
    & OnlyChildrenProps;

export default ContentWithStickySidebar;
