import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

interface PropertiesApiResponse {
    id: number;

    name: string;
}

export const propertyApi = createApi({
    reducerPath: 'property-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<PropertiesApiResponse[], void>({
            query: () => '/properties/',
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllProperties,
} = propertyApi;
