/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

export interface PreviewSectionModalState {
    isOpen: boolean;

    data: {
        documentId: number;

        sectionId: number;

        canGenerateDocument: boolean;

        sections: AdminSingleChapterDataSection[];
    } | null;
}

export interface PreviewSectionModalDispatchParams {
    documentId: number;

    sectionId: number;

    canGenerateDocument: boolean;

    sections: AdminSingleChapterDataSection[];
}

const initialState: PreviewSectionModalState = {
    isOpen: false,

    data: null,
};

export const previewSectionModalSlice = createSlice({
    name: 'preview-section-modal',
    initialState,
    reducers: {
        open(state: PreviewSectionModalState, action: PayloadAction<PreviewSectionModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PreviewSectionModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
