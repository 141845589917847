/* eslint-disable @typescript-eslint/naming-convention */
import PDF from '@CONSTANTS/PDF.constant';
import { BASE_URL } from '@CONSTANTS/API.constant';
import { ApplicationInventoryTemplate } from '@HELPERS/editorTemplates/templates';

const EDITOR_MARGIN = PDF['pdf-editor-margin'];
const PAGE_WIDTH = PDF['pdf-page-width'];
const PAGE_HEIGHT = PDF['pdf-page-height'];

type Orientation = 'portrait' | 'landscape';

type ToolbarMode = 'floating' | 'sliding' | 'scrolling' | 'wrap';

const plugins = [
    // OPEN SOURCE PLUGINS
    'advlist', // https://www.tiny.cloud/docs/tinymce/6/advlist/
    'anchor', // https://www.tiny.cloud/docs/tinymce/6/anchor/
    'autolink', // https://www.tiny.cloud/docs/tinymce/6/autolink/
    'autoresize', // https://www.tiny.cloud/docs/tinymce/6/autoresize/
    // 'autosave', // https://www.tiny.cloud/docs/tinymce/6/autosave/
    'charmap', // https://www.tiny.cloud/docs/tinymce/6/charmap/
    // 'code', // https://www.tiny.cloud/docs/tinymce/6/code/
    // 'codesample', // https://www.tiny.cloud/docs/tinymce/6/codesample/
    'directionality', // https://www.tiny.cloud/docs/tinymce/6/directionality/
    'emoticons', // https://www.tiny.cloud/docs/tinymce/6/emoticons/
    // 'fullscreen', // https://www.tiny.cloud/docs/tinymce/6/fullscreen/
    'help', // https://www.tiny.cloud/docs/tinymce/6/help/
    'image', // https://www.tiny.cloud/docs/tinymce/6/image/
    'importcss', // https://www.tiny.cloud/docs/tinymce/6/importcss/
    'insertdatetime', // https://www.tiny.cloud/docs/tinymce/6/insertdatetime/
    'link', // https://www.tiny.cloud/docs/tinymce/6/link/
    'lists', // https://www.tiny.cloud/docs/tinymce/6/lists/
    'media', // https://www.tiny.cloud/docs/tinymce/6/media/
    'nonbreaking', // https://www.tiny.cloud/docs/tinymce/6/nonbreaking/
    // 'pagebreak', // https://www.tiny.cloud/docs/tinymce/6/pagebreak/
    'preview', // https://www.tiny.cloud/docs/tinymce/6/preview/
    'quickbars', // https://www.tiny.cloud/docs/tinymce/6/quickbars/
    // 'save', // https://www.tiny.cloud/docs/tinymce/6/save/
    'searchreplace', // https://www.tiny.cloud/docs/tinymce/6/searchreplace/
    'table', // https://www.tiny.cloud/docs/tinymce/6/table/
    'template', // https://www.tiny.cloud/docs/tinymce/6/template/
    'visualblocks', // https://www.tiny.cloud/docs/tinymce/6/visualblocks/
    'visualchars', // https://www.tiny.cloud/docs/tinymce/6/visualchars/
    'wordcount', // https://www.tiny.cloud/docs/tinymce/6/wordcount/

    // PREMIUM PLUGINS
    'a11ychecker', // https://www.tiny.cloud/docs/tinymce/6/a11ychecker/
    // 'advcode', // https://www.tiny.cloud/docs/tinymce/6/advcode/

    'advtable', // https://www.tiny.cloud/docs/tinymce/6/advtable/
    // 'typography', // https://www.tiny.cloud/docs/tinymce/6/advanced-typography/ TODO not working
    // 'casechange', // https://www.tiny.cloud/docs/tinymce/6/casechange/ TODO not working
    // 'checklist', // https://www.tiny.cloud/docs/tinymce/6/checklist/ TODO not working
    // 'comments', // https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-comments/
    'editimage', // https://www.tiny.cloud/docs/tinymce/6/editimage/
    'mediaembed', // https://www.tiny.cloud/docs/tinymce/6/introduction-to-mediaembed/
    'export', // https://www.tiny.cloud/docs/tinymce/6/export/
    'footnotes', // https://www.tiny.cloud/docs/tinymce/6/footnotes/
    'formatpainter', // https://www.tiny.cloud/docs/tinymce/6/formatpainter/
    // 'inlinecss', // https://www.tiny.cloud/docs/tinymce/6/inline-css/
    'linkchecker', // https://www.tiny.cloud/docs/tinymce/6/linkchecker/
    // 'mentions', // https://www.tiny.cloud/docs/tinymce/6/mentions/
    // 'mergetags', // https://www.tiny.cloud/docs/tinymce/6/mergetags/
    'pageembed', // https://www.tiny.cloud/docs/tinymce/6/pageembed/
    'permanentpen', // https://www.tiny.cloud/docs/tinymce/6/permanentpen/
    'powerpaste', // https://www.tiny.cloud/docs/tinymce/6/introduction-to-powerpaste/
    // '???', // https://www.tiny.cloud/docs/tinymce/6/rtc-introduction/
    'tinymcespellchecker', // https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-spellchecker/
    'autocorrect', // https://www.tiny.cloud/docs/tinymce/6/autocorrect/
    'tableofcontents', // https://www.tiny.cloud/docs/tinymce/6/tableofcontents/
    'tinydrive', // https://www.tiny.cloud/docs/tinymce/6/tinydrive-introduction/
];

const menubar = 'file edit view insert format tools table help';

const toolbar = [
    [
        'undo',
        'redo',
    ],
    [
        'bold',
        'italic',
        'underline',
        'strikethrough',
    ],
    [
        'fontsize',
        'blocks',
    ],
    [
        'alignleft',
        'aligncenter',
        'alignright',
        'alignjustify',
    ],
    [
        'outdent',
        'indent',
    ],
    [
        'numlist',
        'bullist',
        // 'checklist', // TODO not working
    ],
    [
        'forecolor',
        'backcolor',
        // 'casechange', // TODO not working
        'permanentpen',
        'formatpainter',
        'removeformat',
    ],
    [
        'spellchecker ',
        'language ',
        'spellcheckdialog',
        // 'pagebreak',
    ],
    [
        'charmap',
        'emoticons',
        // 'typography', // TODO not working
    ],
    [
        'preview',
        // 'save',
        'print',
    ],
    [
        // 'insertfile',
        'image',
        'media',
        'pageembed',
        'template',
        'link',
        'anchor',
        'code',
        // 'codesample',
    ],
    [
        'a11ycheck',
        'ltr',
        'rtl',
    ],
    [
        'tableofcontents',
        // 'showcomments',
        // 'addcomment',
    ],
    [
        'footnotes',
        'footnotesupdate',
    ],
    [
        // 'mergetags',
    ],
].map((block) => block.join(' ')).join(' | ');

const quickbars_selection_toolbar = [
    ['bold', 'italic', 'underline'],
    ['fontsize'],
    ['quicklink', 'h2', 'h3', 'quickimage', 'quicktable'],

].map((block) => block.join(' ')).join(' | ');

const color_map = [
    // Marriott colors
    '#00A4BA', 'Mediterranean',
    '#31697A', 'Deep ocean',
    '#A7B5BE', 'Blue grey',
    '#353944', 'Rich grey',
    '#E8523D', 'Kalahari',

    '#000000', 'Black',
    '#993300', 'Burnt orange',
    '#333300', 'Dark olive',
    '#003300', 'Dark green',
    '#003366', 'Dark azure',
    '#000080', 'Navy Blue',
    '#333399', 'Indigo',
    '#333333', 'Very dark gray',
    '#800000', 'Maroon',
    '#FF6600', 'Orange',
    '#808000', 'Olive',
    '#008000', 'Green',
    '#008080', 'Teal',
    '#0000FF', 'Blue',
    '#666699', 'Grayish blue',
    '#808080', 'Gray',
    '#FF0000', 'Red',
    '#FF9900', 'Amber',
    '#99CC00', 'Yellow green',
    '#339966', 'Sea green',
    '#33CCCC', 'Turquoise',
    '#3366FF', 'Royal blue',
    '#800080', 'Purple',
    '#999999', 'Medium gray',
    '#FF00FF', 'Magenta',
    '#FFCC00', 'Gold',
    '#FFFF00', 'Yellow',
    '#00FF00', 'Lime',
    '#00FFFF', 'Aqua',
    '#00CCFF', 'Sky blue',
    '#993366', 'Red violet',
    '#FFFFFF', 'White',
    '#FF99CC', 'Pink',
    '#FFCC99', 'Peach',
    '#FFFF99', 'Light yellow',
    '#CCFFCC', 'Pale green',
    '#CCFFFF', 'Pale cyan',
    '#99CCFF', 'Light sky blue',
    '#CC99FF', 'Plum',
];

export default function getEditorConfig(orientation: Orientation) {
    const width = orientation === 'portrait' ? PAGE_WIDTH : PAGE_HEIGHT;

    return {
        plugins,
        menubar,
        // menu,
        toolbar,
        color_map,
        quickbars_selection_toolbar,

        // TODO open modal event

        // common
        // TODO
        skin: 'oxide', // default
        // icons: 'material',
        branding: false,
        removed_menuitems: 'fontfamily',
        toolbar_mode: 'sliding' as ToolbarMode,
        content_style: `
            html {
              width: ${width}px;
            }

            html.portrait {
              width: ${PAGE_WIDTH}px;
            }

            html.landscape {
              width: ${PAGE_HEIGHT}px;
            }

            body { margin: ${EDITOR_MARGIN}px } .tox-edit-area { display: none }

            html::-webkit-scrollbar {
              width: 8px;
              height: 8px;
            }

            html::-webkit-scrollbar-track {
              background: transparent;
            }

            html::-webkit-scrollbar-thumb {
              background-color: lightgrey;
            }

            scrollbar-width: thin;
            scrollbar-color: lightgrey transparent;
        `,

        // advlist
        advlist_bullet_styles: 'default,circle,square', // default
        advlist_number_styles: 'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman', // default

        // anchor
        allow_html_in_named_anchor: false, // default

        // autolink
        link_default_target: '_blank',
        link_default_protocol: 'https', // default

        // autoresize
        autoresize_bottom_margin: 0,
        autoresize_overflow_padding: undefined, // default

        // charmap
        charmap: undefined, // default
        charmap_append: [],

        // image
        image_list: [
            // some default images ?
        ],
        file_picker_types: 'image',
        image_caption: true,
        image_advtab: true,
        image_description: true, // default
        image_dimensions: false, // default true
        image_title: false, // default
        object_resizing: true, // default
        resize_img_proportional: true, // default
        images_file_types: 'jpg,png,svg,webp',
        images_upload_url: 'ku',
        image_uploadtab: true,
        automatic_uploads: false,
        image_class_list: [
            { title: 'Responsive', value: 'img-responsive' },
        ],
        // ...

        // importcss
        content_css: [
            `${BASE_URL}/static/pdf/document.css`,
        ],
        // ...

        // insertdatetime
        insertdatetime_dateformat: '%d-%m-%Y', // default
        insertdatetime_formats: ['%H:%M:%S', '%Y-%m-%d', '%I:%M:%S %p', '%D'], // default
        insertdatetime_timeformat: '%H:%M:%S', // default
        insertdatetime_element: false, // default

        // link
        link_context_toolbar: true,
        link_title: false,
        // ...

        // lists
        lists_indent_on_tab: true, // default

        // media TODO
        // ...

        // nonbreaking
        nonbreaking_force_tab: false, // default
        nonbreaking_wrap: true, // default

        // quickbars TODO
        // ...

        // table
        table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol', // default,
        table_default_styles: {
            width: `${width - (EDITOR_MARGIN * 2)}px`,
            'border-collapse': 'collapse',
        },
        table_class_list: [
            // TODO table_class_list
            // { title: 'None', value: '' },
            // { title: 'No Borders', value: 'table_no_borders' },
            // { title: 'Red borders', value: 'table_red_borders' },
            // { title: 'Blue borders', value: 'table_blue_borders' },
            // { title: 'Green borders', value: 'table_green_borders' },
        ],
        table_cell_class_list: [
            // TODO table_cell_class_list
            // { title: 'None', value: '' },
            // { title: 'No Border', value: 'table_cell_no_border' },
            // { title: 'Red border', value: 'table_cell_red_border' },
            // { title: 'Blue border', value: 'table_cell_blue_border' },
            // { title: 'Green border', value: 'table_cell_green_border' },
        ],
        table_row_class_list: [
            // TODO table_row_class_list
            // { title: 'None', value: '' },
            // { title: 'No Border', value: 'table_row_no_border' },
            // { title: 'Red border', value: 'table_row_red_border' },
            // { title: 'Blue border', value: 'table_row_blue_border' },
            // { title: 'Green border', value: 'table_row_green_border' },
        ],
        table_background_color_map: [
            // TODO table_background_color_map
            // { title: 'Red', value: 'FF0000' },
            // { title: 'White', value: 'FFFFFF' },
            // { title: 'Yellow', value: 'F1C40F' },
        ],
        // ...

        // template TODO
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        // ...

        // a11ychecker
        a11y_advanced_options: true,

        // advtable TODO

        // typography TODO not working
        // typography_default_lang: 'en-US',
        // typography_ignore: ['code'],

        // editimage TODO

        // export
        export_ignore_elements: ['iframe', 'video', 'audio'],
        // ...

        // footnotes TODO

        // permanentpen TODO fonts

        // powerpaste ?
        paste_as_text: true,

        // tinydrive TODO

        templates: [
            {
                title: 'Application inventory',
                description: '',
                content: ApplicationInventoryTemplate,
            },
        ],
    };
}
