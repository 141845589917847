import React from 'react';

import { useDeleteDocument } from '@SERVICES';
import useDeleteDocumentModal from '@HOOKS/store/modals/useDeleteDocumentModal';

import DeleteItemModal from '@MODALS/common/DeleteItemModal';

function DeleteDocumentModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteDocumentModal();

    const [
        deleteDocument,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteDocument();

    return (
        <DeleteItemModal
            modalTitle="Delete document"
            name={modalParams!.name}
            itemName="Document"
            description="All chapters of this document will also be deleted!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteDocument({
                    id: modalParams!.id,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteDocumentModal;
