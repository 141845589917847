/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DeleteUserModalState {
    isOpen: boolean;

    data: {
        id: number;
        name: string;
    } | null;
}

export interface DeleteUserModalDispatchParams {
    id: number;
    name: string;
}

const initialState: DeleteUserModalState = {
    isOpen: false,
    data: null,
};

export const deleteUserModalSlice = createSlice({
    name: 'delete-user-modal',
    initialState,
    reducers: {
        open(state: DeleteUserModalState, action: PayloadAction<DeleteUserModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: DeleteUserModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
