import React from 'react';

import classnames from 'classnames/bind';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleDocument } from '@SERVICES';

import BasicIconButton from '@COMPONENTS/COMMON/buttons/BasicIconButton';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './DocumentName.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentName(props: Props) {
    const { documentData } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions,
    } = useDocumentNameModal(() => true);

    const {
        actions: previewDocumentModalActions,
    } = usePreviewDocumentModal(() => true);

    const locked = !canEditDocument;

    return (
        <div className={cx('document-name', 'container')}>
            <div className={cx('name-wrapper')}>
                <div className={cx('name')}>
                    {`Document - ${documentData.data.name}`}
                </div>
                {
                    locked
                        ? (
                            <Lock size="medium" />
                        ) : (

                            <div className={cx('edit-document-name')}>
                                <div className={cx('button')}>
                                    <BasicIconButton
                                        tooltip="Edit document name"
                                        icon="edit"
                                        width={18}
                                        height={18}
                                        color={COLOR['marriott-light-grey']}
                                        onClick={() => {
                                            actions.open({
                                                documentData: documentData.data,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )
                }
                <div className={cx('preview-document')}>
                    <div className={cx('button')}>
                        <BasicIconButton
                            tooltip="Document preview"
                            icon="preview"
                            width={18}
                            height={18}
                            color={COLOR['marriott-light-grey']}
                            onClick={() => {
                                previewDocumentModalActions.open({
                                    documentData: documentData.data,
                                    canGenerateDocument,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={cx('document-owner')}>
                <div className={cx('owner')}>
                    Owner:
                </div>
                <div className={cx('name')}>
                    {documentData.data.owner.fullName || documentData.data.owner.email}
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentName;
