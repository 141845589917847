import React from 'react';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionData,
    AdminSingleSectionMeta,
    AdminSingleSectionDataVariant,
} from '@INTERFACES/admin-api/sections';

import Options from '@COMPONENTS/SIDEBARS/VariantSidebar/components/Options';

import styles from './VariantSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function VariantSidebar(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    return (
        <div className={cx('variant-sidebar')}>
            <Options
                documentId={sectionData.document.id}
                chapterId={sectionData.chapter.id}
                sectionId={sectionData.id}
                variant={variant}
                sectionMeta={sectionMeta}
                documentOwnerId={sectionData.document.owner}
            />
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantSidebar;
