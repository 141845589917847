import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminUnpublishedDocument, usePublishAll } from '@SERVICES';
import usePublishDocumentModal from '@HOOKS/store/modals/usePublishDocumentModal';
import useAuth from '@HOOKS/useAuth';

import { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';
import BaseModalSidebar from '@MODALS/Publications/components/BaseModalSidebar';

function ModalSidebar(props: Props) {
    const {
        documentData,
        selectedChaptersIds,
        selectedSectionsIds,
        selectedVariantsIds,
    } = props;

    const { state: { user } } = useAuth();

    const {
        actions: publishDocumentModalActions,
    } = usePublishDocumentModal(() => true);

    const [
        publishAll,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = usePublishAll();

    useEffect(() => {
        if (isSuccess) {
            notify.info('Document has been published', {
                toastId: 'publish-document',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'publish-document-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'publish-document-error',
                });
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (isSuccess) {
            const isPublished = (
                documentData
                && documentData.chapters.every((chapter) => (
                    chapter.introductionText.isPublished
                    && chapter.sections.every((section) => (
                        section.baseText.isPublished
                        && section.variants.every((v) => v.isPublished)
                    ))
                ))
            );

            if (isPublished) {
                publishDocumentModalActions.close();
            }
        }
    }, [isSuccess, documentData, publishDocumentModalActions]);

    const hasSelected = selectedChaptersIds.size > 0 || selectedSectionsIds.size > 0 || selectedVariantsIds.size > 0;

    function onSave(formValues: PublishFormValues, includeToVersionControl: boolean) {
        let description = null;

        if (includeToVersionControl) {
            description = formValues.description;
        }

        if (documentData) {
            const chapters: any[] = [];

            const sections: any[] = [];

            const variants: any[] = [];

            documentData.chapters.forEach((chapter) => {
                if (selectedChaptersIds.has(chapter.id)) {
                    chapters.push({
                        id: chapter.id,
                        documentId: documentData.id,
                        body: chapter.introductionText.body,
                    });
                }
            });

            documentData.chapters.forEach((chapter) => {
                const { sections: s } = chapter;

                s.forEach((section) => {
                    if (selectedSectionsIds.has(section.id)) {
                        sections.push({
                            id: section.id,
                            chapterId: chapter.id,
                            documentId: documentData.id,
                            body: section.baseText.body,
                        });
                    }
                });
            });

            documentData.chapters.forEach((chapter) => {
                const { sections: s } = chapter;

                s.forEach((section) => {
                    const { variants: v } = section;

                    v.forEach((variant) => {
                        if (selectedVariantsIds.has(variant.id)) {
                            variants.push({
                                id: variant.id,
                                sectionId: section.id,
                                chapterId: chapter.id,
                                documentId: documentData.id,
                                body: variant.body,
                            });
                        }
                    });
                });
            });

            publishAll({
                type: 'document',
                documentId: documentData.id,
                data: {
                    description,
                    userId: user!.id,
                    chapters,
                    sections,
                    variants,
                },
            });
        }
    }

    return (
        <BaseModalSidebar
            isLoading={isLoading}
            hasSelected={hasSelected}
            onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                onSave(formValues, includeToVersionControl);
            }}
        />
    );
}

ModalSidebar.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
};

type Props = InferProps<typeof ModalSidebar.propTypes> & {
    documentData: AdminUnpublishedDocument | undefined,
};

export default ModalSidebar;
