/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DeleteChapterModalState {
    isOpen: boolean;

    data: {
        id: number;

        name: string;

        documentId: number;
    } | null;
}

export interface DeleteChapterModalDispatchParams {
    id: number;

    name: string;

    documentId: number;
}

const initialState: DeleteChapterModalState = {
    isOpen: false,
    data: null,
};

export const deleteChapterModalSlice = createSlice({
    name: 'delete-chapter-modal',
    initialState,
    reducers: {
        open(state: DeleteChapterModalState, action: PayloadAction<DeleteChapterModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: DeleteChapterModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
