import React from 'react';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';

import BasicIconButton from '@COMPONENTS/COMMON/buttons/BasicIconButton';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './ChapterName.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterName(props: Props) {
    const { chapterData, chapterMeta } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const {
        actions,
    } = useChapterNameModal(() => true);

    const {
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    return (
        <div className={cx('container', 'chapter-name')}>
            <div className={cx('name')}>
                {`Chapter - ${chapterData.name}`}
            </div>
            {
                !canEditDocument
                    ? (
                        <Lock size="medium" />
                    ) : (
                        <div className={cx('edit-chapter-name')}>
                            <div className={cx('button')}>
                                <BasicIconButton
                                    tooltip="Edit chapter name"
                                    icon="edit"
                                    width={18}
                                    height={18}
                                    color={COLOR['marriott-light-grey']}
                                    onClick={() => {
                                        actions.open({
                                            chapterData,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )
            }
            <div className={cx('preview-chapter')}>
                <div className={cx('button')}>
                    <BasicIconButton
                        tooltip="Chapter preview"
                        icon="preview"
                        width={18}
                        height={18}
                        color={COLOR['marriott-light-grey']}
                        onClick={() => {
                            previewChapterModalActions.open({
                                chapterData,
                                canGenerateDocument,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default ChapterName;
