import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './PublishAllButton.module.scss';

const cx: CX = classnames.bind(styles);

function PublishAllButton(props: Props) {
    const { disabled, onClick, canPublishDocument } = props;

    const locked = !canPublishDocument;

    return (
        <button
            type="button"
            className={cx('publish-all-button', 'clear-button', {
                locked,
            })}
            onClick={() => {
                if (!locked) {
                    onClick();
                }
            }}
            disabled={disabled}
        >
            {
                locked
                    ? (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    ) : (
                        <div className={cx('icon-wrapper')}>
                            <div className={cx('icon')}>
                                &raquo;
                            </div>
                        </div>
                    )
            }
            <div className={cx('text')}>
                Publish all
            </div>
        </button>
    );
}

PublishAllButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    canPublishDocument: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof PublishAllButton.propTypes>;

export default PublishAllButton;
