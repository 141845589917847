import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Subheader.module.scss';

const cx: CX = classnames.bind(styles);

function Subheader({ title }: Props) {
    return (
        <div className={cx('subheader')}>
            <div className="container max-constraint center">
                <div className={cx('content-wrapper')}>
                    {
                        title.split(' %s ').map((segment, i) => {
                            const key = `segment-${i}`;

                            return (
                                <div
                                    key={key}
                                    className={cx('segment')}
                                >
                                    {segment}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

Subheader.propTypes = {
    title: PropTypes.string.isRequired,
};

type Props = InferProps<typeof Subheader.propTypes>;

export default Subheader;
