import React, { RefObject, useRef, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { Role, useGetAllRoles } from '@SERVICES';

import styles from './Roles.module.scss';
import { SingleValue } from 'react-select';

import MyltiplySelect from '@COMPONENTS/COMMON/inputs/select/MyltiplySelect';
import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';

const cx: CX = classnames.bind(styles);

const defaultData = {
    data: [],
};

function Roles(props: Props) {
    const {
        roles,
        setRoles,
    } = props;

    const {
        data: rolesData = defaultData, isLoading,
    } = useGetAllRoles();

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const isRoleSelected = (id: number) => roles.findIndex((role: any) => role.id === id) !== -1;

    const editRoles = (role: any) => {
        const roleIndex = roles.findIndex((r: any) => r.id === role.id);
        if (roleIndex === -1) {
            setRoles([...roles, role]);
        } else {
            const rolesArr = JSON.parse(JSON.stringify(roles));
            rolesArr.splice(roleIndex, 1);
            setRoles(rolesArr);
        }
    };

    return (
        <div className={cx('properties')}>
            <div className={cx('properties-title')}>
                Roles
            </div>
            <div className={cx('label')}>
                Choose User Roles. Defines who can download which parts of the documents on the chapter and section
                level.
            </div>
            <div className={cx('roles-flex')}>
                <div className={cx('role')}>
                    <BasicCheckbox
                        title="All"
                        fontSize={14}
                        fontWeight={600}
                        checked={rolesData.data.length === roles.length}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setRoles(rolesData.data);
                            } else {
                                setRoles([]);
                            }
                        }}
                    />
                </div>
                {
                    rolesData.data.map((role) => (
                        <div key={role.id} className={cx('role')}>
                            <BasicCheckbox
                                title={role.name}
                                fontSize={14}
                                fontWeight={400}
                                checked={isRoleSelected(role.id) || false}
                                onChange={() => {
                                    editRoles(role);
                                }}
                            />
                        </div>
                    ))
                }
            </div>
            {roles.length === 0 && <div className={cx('required')}>At least one role must be assigned.</div>}
            {/* <MyltiplySelect */}
            {/*     title="Choose User Roles. Defines who can download which parts of the documents on the chapter and section level." */}
            {/*     placeholder="Select role" */}
            {/*     isSearchable */}
            {/*     options={rolesData.data} */}
            {/*     value={roles} */}
            {/*     menuPlacement="top" */}
            {/*     isLoading={isLoading} */}
            {/*     requiredText="At least one role must be assigned." */}
            {/*     onMenuOpen={() => { */}
            {/*         if (selectWrapperRef.current) { */}
            {/*             selectWrapperRef.current.style.zIndex = '5'; */}
            {/*         } */}
            {/*     }} */}
            {/*     onMenuClose={() => { */}
            {/*         if (selectWrapperRef.current) { */}
            {/*             selectWrapperRef.current.style.zIndex = 'unset'; */}
            {/*         } */}
            {/*     }} */}
            {/*     onChange={(val: SingleValue<any>) => { */}
            {/*         setRoles(val); */}
            {/*     }} */}
            {/* /> */}

        </div>
    );
}

Roles.propTypes = {
    setRoles: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Roles.propTypes> & {
    roles: any,
};

export default Roles;
