import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import ReactDatePicker from 'react-datepicker';

import classNames from 'classnames/bind';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';

const cx: CX = classNames.bind(styles);

function DatePicker(props: Props) {
    const {
        date,
        setDate,
        disabled,
    } = props;

    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <ReactDatePicker
            selected={date}
            onChange={(d) => {
                if (!disabled) {
                    setDate(d);
                }
            }}
            className={cx('date-picker', {
                'is-open': isOpen,
                disabled,
            })}
            showTimeSelect
            isClearable
            disabled={disabled}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Never"
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        />
    );
}

DatePicker.defaultProps = {
    disabled: false,
};

DatePicker.propTypes = {
    setDate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

type Props = InferProps<typeof DatePicker.propTypes> & typeof DatePicker.defaultProps & {
    date: Date | null,
};

export default DatePicker;
