import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ContentTag } from '@INTERFACES/api/content';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';
import BasicInput from '@COMPONENTS/COMMON/inputs/input/BasicInput';
import TagCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/TagCheckbox';
import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './SelectContentSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function SelectContentSidebar(props: Props) {
    const {
        isOnlyRequiredSections,
        setOnlyRequiredSections,
        tags,
        isTagsLoading,
        selectedTagsIds,
        setSelectedTagsIds,
        searchValue,
        setSearchValue,
    } = props;

    function handleInputChange(val: string) {
        setSearchValue(val);
    }

    return (
        <div className={cx('select-content-sidebar')}>
            <div className={cx('container-left')}>
                <div className={cx('sidebar-title')}>
                    Filter sections
                </div>

                <div className={cx('sections-search-wrapper')}>
                    <BasicInput
                        value={searchValue}
                        label="Search"
                        onChange={(val) => handleInputChange(val)}
                        placeholder="Search by section name"
                    />
                </div>

                <div className={cx('delimiter')} />

                <div className={cx('filter-sections')}>
                    <div className={cx('tags')}>
                        <div className={cx('tags-title')}>
                            Tags
                        </div>
                        {
                            !isTagsLoading
                                ? (
                                    <div className={cx('tags-list')}>
                                        {
                                            tags.length > 0
                                                ? tags.map((tag) => {
                                                    const key = tag.id;

                                                    const checked = selectedTagsIds.has(tag.id);

                                                    return (
                                                        <div
                                                            key={key}
                                                            className={cx('tags-list-item')}
                                                        >
                                                            <TagCheckbox
                                                                title={tag.name}
                                                                checked={checked}
                                                                onChange={(val: boolean) => {
                                                                    const set = new Set(selectedTagsIds);

                                                                    if (val) {
                                                                        set.add(tag.id);
                                                                    } else {
                                                                        set.delete(tag.id);
                                                                    }

                                                                    setSelectedTagsIds(set);
                                                                }}
                                                            />
                                                        </div>

                                                    );
                                                })
                                                : (
                                                    <div className={cx('tags-not-found')}>
                                                        Tags not found
                                                    </div>
                                                )
                                        }
                                    </div>
                                )
                                : (
                                    <div className={cx('tags-loader')}>
                                        <CircularProgressIndicator
                                            color={COLOR['marriott-light-grey']}
                                            size={26}
                                            thickness={3}
                                        />
                                    </div>
                                )
                        }
                    </div>

                    <div className={cx('delimiter')} />

                    {/* <div className={cx('other-filters')}> */}
                    {/*    <div className={cx('other-filters-subtitle')}> */}
                    {/*        Other filters */}
                    {/*    </div> */}

                    {/*    <BasicCheckbox */}
                    {/*        checked={isOnlyRequiredSections} */}
                    {/*        fontSize={14} */}
                    {/*        fontWeight={700} */}
                    {/*        onChange={() => { */}
                    {/*            setOnlyRequiredSections(!isOnlyRequiredSections); */}
                    {/*        }} */}
                    {/*        title="Only required sections" */}
                    {/*    /> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
}

SelectContentSidebar.propTypes = {
    isOnlyRequiredSections: PropTypes.bool.isRequired,
    setOnlyRequiredSections: PropTypes.func.isRequired,
    selectedTagsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedTagsIds: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    isTagsLoading: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof SelectContentSidebar.propTypes> & { tags: ContentTag[] };

export default SelectContentSidebar;
