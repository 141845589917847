import React from 'react';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import { useUpdateDocument } from '@SERVICES';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';

function DocumentNameModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDocumentNameModal();

    const [updateDocument, {
        isLoading, isSuccess, isError, error,
    }] = useUpdateDocument();

    const { documentData } = modalParams!;

    const onSave = (formValues: FormValues) => {
        updateDocument({
            id: documentData.id,
            name: formValues.name,
            cover: '',
        });
    };

    return (
        <NameInputModal
            modalTitle="Update document name"
            itemName="Document"
            inputPlaceholder="Document name"
            successMessage="Document name has been updated!"
            initialInputValue={documentData.name}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DocumentNameModal;
