import React, { ChangeEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as CheckboxIcon } from '@ICONS/checkbox-icon.svg';

import COLOR from '@CONSTANTS/COLOR.constant';
import styles from './BasicCheckbox.module.scss';

const cx: CX = classnames.bind(styles);

function BasicCheckbox(props: Props) {
    const {
        title,
        fontSize,
        fontWeight,
        color,
        checked,
        disabled,
        onChange,
    } = props;

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event);
    }

    return (
        <div
            className={cx('basic-checkbox', {
                disabled,
            })}
        >
            <label>
                <input
                    className="visually-hidden"
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                />
                <div className={cx('icon')}>
                    <div className={cx('icon-wrapper')}>
                        <CheckboxIcon />
                    </div>
                </div>
                {title && (
                    <div
                        style={{
                            fontSize,
                            fontWeight,
                            color,
                        }}
                        className={cx('title')}
                    >
                        {title}
                    </div>
                )}
            </label>
        </div>
    );
}

BasicCheckbox.defaultProps = {
    fontSize: 12,
    fontWeight: 400,
    color: COLOR['marriott-dark-grey'],
    disabled: false,
};

BasicCheckbox.propTypes = {
    title: PropTypes.string.isRequired,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
    color: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BasicCheckbox.propTypes> & typeof BasicCheckbox.defaultProps;

export default BasicCheckbox;
