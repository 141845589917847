import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { useGetSectionByIdAdmin } from '@SERVICES';
import { AdminSingleSection } from '@INTERFACES/admin-api/sections';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';
import usePublishVariantModal from '@HOOKS/store/modals/usePublishVariantModal';
import usePublishSectionModal from '@HOOKS/store/modals/usePublishSectionModal';
import usePublishSectionBaseTextModal from '@HOOKS/store/modals/usePublishSectionBaseTextModal';
import useDeleteVariantModal from '@HOOKS/store/modals/useDeleteVariantModal';
import usePreviewSectionBaseTextModal from '@HOOKS/store/modals/usePreviewSectionBaseTextModal';
import usePreviewVariantModal from '@HOOKS/store/modals/usePreviewVariantModal';
import useSectionBaseTextHistoryModal from '@HOOKS/store/modals/useSectionBaseTextHistoryModal';
import useVariantHistoryModal from '@HOOKS/store/modals/useVariantHistoryModal';
import useNewTagInSectionModal from '@HOOKS/store/modals/useNewTagInSectionModal';
import useNewTagInVariantModal from '@HOOKS/store/modals/useNewTagInVariantModal';

import Content from '@PAGES/admin/edit-section/components/Content';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';
import EditSectionSidebar from '@COMPONENTS/SIDEBARS/EditSectionSidebar';
import SidebarHeader from '@COMPONENTS/SIDEBARS/EditSectionSidebar/components/SidebarHeader';

import styles from './EditSection.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

const defaultData: AdminSingleSection = {
    data: null,
    meta: null,
};

function EditSection() {
    const params = useParams();

    const { id } = params;

    const {
        data: sectionData = defaultData, isLoading, isFetching, isError, error,
    } = useGetSectionByIdAdmin(+id!);

    const {
        actions: sectionNameModalActions,
    } = useSectionNameModal(() => true);

    const {
        actions: newVariantModalActions,
    } = useNewVariantModal(() => true);

    const {
        actions: publishSectionModalActions,
    } = usePublishSectionModal(() => true);

    const {
        actions: publishSectionBaseTextModalActions,
    } = usePublishSectionBaseTextModal(() => true);

    const {
        actions: publishVariantModalActions,
    } = usePublishVariantModal(() => true);

    const {
        actions: deleteVariantModalActions,
    } = useDeleteVariantModal(() => true);

    const {
        actions: previewSectionBaseTextModalActions,
    } = usePreviewSectionBaseTextModal(() => true);

    const {
        actions: previewVariantModalActions,
    } = usePreviewVariantModal(() => true);

    const {
        actions: sectionBaseTextHistoryModalActions,
    } = useSectionBaseTextHistoryModal(() => true);

    const {
        actions: variantHistoryModalActions,
    } = useVariantHistoryModal(() => true);

    const {
        actions: newTagInSectionModalActions,
    } = useNewTagInSectionModal(() => true);

    const {
        actions: newTagInVariantModalActions,
    } = useNewTagInVariantModal(() => true);

    const [view, setView] = useState<'options' | 'sections' | 'filters'>('options');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    // CLOSE MODALS
    useEffect(() => () => {
        sectionNameModalActions.close();
        newVariantModalActions.close();
        publishVariantModalActions.close();
        publishSectionModalActions.close();
        publishSectionBaseTextModalActions.close();
        deleteVariantModalActions.close();
        previewSectionBaseTextModalActions.close();
        previewVariantModalActions.close();
        sectionBaseTextHistoryModalActions.close();
        variantHistoryModalActions.close();
        newTagInSectionModalActions.close();
        newTagInVariantModalActions.close();
    }, [
        id,
        sectionNameModalActions,
        newVariantModalActions,
        publishSectionModalActions,
        publishSectionBaseTextModalActions,
        publishVariantModalActions,
        deleteVariantModalActions,
        previewSectionBaseTextModalActions,
        previewVariantModalActions,
        sectionBaseTextHistoryModalActions,
        variantHistoryModalActions,
        newTagInSectionModalActions,
        newTagInVariantModalActions,
    ]);

    if (isError && (error as FetchBaseQueryError)?.status === 404) {
        return (
            <div className={cx('section-not-found', 'container')}>
                404 Section not found
            </div>
        );
    }

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebarHeader={(
                        <SidebarHeader
                            view={view}
                            setView={setView}
                        />
                    )}
                    sidebar={(
                        <EditSectionSidebar
                            sectionMeta={sectionData.meta!}
                            view={view}
                        />
                    )}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <Content
                            key={sectionData.data?.id || 0}
                            sectionData={sectionData.data!}
                            sectionMeta={sectionData.meta!}
                            view={view}
                            top={top}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default EditSection;
