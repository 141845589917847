import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import SectionSubheader from '@PAGES/admin/edit-chapter/components/SectionSubheader';
import Warning from '@COMPONENTS/SHARED/Warning';

import Variant from '@PAGES/admin/edit-chapter/components/Variant';
import styles from './SectionBody.module.scss';

const cx: CX = classnames.bind(styles);

function SectionBody(props: Props) {
    const { section } = props;

    const navigate = useNavigate();

    function getBaseTextWarning() {
        if (section.baseText.latestVersion === null) {
            return 'Base text is not published';
        }

        return 'Base text has unpublished changes';
    }

    return (
        <div className={cx('section-body')}>
            <SectionSubheader section={section} />

            {
                !section.baseText.isPublished
                && (
                    <div className={cx('base-text-warning-wrapper')}>
                        <Warning text={getBaseTextWarning()} />
                    </div>
                )
            }

            {
                section.tags.length > 0
                && (
                    <div className={cx('section-tags')}>
                        <div className={cx('section-tags-title')}>
                            Section tags
                        </div>
                        <div className={cx('section-tags-list')}>
                            {
                                section.tags.map((tag) => {
                                    const key = tag.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('section-tag-item')}
                                        >
                                            {tag.name}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }

            <div className={cx('variants')}>
                {
                    section.variants.length
                        ? (
                            <>
                                <div className={cx('variants-title')}>
                                    Variants
                                </div>
                                <div className={cx('variants-list')}>
                                    {
                                        section.variants.map((variant) => {
                                            const key = variant.id;

                                            return (
                                                <div
                                                    key={key}
                                                    tabIndex={0}
                                                    role="button"
                                                    className={cx('variant-item')}
                                                    onClick={() => {
                                                        navigate(ROUTES.admin.sections.id.get(section.id, variant.id));
                                                    }}
                                                >
                                                    <Variant
                                                        variant={variant}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </>
                        ) : (
                            <div className={cx('no-variants')}>
                                No variants
                            </div>
                        )
                }
            </div>
        </div>
    );
}

type Props = { section: AdminSingleChapterDataSection };

export default SectionBody;
