import { useEffect } from 'react';

const browserName = (function browserDetect(agent) {
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'MS Edge';
        case agent.indexOf('edg/') > -1:
            return 'Edge (chromium based)';
        case agent.indexOf('opr') > -1:
            return 'Opera';
        case agent.indexOf('chrome') > -1:
            return 'Chrome';
        case agent.indexOf('trident') > -1:
            return 'MS IE';
        case agent.indexOf('firefox') > -1:
            return 'Mozilla Firefox';
        case agent.indexOf('safari') > -1:
            return 'Safari';
        default:
            return 'other';
    }
}(window.navigator.userAgent.toLowerCase()));

function createScrollLockHook() {
    let SCROLL_Y = 0;

    function lock() {
        // position sticky bug?
        if (browserName === 'Mozilla Firefox') return;

        const { scrollY } = window;

        SCROLL_Y = scrollY;

        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = '100%';
    }

    function unlock() {
        if (browserName === 'Mozilla Firefox') return;

        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';

        window.scrollTo(0, SCROLL_Y || 0);
    }

    return function useScrollLock() {
        return {
            lock,
            unlock,
        };
    };
}

export default createScrollLockHook();
