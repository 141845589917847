import useAuth from '@HOOKS/useAuth';
import { useMemo } from 'react';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

type HookParams = {
    documentOwnerId: number;
    permissions: DOCUMENT_PERMISSIONS[],
};

function createDocumentPermissionsHook() {
    return function useDocumentPermissions(params: HookParams) {
        const {
            documentOwnerId,
            permissions,
        } = params;

        const { state: { user, permissions: userPermissions } } = useAuth();

        return useMemo(() => {
            function canActivate() {
                return (codename: DOCUMENT_PERMISSIONS) => permissions.includes(codename);
            }

            function wrap(fn: (arg: (codename: DOCUMENT_PERMISSIONS) => boolean) => boolean) {
                if (
                    user!.id === documentOwnerId
                    || userPermissions.isSuperuser
                ) return true;

                const activate = canActivate();

                return fn(activate);
            }

            return {
                canGenerateDocument: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_GENERATE_DOCUMENT)),
                canEditDocument: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_EDIT_DOCUMENT)),
                canDeleteDocument: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_DELETE_DOCUMENT)),
                canPublishDocument: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_PUBLISH_DOCUMENT)),
                canManageDocumentTags: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_TAGS)),
                canManageDocumentVendors: wrap((activate) => activate(DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_VENDORS)),
            };
        }, [documentOwnerId, permissions, user, userPermissions]);
    };
}

export default createDocumentPermissionsHook();
