import React from 'react';

import classnames from 'classnames/bind';

import { AdminDocumentListItem } from '@SERVICES';

import useNewDocumentModal from '@HOOKS/store/modals/useNewDocumentModal';
import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import Document from '@PAGES/admin/documents-list/components/Document';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const { documentsData } = props;

    const {
        canCreateDocuments,
    } = useGlobalPermissions();

    const {
        actions: newDocumentModalActions,
    } = useNewDocumentModal(() => true);

    return (
        <div className={cx('content')}>
            <div className={cx('grid')}>
                <div className={cx('grid-item')}>
                    <div className={cx('add-document-button')}>
                        <AddButton
                            locked={!canCreateDocuments}
                            title="New document"
                            fontSize={36}
                            onClick={() => {
                                newDocumentModalActions.open();
                            }}
                        />
                    </div>
                </div>

                {
                    documentsData.map((doc) => {
                        const key = doc.data.id;

                        return (
                            <div
                                key={key}
                                className={cx('grid-item')}
                            >
                                <Document doc={doc} />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

type Props = {
    documentsData: AdminDocumentListItem[],
};

export default Content;
