import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { OrderSectionsDrawerSection } from '@REDUCERS';
import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useOrderSectionsDrawer from '@HOOKS/store/drawers/useOrderSectionsDrawer';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';
import ChangeOrderButton from '@COMPONENTS/COMMON/buttons/ChangeOrderButton';

import styles from './SectionsStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function SectionsStickyHeader(props: Props) {
    const {
        top,
        chapterData,
        chapterMeta,
        sectionsOrder,
        setOpenSectionsIds,
    } = props;

    const { sections } = chapterData;

    const {
        actions: orderSectionsActions,
    } = useOrderSectionsDrawer(() => true);

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    return (
        <StickyHeader
            top={top}
        >
            <div
                id="sections-sticky-header"
                className={cx('sections-sticky-header', 'container')}
            >
                <div className={cx('sections-title-wrapper')}>
                    <div className={cx('title')}>
                        Sections
                    </div>
                    <PairToggleButtons
                        titleOn="Show all"
                        titleOff="Hide all"
                        onClickOn={() => {
                            const sectionsIds = sections.map((s) => s.id);

                            const set = new Set<number>(sectionsIds);

                            setOpenSectionsIds(set);
                        }}
                        onClickOff={() => {
                            setOpenSectionsIds(new Set());
                        }}
                    />
                </div>

                <div className={cx('buttons-wrapper')}>
                    <div
                        id="order-sections-button-wrapper"
                        className={cx('order-sections-button')}
                    >
                        <ChangeOrderButton
                            onClick={() => {
                                orderSectionsActions.open({
                                    documentId: chapterData.document.id,
                                    chapterId: chapterData.id,
                                    sections: sectionsOrder,
                                    canEditDocument,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </StickyHeader>
    );
}

SectionsStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    setOpenSectionsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SectionsStickyHeader.propTypes> & {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
    sectionsOrder: OrderSectionsDrawerSection[],
};

export default SectionsStickyHeader;
