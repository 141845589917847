import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ContentSection } from '@INTERFACES/api/content';

import SquareCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/SquareCheckbox';
import VariantRadio from '@COMPONENTS/COMMON/inputs/radio/VariantRadio';

import styles from './GridView.module.scss';

const cx: CX = classnames.bind(styles);

function GridView(props: Props) {
    const {
        sections,
        selectedSectionsIds,
        selectedVariantsIds,
        setSelectedSectionsIds,
        setSelectedVariantsIds,
        isPartial,
        isChapterMandatory,
    } = props;

    return (
        <div className={cx('container')}>
            <div className={cx('grid-view')}>
                {
                    sections.map((section, j) => {
                        const sectionKey = section.id;
                        const { variants } = section;

                        const checked = selectedSectionsIds.has(section.id);

                        const availableVariantsIds = variants.map((v) => v.id);

                        const defaultVariant = variants.find((v) => v.isDefault) || variants[0];

                        const hasVariants = !(variants.length === 1 && variants[0].isDefault) && variants.length > 0;

                        return (
                            <div
                                key={sectionKey}
                                className={cx('grid-item', {
                                    checked,
                                    'has-variants': hasVariants,
                                })}
                            >
                                <div className={cx('section-wrapper')}>
                                    <SquareCheckbox
                                        header={`Section ${j + 1}`}
                                        title={section.name}
                                        isMandatory={isPartial ? false : (isChapterMandatory || section.isMandatory)}
                                        checked={checked}
                                        onChange={(val: boolean) => {
                                            if (variants.length === 0) {
                                                const sectionsSet = new Set(selectedSectionsIds);

                                                if (val) {
                                                    sectionsSet.add(section.id);
                                                } else {
                                                    sectionsSet.delete(section.id);
                                                }

                                                setSelectedSectionsIds(sectionsSet);
                                            } else {
                                                const sectionsSet = new Set(selectedSectionsIds);
                                                const variantsSet = new Set(selectedVariantsIds);

                                                if (val) {
                                                    sectionsSet.add(section.id);

                                                    variantsSet.add(defaultVariant.id);
                                                } else {
                                                    sectionsSet.delete(section.id);

                                                    availableVariantsIds.forEach((id) => {
                                                        variantsSet.delete(id);
                                                    });
                                                }

                                                setSelectedSectionsIds(sectionsSet);
                                                setSelectedVariantsIds(variantsSet);
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    checked && hasVariants
                                    && (
                                        <div className={cx('variants')}>
                                            <div className={cx('variants-title')}>
                                                Variants
                                            </div>
                                            <div className={cx('variants-list')}>
                                                {
                                                    variants.map((variant) => {
                                                        const key = variant.id;

                                                        const variantChecked = selectedVariantsIds.has(variant.id);

                                                        return (
                                                            <div
                                                                key={key}
                                                                className={cx('variant')}
                                                            >
                                                                <VariantRadio
                                                                    variant={variant}
                                                                    name={`section-${section.id}-variants`}
                                                                    checked={variantChecked}
                                                                    onChange={(val: number) => {
                                                                        const variantsSet = new Set(selectedVariantsIds);

                                                                        availableVariantsIds.forEach((id) => {
                                                                            if (id !== val) {
                                                                                variantsSet.delete(id);
                                                                            } else {
                                                                                variantsSet.add(val);
                                                                            }
                                                                        });

                                                                        setSelectedVariantsIds(variantsSet);
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

GridView.propTypes = {
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
    isPartial: PropTypes.bool.isRequired,
    isChapterMandatory: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof GridView.propTypes> & { sections: ContentSection[] };

export default GridView;
