import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import { SectionHistoryBaseText } from '@SERVICES';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import Warning from '@COMPONENTS/SHARED/Warning';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import styles from './BaseTextInfo.module.scss';

const cx: CX = classnames.bind(styles);

function BaseTextInfo(props: Props) {
    const { sectionId, baseText } = props;

    const navigate = useNavigate();

    function getWarning() {
        if (baseText.versions.length === 0) {
            return 'Base text is not published';
        }

        return 'Base text has unpublished changes';
    }

    return (
        <div className={cx('base-text-info')}>
            <div className={cx('info')}>
                {
                    !baseText.isPublished
                    && (
                        <>
                            <div className={cx('warning-wrapper')}>
                                <Warning text={getWarning()} />
                            </div>
                            <div className={cx('delimiter')} />
                        </>
                    )
                }

                <div className={cx('edit-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'edit-button')}
                        onClick={() => {
                            navigate(ROUTES.admin.sections.id.get(sectionId));
                        }}
                    >
                        <EditIcon
                            width={20}
                            height={20}
                        />
                        <div className={cx('edit-button-title')}>
                            Edit section
                        </div>
                    </button>
                </div>

                <div className={cx('title')}>
                    Base text
                </div>
            </div>
        </div>
    );
}

BaseTextInfo.propTypes = {
    sectionId: PropTypes.number.isRequired,
};

type Props = InferProps<typeof BaseTextInfo.propTypes> & {
    baseText: SectionHistoryBaseText,
};

export default BaseTextInfo;
