import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';

export const productsAdminApi = createApi({
    reducerPath: 'products-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: () => ({
        //
    }),
});
