import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSearchInsideDocument, useSearchInsideDocumentLazy } from '@SERVICES';

import ResultSetWrapper from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultSetWrapper';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import ChapterStickyHeader from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ChapterStickyHeader';
import SectionStickyHeader from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/SectionStickyHeader';
import ResultItemTitle from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultItemTitle';

import styles from './DocumentResults.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentResults(props: Props) {
    const {
        id,
        q,
        setLoading,
        isFocused,
        setFocused,
    } = props;

    const [
        searchInsideDocument,
        {
            data, isLoading, isError, error, requestId,
        },
    ] = useSearchInsideDocumentLazy();

    const navigate = useNavigate();

    const [results, setResults] = useState<AdminSearchInsideDocument | undefined>(undefined);

    const showResultSet = (
        q.length > 2
        && results !== undefined
        && isFocused
    );

    useLayoutEffect(() => {
        setLoading(isLoading);
    }, [setLoading, isLoading]);

    useLayoutEffect(() => {
        if (data) {
            setResults(data);
        }
    }, [data, requestId]);

    useLayoutEffect(() => {
        if (q.length <= 2) {
            setResults(undefined);
        }
    }, [q]);

    useEffect(() => {
        if (q.trim().length > 2) {
            const result = searchInsideDocument({
                id,
                q,
            });

            return () => {
                result.abort();
            };
        }
    }, [id, q, searchInsideDocument]);

    if (!showResultSet) return null;

    const count = results?.meta.count || 0;
    const resultsQuery = results?.meta.query || '';

    return (
        <ResultSetWrapper
            setFocused={setFocused}
            count={count}
            resultsQuery={resultsQuery}
        >
            <div className={cx('document-results')}>
                {
                    results?.data.chapters.length > 0
                    && (
                        <div className={cx('chapters-list')}>
                            {
                                results?.data.chapters.map((chapter) => {
                                    const chapterKey = chapter.id;

                                    return (
                                        <div
                                            key={chapterKey}
                                            className={cx('chapter')}
                                        >
                                            <ChapterStickyHeader
                                                name={chapter.name}
                                                top={1}
                                                zIndex={6}
                                            />

                                            {
                                                chapter.body
                                                && (
                                                    <div className={cx('chapter-introduction-text')}>
                                                        <ResultItemTitle
                                                            title="Introduction text"
                                                            onClick={() => {
                                                                navigate(ROUTES.admin.chapters.id.get(chapter.id));
                                                            }}
                                                        />
                                                        <DocumentPreviewPage
                                                            isLandscape={false}
                                                            body={chapter.body}
                                                            isPreview
                                                        />
                                                    </div>
                                                )
                                            }

                                            {
                                                chapter.sections.length > 0
                                                && (
                                                    <div className={cx('sections-list')}>
                                                        {
                                                            chapter.sections.map((section) => {
                                                                const sectionKey = section.id;

                                                                return (
                                                                    <div
                                                                        key={sectionKey}
                                                                        className={cx('section')}
                                                                    >
                                                                        <SectionStickyHeader
                                                                            name={section.name}
                                                                            top={35}
                                                                            zIndex={5}
                                                                        />

                                                                        {
                                                                            section.body
                                                                            && (
                                                                                <div className={cx('section-base-text')}>
                                                                                    <ResultItemTitle
                                                                                        title="Base text"
                                                                                        onClick={() => {
                                                                                            navigate(ROUTES.admin.sections.id.get(section.id));
                                                                                        }}
                                                                                    />

                                                                                    <DocumentPreviewPage
                                                                                        isLandscape={section.isLandscape}
                                                                                        body={section.body}
                                                                                        isPreview
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        {
                                                                            section.variants.length > 0
                                                                            && (
                                                                                <div className={cx('variants-list')}>
                                                                                    {
                                                                                        section.variants.map((variant) => {
                                                                                            const variantKey = variant.id;

                                                                                            return (
                                                                                                <div
                                                                                                    key={variantKey}
                                                                                                    className={cx('variant')}
                                                                                                >
                                                                                                    <ResultItemTitle
                                                                                                        title={variant.name}
                                                                                                        onClick={() => {
                                                                                                            navigate(ROUTES.admin.sections.id.get(section.id, variant.id));
                                                                                                        }}
                                                                                                    />

                                                                                                    <DocumentPreviewPage
                                                                                                        isLandscape={variant.isLandscape}
                                                                                                        body={variant.body}
                                                                                                        isPreview
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
        </ResultSetWrapper>
    );
}

DocumentResults.propTypes = {
    id: PropTypes.number.isRequired,
    q: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    setFocused: PropTypes.func.isRequired,
};

type Props = InferProps<typeof DocumentResults.propTypes>;

export default DocumentResults;
