import React, {
    RefObject,
    useId,
    useRef,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as CheckboxIcon } from '@ICONS/checkbox-icon.svg';

import styles from './SquareCheckbox.module.scss';

const cx: CX = classnames.bind(styles);

function SquareCheckbox(props: Props) {
    const {
        header, title, isMandatory, checked, onChange,
    } = props;

    const ref: RefObject<HTMLDivElement> = useRef(null);

    const id = useId();

    function handleChange(val: boolean) {
        onChange(val);
    }

    return (
        <div
            ref={ref}
            className={
                cx({
                    'square-checkbox': true,
                    mandatory: isMandatory,
                })
            }
        >
            {
                isMandatory
                && (
                    <div className={cx('mandatory-label')}>
                        Mandatory
                    </div>
                )
            }

            <input
                id={id}
                type="checkbox"
                className="visually-hidden"
                checked={isMandatory || checked}
                onChange={() => {
                    if (!isMandatory) {
                        handleChange(!checked);
                    }
                }}
            />
            <label htmlFor={id}>
                <div className={cx('icon-container')}>
                    {
                        header && (
                            <div className={cx('header')}>
                                {header}
                            </div>
                        )
                    }
                    <div className={cx('icon-wrapper')}>
                        <CheckboxIcon />
                    </div>
                </div>

                <div className={cx('title-container')}>
                    <div className={cx('title')}>
                        {title}
                    </div>
                </div>
            </label>
        </div>
    );
}

SquareCheckbox.defaultProps = {
    header: '',
    isMandatory: false,
};

SquareCheckbox.propTypes = {
    header: PropTypes.string,
    title: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SquareCheckbox.propTypes> & typeof SquareCheckbox.defaultProps;

export default React.memo(SquareCheckbox);
