import React, { useLayoutEffect } from 'react';
import {
    matchRoutes, RouteObject, useLocation, useParams,
} from 'react-router-dom';

import { useGetDocumentByIdAdminLazy, useGetChapterByIdAdminLazy, useGetSectionByIdAdminLazy } from '@SERVICES';

import Subheader from '@COMPONENTS/HEADERS/Subheader';
import ROUTES from '@CONSTANTS/ROUTES.constant';

const routes: RouteObject[] = [
    { path: ROUTES.admin.documents.path },
    { path: ROUTES.admin.documents.id.path },
    { path: ROUTES.admin.chapters.id.path },
    { path: ROUTES.admin.sections.id.path },
    { path: ROUTES.admin.manageVendors.path },
    { path: ROUTES.admin.logs.path },
    { path: ROUTES.admin.manageUsersPermissions.path },
];

function AdminSubheader() {
    const [getDocument, { data: documentData, isFetching: isDocumentFetching }] = useGetDocumentByIdAdminLazy();
    const [getChapter, { data: chapterData, isFetching: isChapterFetching }] = useGetChapterByIdAdminLazy();
    const [getSection, { data: sectionData, isFetching: isSectionFetching }] = useGetSectionByIdAdminLazy();

    const location = useLocation();
    const params = useParams();

    const [{ route }] = matchRoutes(routes, location) || [];

    const isDocumentPage = route.path === ROUTES.admin.documents.id.path;
    const isChapterPage = route.path === ROUTES.admin.chapters.id.path;
    const isSectionPage = route.path === ROUTES.admin.sections.id.path;
    const isVendorsPage = route.path === ROUTES.admin.manageVendors.path;
    const isLogsPage = route.path === ROUTES.admin.logs.path;
    const isUserPermissionsPage = route.path === ROUTES.admin.manageUsersPermissions.path;

    useLayoutEffect(() => {
        const { id } = params;

        if (isDocumentPage) {
            getDocument(+id!, true);
        } else if (isChapterPage) {
            getChapter(+id!, true);
        } else if (isSectionPage) {
            getSection(+id!, true);
        }
    }, [
        isDocumentPage,
        isChapterPage,
        isSectionPage,
        getDocument,
        getChapter,
        getSection,
        params,
    ]);

    function getTitle() {
        if (isVendorsPage) {
            return 'Manage Vendors';
        }

        if (isLogsPage) {
            return 'Manage Logs';
        }

        if (isUserPermissionsPage) {
            return 'Manage Users Permissions';
        }

        const main = 'Manage Document Templates';

        if (isDocumentFetching || isChapterFetching || isSectionFetching) {
            return main;
        }

        const documentName = documentData?.data?.name;
        const chapterName = chapterData?.data?.name;
        const sectionName = sectionData?.data?.name;

        if (isDocumentPage) {
            if (documentName) {
                return [
                    main,
                    ' %s Document - ',
                    documentName,
                ].join(' ');
            }
        } else if (isChapterPage) {
            if (chapterName) {
                return [
                    main,
                    ' %s Document - ',
                    chapterData.data.document.name!,
                    ' %s Chapter - ',
                    chapterName,
                ].join(' ');
            }
        } else if (isSectionPage) {
            if (sectionName) {
                return [
                    main,
                    ' %s Document - ',
                    sectionData.data.document.name!,
                    ' %s Chapter - ',
                    sectionData.data.chapter.name!,
                    ' %s Section - ',
                    sectionName,
                ].join(' ');
            }
        }

        return main;
    }

    const title = getTitle();

    return <Subheader title={title} />;
}

export default AdminSubheader;
