import React from 'react';

import classnames from 'classnames/bind';

import { ReactComponent as LockIcon } from '@ICONS/lock-icon.svg';

import styles from './Lock.module.scss';

const cx: CX = classnames.bind(styles);

const sizeMap = {
    small: {
        icon: 22,
        body: 28,
    },
    medium: {
        icon: 26,
        body: 36,
    },
    large: {
        icon: 34,
        body: 46,
    },
    free: {
        icon: 30,
        body: '100%',
    },
};

function Lock(props: Props) {
    const { size } = props;

    const { icon, body } = sizeMap[size];

    return (
        <div
            className={cx('lock', {
                small: size === 'small',
                medium: size === 'medium',
                free: size === 'free',
            })}
            style={{
                width: body,
                height: body,
            }}
        >
            <LockIcon
                width={icon}
                height={icon}
            />
        </div>
    );
}

type Props = {
    size: 'small' | 'medium' | 'large' | 'free'
};

export default Lock;
