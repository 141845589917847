import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import {
    documentGenerationStepsSlice,
    DocumentGenerationStepsState,
    DocumentGenerationStepsDispatchParamsApply,
    DocumentGenerationStepsStep,
} from '@REDUCERS';

function createDocumentGenerationStepsHook() {
    return function useDocumentGenerationSteps(equalityFn?: EqualityFn<DocumentGenerationStepsState>) {
        const state = useAppSelector((store) => store.documentGenerationSteps, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            apply: (params: DocumentGenerationStepsDispatchParamsApply) => dispatch(documentGenerationStepsSlice.actions.apply(params)),
        }), [dispatch]);

        const services = useMemo(() => ({
            getStepData<T extends DocumentGenerationStepsStep>(stepId: GenerationSteps) : T {
                return state.steps.find((step) => (
                    step.id === stepId
                )) as T;
            },
        }), [state]);

        return {
            state,
            actions,
            services,
        };
    };
}

export default createDocumentGenerationStepsHook();
