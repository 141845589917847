import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { deleteVendorModalSlice, DeleteVendorModalDispatchParams, DeleteVendorModalState } from '@REDUCERS';

function createDeleteVendorModalHook() {
    return function useDeleteVendorModal(equalityFn?: EqualityFn<DeleteVendorModalState>) {
        const state = useAppSelector((store) => store.deleteVendorModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DeleteVendorModalDispatchParams) => dispatch(deleteVendorModalSlice.actions.open(params)),
            close: () => dispatch(deleteVendorModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDeleteVendorModalHook();
