import React, {
    RefObject,
    useEffect,
    useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import useAuth from '@HOOKS/useAuth';

import BasicInputValidatable from '@COMPONENTS/COMMON/inputs/input/BasicInput/BasicInputValidatable.component';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './LoginForm.module.scss';
import TextButton from '@COMPONENTS/COMMON/buttons/TextButton';

const cx: CX = classnames.bind(styles);

enum Form {
    EMAIL = 'email',
    PASSWORD = 'password',
}

yup.setLocale({
    mixed: {
        required: () => 'This field is required',
    },
});

const schema = yup.object({
    [Form.EMAIL]: yup.string().email().required(),
    [Form.PASSWORD]: yup.string().required(),
});

export type LoginFormValues = {
    [Form.EMAIL]: string;
    [Form.PASSWORD]: string;
};

interface LoginProps {
    setIsLogin: any;
}

function LoginForm({ setIsLogin }: LoginProps) {
    const {
        actions,
        state: {
            loginData: {
                isLoading, isError, error,
            },
        },
    } = useAuth();

    const [search] = useSearchParams();

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const defaultValues = {
        [Form.EMAIL]: search.get('email') || '',
        [Form.PASSWORD]: search.get('password') || '',
    };

    const { handleSubmit, control } = useForm<LoginFormValues>({
        defaultValues: {
            [Form.EMAIL]: defaultValues[Form.EMAIL],
            [Form.PASSWORD]: defaultValues[Form.PASSWORD],
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError)?.status === 403) {
                notify.error('Wrong email or password!', {
                    toastId: 'wrong-email-or-password',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'something-wrong',
                });
            }
        }
    }, [isError, error]);

    function onSave(formValues: LoginFormValues) {
        actions.login({
            email: formValues[Form.EMAIL],
            password: formValues[Form.PASSWORD],
        });
    }

    return (
        <div className={cx('login-form')}>
            <form onSubmit={handleSubmit(onSave)}>
                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<LoginFormValues>
                        label="Email"
                        placeholder="Email"
                        validation={{
                            name: Form.EMAIL,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<LoginFormValues>
                        label="Password"
                        placeholder="Password"
                        validation={{
                            name: Form.PASSWORD,
                            control,
                        }}
                    />
                    <TextButton
                        title="Forgot Password?"
                        onClick={() => {
                            setIsLogin(false);
                        }}
                        color={COLOR['marriott-dark-grey']}
                        fontSize={12}
                        fontWeight={700}
                        padding="0"
                    />
                </div>
                <HiddenFormButton ref={formButtonRef} />
            </form>
            <BasicButton
                title="Login"
                isProcessing={isLoading}
                style={{
                    height: 40,
                    width: '100%',
                    fontSize: 14,
                    backgroundColor: COLOR['app-green'],
                }}
                onClick={() => {
                    formButtonRef.current?.click();
                }}
            />
        </div>
    );
}

export default LoginForm;
