import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { sectionHistoryModalSlice, SectionHistoryModalDispatchParams, SectionHistoryModalState } from '@REDUCERS';

function createHistoryModalHook() {
    return function useSectionHistoryModal(equalityFn?: EqualityFn<SectionHistoryModalState>) {
        const state = useAppSelector((store) => store.sectionHistoryModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: SectionHistoryModalDispatchParams) => dispatch(sectionHistoryModalSlice.actions.open(params)),
            close: () => dispatch(sectionHistoryModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createHistoryModalHook();
