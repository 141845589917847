import React from 'react';

import classNames from 'classnames/bind';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './SectionStickyHeader.module.scss';

const cx: CX = classNames.bind(styles);

function SectionStickyHeader(props: Props) {
    const { section } = props;

    return (
        <StickyHeader
            top={1}
            zIndex={6}
        >
            <div className={cx('section-sticky-header')}>
                <div className={cx('title')}>
                    <span className={cx('title-prefix')}>
                        Section
                    </span>
                    {section.name}
                </div>
            </div>
        </StickyHeader>
    );
}

type Props = {
    section: AdminSingleChapterDataSection,
};

export default SectionStickyHeader;
