import React, {
    MutableRefObject, useRef, useState,
} from 'react';

import { SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionDataVariant,
    AdminSingleSectionData, AdminSingleSectionDataVariantVersion, AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import VariantBodySidebar from '@PAGES/admin/edit-section/components/VariantBodySidebar';
import VariantEditor from '@PAGES/admin/edit-section/components/editors/VariantEditor';
import VariantBodyFooter from '@PAGES/admin/edit-section/components/VariantBodyFooter';

import styles from './VariantBody.module.scss';

const cx: CX = classnames.bind(styles);

function VariantBody(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    const editorRef: MutableRefObject<string> = useRef('');
    const [contentIsEmpty, setContentIsEmpty] = useState<boolean>(true);

    const { versions } = variant;

    const [selectedVersion, setSelectedVersion] = useState<AdminSingleSectionDataVariantVersion | undefined>(undefined);

    function getEditorContent() {
        return editorRef.current;
    }

    function onVersionChange(val: SingleValue<any>) {
        const version = versions.find((v) => v.id === val.value);

        setSelectedVersion(version);
    }

    return (
        <div className={cx('variant-body')}>
            <div className={cx('body-wrapper')}>
                <div className={cx('editor')}>
                    <div className={cx('editor-wrapper')}>
                        <VariantEditor
                            variant={variant}
                            selectedVersion={selectedVersion}
                            sectionData={sectionData}
                            onEditorChange={(content: string) => {
                                editorRef.current = content;

                                setContentIsEmpty(isContentEmptyCheck(content));
                            }}
                        />
                    </div>
                </div>
                <div className={cx('body-sidebar-wrapper')}>
                    <VariantBodySidebar
                        contentIsEmpty={contentIsEmpty}
                        variant={variant}
                        selectedVersion={selectedVersion}
                        sectionData={sectionData}
                        sectionMeta={sectionMeta}
                        getEditorContent={() => getEditorContent()}
                        onVersionChange={(val: any) => onVersionChange(val)}
                    />
                </div>
            </div>

            <div className={cx('footer-wrapper')}>
                <VariantBodyFooter
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantBody;
