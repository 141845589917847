import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';

import { AdminSingleDocument, Role, useCreateRole } from '@SERVICES';

import useAuth from '@HOOKS/useAuth';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewChapterModal from '@HOOKS/store/modals/useNewChapterModal';

import DocumentName from '@PAGES/admin/document/components/DocumentName';
import Chapter from '@PAGES/admin/document/components/Chapter';
import DocumentImage from '@PAGES/admin/document/components/DocumentImage';
import DocumentTags from '@PAGES/admin/document/components/DocumentTags';
import ChaptersStickyHeader from '@PAGES/admin/document/components/ChaptersStickyHeader';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import DocumentPermissions from '@PAGES/admin/document/components/DocumentPermissions';

import DocumentPermissionsByRole from '@PAGES/admin/document/components/DocumentPermissionsByRole';
import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

interface OnChangeData {
    fullArray: any[],

    index: number,

    chapterId: number,

    sectionId: number,
}

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

function Content(props: Props) {
    const { documentData } = props;

    const { state: { user, permissions } } = useAuth();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canEditDocumentDownloadPermissions = permissions.global.some((item) => item.codename === GLOBAL_PERMISSIONS.CAN_EDIT_DOCUMENT_DOWNLOAD_PERMISSIONS);

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions: newChapterModalActions,
    } = useNewChapterModal(() => true);

    const [updateRole] = useCreateRole();

    const [isDocumentPermissionsByRoleOpen, setDocumentPermissionsByRoleOpen] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [isDocumentPermissionsOpen, setDocumentPermissionsOpen] = useState<boolean>(false);
    const [isDocumentImageOpen, setDocumentImageOpen] = useState<boolean>(false);
    const [isDocumentTagsOpen, setDocumentTagsOpen] = useState<boolean>(false);

    const [rolesPermissions, setRolesPermissions] = useState<Role[]>(documentData.data.roles);

    const onChangePermission = (
        {
            fullArray,
            index,
            chapterId,
            sectionId,
        }: OnChangeData,
    ) => {
        setOpen(false);
        if (fullArray) {
            setRolesPermissions(fullArray);
            return;
        }

        let roleId = 0;
        let value = false;

        setRolesPermissions((prevState: any[]) => {
            let array: Role[] = [];
            array = JSON.parse(JSON.stringify(prevState));
            roleId = array[index].roleId;
            if (!array[index]?.chapters[chapterId]) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                array[index].chapters = {
                    ...array[index].chapters,
                    [chapterId]: { chapterId, value: false, sections: {} },
                };
            }
            if (sectionId && !array[index]?.chapters[chapterId]?.sections[sectionId]) {
                array[index].chapters[chapterId].sections = {
                    ...array[index].chapters[chapterId].sections,
                    [sectionId]: { sectionId, value: false },
                };
            }
            if (sectionId) {
                value = !array[index].chapters[chapterId].sections[sectionId].value;
                array[index].chapters[chapterId].sections[sectionId].value = value;
                let postData: {
                    role_id: number,
                    value: boolean,
                    section_id?: number,
                    chapter_id?: number
                } = {
                    role_id: roleId,
                    section_id: sectionId,
                    value,
                };
                updateRole(postData);
                if (value) {
                    if (!array[index].chapters[chapterId].value) {
                        postData = {
                            role_id: roleId,
                            chapter_id: chapterId,
                            value: true,
                        };
                        updateRole(postData);
                    }
                    array[index].chapters[chapterId].value = true;
                }
            } else {
                value = !array[index].chapters[chapterId].value;
                array[index].chapters[chapterId].value = value;
                let postData: {
                    role_id: number,
                    value: boolean,
                    section_id?: number,
                    chapter_id?: number
                } = {
                    role_id: roleId,
                    chapter_id: chapterId,
                    value,
                };
                updateRole(postData);
                if (!value) {
                    const keys = Object.keys(array[index].chapters[chapterId].sections);
                    if (keys) {
                        keys.forEach((key: any) => {
                            if (array[index].chapters[chapterId].sections[key].value) {
                                postData = {
                                    role_id: roleId,
                                    section_id: key,
                                    value: false,
                                };
                                updateRole(postData);
                            }
                            array[index].chapters[chapterId].sections[key].value = false;
                        });
                    }
                }
            }

            return [...array];
        });
    };

    return (
        <div className={cx('content')}>
            <DocumentName
                documentData={documentData}
            />
            {(user!.id === documentData.data.owner.id || permissions.isSuperuser || canEditDocumentDownloadPermissions) && (
                <div className={cx('document-permissions-wrapper')}>
                    <StickyHeader top={top}>
                        <div className={cx('document-permissions-subheader', 'container')}>
                            <div className={cx('title')}>
                                Document download permissions by Role
                            </div>
                            <OpenCloseButton
                                isOpen={isDocumentPermissionsByRoleOpen}
                                backgroundColor={COLOR['marriott-light-grey']}
                                iconColor={COLOR['app-white']}
                                onClick={() => {
                                    setOpen(true);
                                    setDocumentPermissionsByRoleOpen((val) => !val);
                                }}
                            />
                        </div>
                    </StickyHeader>

                    {
                        isDocumentPermissionsByRoleOpen
                        && (
                            <DocumentPermissionsByRole
                                roles={rolesPermissions}
                                setRolesPermissions={onChangePermission}
                                chapters={documentData.data.chapters}
                                open={open}
                            />
                        )
                    }
                </div>
            )}
            {
                (user!.id === documentData.data.owner.id || permissions.isSuperuser)
                && (
                    <div className={cx('document-permissions-wrapper')}>
                        <StickyHeader top={top}>
                            <div className={cx('document-permissions-subheader', 'container')}>
                                <div className={cx('title')}>
                                    Document management permissions
                                </div>
                                <OpenCloseButton
                                    isOpen={isDocumentPermissionsOpen}
                                    backgroundColor={COLOR['marriott-light-grey']}
                                    iconColor={COLOR['app-white']}
                                    onClick={() => {
                                        setDocumentPermissionsOpen((val) => !val);
                                    }}
                                />
                            </div>
                        </StickyHeader>

                        {
                            isDocumentPermissionsOpen
                            && (
                                <DocumentPermissions
                                    documentData={documentData.data}
                                />
                            )
                        }
                    </div>
                )
            }

            <div className={cx('document-image-wrapper')}>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('document-image-subheader', 'container')}>
                        <div className={cx('title')}>
                            Document image
                        </div>
                        <OpenCloseButton
                            isOpen={isDocumentImageOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setDocumentImageOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isDocumentImageOpen
                    && (
                        <DocumentImage
                            documentData={documentData}
                        />
                    )
                }
            </div>

            <div className={cx('document-tags-wrapper')}>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('document-tags-subheader', 'container')}>
                        <div className={cx('title')}>
                            Document tags
                        </div>
                        <OpenCloseButton
                            isOpen={isDocumentTagsOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setDocumentTagsOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isDocumentTagsOpen
                    && (
                        <DocumentTags documentData={documentData} />
                    )
                }
            </div>

            <ChaptersStickyHeader
                top={top}
                documentData={documentData}
            />

            <div className="container max-constraint center">
                <div className={cx('grid')}>
                    <div className={cx('grid-item')}>
                        <div className={cx('add-chapter-button')}>
                            <AddButton
                                locked={!canEditDocument}
                                title="New chapter"
                                fontSize={36}
                                onClick={() => {
                                    newChapterModalActions.open({
                                        documentId: documentData.data.id,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    {
                        documentData!.data.chapters.map((chapter, i) => {
                            const chapterKey = chapter.id;

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('grid-item')}
                                >
                                    <Chapter
                                        documentId={documentData.data.id}
                                        documentOwnerId={documentData.data.owner.id}
                                        permissions={documentData.meta.permissions}
                                        chapter={chapter}
                                        index={i}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default Content;
