import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as WarningIcon } from '@ICONS/warning-icon.svg';

import styles from './Warning.module.scss';

const cx: CX = classnames.bind(styles);

function Warning(props: Props) {
    const { text } = props;

    return (
        <div className={cx('warning')}>
            <div className={cx('icon')}>
                <WarningIcon
                    width={24}
                    height={24}
                    fill={COLOR['app-yellow']}
                />
            </div>
            <div className={cx('text')}>
                {text}
            </div>
        </div>
    );
}

Warning.propTypes = {
    text: PropTypes.string.isRequired,
};

type Props = InferProps<typeof Warning.propTypes>;

export default Warning;
