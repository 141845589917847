import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminVendorsData } from '@SERVICES';

import useGlobalPermissions from '@HOOKS/useGlobalPermissions';
import useNewVendorModal from '@HOOKS/store/modals/useNewVendorModal';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import Vendor from '@PAGES/admin/vendors/components/Vendor';
import VendorsStickyHeader from '@PAGES/admin/vendors/components/VendorsStickyHeader';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const { top, vendorsData } = props;

    const {
        canManageVendors,
    } = useGlobalPermissions();

    const {
        actions: newVendorModalActions,
    } = useNewVendorModal();

    const [openVendorsIds, setOpenVendorsIds] = useState<Set<number>>(new Set());

    return (
        <div className={cx('content')}>
            <div className={cx('page-name', 'container')}>
                Vendors
            </div>

            <VendorsStickyHeader
                top={top}
                vendorsData={vendorsData}
                setOpenVendorsIds={setOpenVendorsIds}
            />

            <div className={cx('add-vendor-button', 'container')}>
                <AddButton
                    locked={!canManageVendors}
                    title="New vendor"
                    fontSize={36}
                    onClick={() => {
                        newVendorModalActions.open();
                    }}
                />
            </div>

            {
                vendorsData.length > 0
                    ? (
                        <div className="container">
                            <div className={cx('vendors-list')}>
                                {
                                    vendorsData.map((vendor) => {
                                        const key = vendor.id;

                                        return (
                                            <div
                                                key={key}
                                                className={cx('vendor-wrapper', {
                                                    collapsed: !openVendorsIds.has(vendor.id),
                                                })}
                                            >
                                                <Vendor
                                                    vendor={vendor}
                                                    openVendorsIds={openVendorsIds}
                                                    setOpenVendorsIds={setOpenVendorsIds}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <div className={cx('vendors-not-found', 'container')}>
                            No vendors
                        </div>
                    )
            }
        </div>
    );
}

Content.propTypes = {
    top: PropTypes.number.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    vendorsData: AdminVendorsData,
};

export default React.memo(Content, (prevProps, nextProps) => prevProps.vendorsData === nextProps.vendorsData);
