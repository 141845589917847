import React from 'react';
import ReactDOM from 'react-dom/client';

import 'normalize.css';

import '@STYLES/global.scss';

import App from './App';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    // TODO use when dnd fix
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
);

reportWebVitals();
