import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import invalidateData from '../helpers/invalidateData';
import { sectionsAdminApi } from './sections.admin-api';
import { chaptersAdminApi } from './chapters.admin-api';

interface AddConditionApiResponse {
    id: number;

    brand: {
        id: number;

        name: string;
    } | null;

    region: {
        id: number;

        name: string;
    } | null;

    area: {
        id: number;

        name: string;
    } | null;

    country: {
        id: number;

        name: string;
    } | null;

    property_type: {
        id: number;

        name: string;
    } | null;

    section: number;
}

type AddConditionParams = {
    brandId: number | null;

    regionId: number | null;

    areaId: number | null;

    countryId: number | null;

    propertyTypeId: number | null;

    sectionId: number;
};

type DeleteConditionParams = {
    id: number;

    sectionId: number;
};

function updateSingleChapter(
    {
        dispatch, sectionId, chapterId, hasMandatoryConditions,
    }: {
        dispatch: ThunkDispatch<any, any, AnyAction>;
        sectionId: number;
        chapterId: number;
        hasMandatoryConditions: boolean;
    },
) {
    dispatch(
        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
            data: {
                ...draft.data,
                sections: draft.data.sections.map((section) => {
                    if (section.id === sectionId) {
                        return {
                            ...section,
                            hasMandatoryConditions,
                        };
                    }

                    return section;
                }),
            },
            meta: draft.meta,
        })),
    );
}

export const sectionsMandatoryConditionsAdminApi = createApi({
    reducerPath: 'sections-mandatory-conditions-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        addCondition: builder.mutation<AddConditionApiResponse, AddConditionParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('brand', params?.brandId?.toString() || '');
                formData.append('region', params?.regionId?.toString() || '');
                formData.append('area', params?.areaId?.toString() || '');
                formData.append('country', params?.countryId?.toString() || '');
                formData.append('property_type', params?.propertyTypeId?.toString() || '');
                formData.append('section', params.sectionId.toString());

                return ({
                    url: '/conditions/',
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { sectionId } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD CONDITION TO SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                mandatoryConditions: [...draft.data.mandatoryConditions, {
                                    id: queryData.id,
                                    brand: queryData.brand,
                                    region: queryData.region,
                                    area: queryData.area,
                                    country: queryData.country,
                                    propertyType: queryData.property_type,
                                }],
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // ADD CONDITION TO SINGLE CHAPTER
                        updateSingleChapter({
                            dispatch,
                            sectionId,
                            chapterId: data.data.chapter.id,
                            hasMandatoryConditions: data.data.mandatoryConditions.length > 0,
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        deleteCondition: builder.mutation<void, DeleteConditionParams>({
            query: (params) => ({
                url: `/conditions/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    id,
                    sectionId,
                } = args;

                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE CONDITION FROM SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                mandatoryConditions: draft.data.mandatoryConditions.filter((con) => con.id !== id),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // DELETE CONDITION FROM SINGLE CHAPTER
                        updateSingleChapter({
                            dispatch,
                            sectionId,
                            chapterId: data.data.chapter.id,
                            hasMandatoryConditions: data.data.mandatoryConditions.length > 0,
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useAddConditionMutation: useAddSectionMandatoryCondition,
    useDeleteConditionMutation: useDeleteSectionMandatoryCondition,
} = sectionsMandatoryConditionsAdminApi;
