import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newTagModalSlice, NewTagModalDispatchParams, NewTagModalState } from '@REDUCERS';

function createNewTagModalHook() {
    return function useNewTagModal(equalityFn?: EqualityFn<NewTagModalState>) {
        const state = useAppSelector((store) => store.newTagModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: NewTagModalDispatchParams) => dispatch(newTagModalSlice.actions.open(params)),
            close: () => dispatch(newTagModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewTagModalHook();
