import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import {
    previewSectionBaseTextModalSlice,
    PreviewSectionBaseTextModalState,
    PreviewSectionBaseTextModalDispatchParams,
} from '@REDUCERS';

function createPreviewSectionBaseTextModalHook() {
    return function usePreviewSectionBaseTextModal(equalityFn?: EqualityFn<PreviewSectionBaseTextModalState>) {
        const state = useAppSelector((store) => store.previewSectionBaseTextModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PreviewSectionBaseTextModalDispatchParams) => dispatch(previewSectionBaseTextModalSlice.actions.open(params)),
            close: () => dispatch(previewSectionBaseTextModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPreviewSectionBaseTextModalHook();
