import React from 'react';

import classNames from 'classnames/bind';

import { AdminLogs } from '@SERVICES';

import { LogsActions, logsActionsMap, RESULTS_PAGE_SIZE } from '@CONSTANTS/LOGS.constant';
import getDate from '@HELPERS/getDate';

import styles from './Table.module.scss';

const cx: CX = classNames.bind(styles);

function Table(props: Props) {
    const {
        page,
        logs,
    } = props;

    const { results } = logs;

    return (
        <div className={cx('table')}>
            <table>
                <thead>
                    <tr>
                        <th>
                            <div className={cx('header-cell')}>
                                №
                            </div>
                        </th>
                        <th>
                            <div className={cx('header-cell')}>
                                User
                            </div>
                        </th>
                        <th>
                            <div className={cx('header-cell')}>
                                Action
                            </div>
                        </th>
                        <th>
                            <div className={cx('header-cell')}>
                                Date
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        results.map((log, i) => {
                            const key = log.id;

                            return (
                                <tr
                                    key={key}
                                >
                                    <td className={cx('column-number')}>
                                        {(i + 1) + (RESULTS_PAGE_SIZE * (page - 1))}
                                    </td>
                                    <td className={cx('user')}>
                                        {log.user.fullName}
                                    </td>
                                    <td className={cx('action')}>
                                        {logsActionsMap[log.action as LogsActions]}
                                    </td>
                                    <td className={cx('date')}>
                                        {getDate(log.createdAt)}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

type Props = {
    page: number,
    logs: AdminLogs,
};

export default Table;
