import { AdminVendors } from '@SERVICES';

export default function getFilteredBySearchData(
    vendorsData: AdminVendors,
    searchValue: string,
): AdminVendors {
    const val = searchValue.toLowerCase().trim();

    if (vendorsData.data && val !== '') {
        const { data } = vendorsData;

        const filtered = data.filter((vendor) => {
            try {
                return vendor.name.toLowerCase().match(
                    val,
                );
            } catch (e) {
                return false;
            }
        });

        return {
            data: filtered,
        };
    }

    return vendorsData;
}
