import React, { ChangeEvent, useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './BasicInput.module.scss';

const cx: CX = classnames.bind(styles);

function BasicInput(props: Props) {
    const {
        value, onChange, placeholder, label,
    } = props;

    const inputId = useId();

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
    }

    return (
        <div className={cx('basic-input')}>
            <label htmlFor={inputId}>
                {
                    label
                    && (
                        <div className={cx('input-label')}>
                            {label}
                        </div>
                    )
                }
                <input
                    id={inputId}
                    type="text"
                    value={value}
                    onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === '') {
                            e.target.setAttribute('readonly', String(true));
                        } else {
                            e.target.removeAttribute('readonly');
                        }
                    }}
                    placeholder={placeholder}
                    autoComplete="off"
                    readOnly
                    onClick={(e: any) => {
                        e.target.removeAttribute('readonly');
                    }}
                    onBlur={(e) => {
                        e.target.setAttribute('readonly', String(true));
                    }}
                />
            </label>
        </div>
    );
}

BasicInput.defaultProps = {
    placeholder: '',
    label: '',
};

BasicInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
};

type Props = InferProps<typeof BasicInput.propTypes> & typeof BasicInput.defaultProps;

export default BasicInput;
