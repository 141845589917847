import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import styles from './CircularProgressIndicator.module.scss';

const cx: CX = classnames.bind(styles);

function CircularProgressIndicator(props: Props) {
    const { color, size, thickness } = props;

    return (
        <div className={cx('circular-progress-indicator')}>
            <div
                style={{
                    width: size,
                    height: size,
                }}
                className={cx('ring')}
            >
                <div
                    style={{
                        border: `${thickness}px solid transparent`,
                        borderTopColor: color,
                    }}
                />
                <div
                    style={{
                        border: `${thickness}px solid transparent`,
                        borderTopColor: color,
                    }}
                />
                <div
                    style={{
                        border: `${thickness}px solid transparent`,
                        borderTopColor: color,
                    }}
                />
                <div
                    style={{
                        border: `${thickness}px solid transparent`,
                        borderTopColor: color,
                    }}
                />
            </div>
        </div>
    );
}

CircularProgressIndicator.defaultProps = {
    color: COLOR['app-white'],
    size: 35,
    thickness: 3,
};

CircularProgressIndicator.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    thickness: PropTypes.number,
};

type Props = InferProps<typeof CircularProgressIndicator.propTypes> & typeof CircularProgressIndicator.defaultProps;

export default CircularProgressIndicator;
