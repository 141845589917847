import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { deleteUserModalSlice, DeleteUserModalDispatchParams, DeleteUserModalState } from '@REDUCERS';

function createDeleteUserModalHook() {
    return function useDeleteUserModal(equalityFn?: EqualityFn<DeleteUserModalState>) {
        const state = useAppSelector((store) => store.deleteUserModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DeleteUserModalDispatchParams) => dispatch(deleteUserModalSlice.actions.open(params)),
            close: () => dispatch(deleteUserModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDeleteUserModalHook();
