import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';
import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';

import styles from './BaseText.module.scss';

const cx: CX = classNames.bind(styles);

function BaseText(props: Props) {
    const {
        section,
        selectedSectionsIds,
        setSelectedSectionsIds,
    } = props;

    const { isLandscape, baseText } = section;

    const oldVersion = baseText.latestVersion?.body || null;
    const newVersion = baseText.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('base-text')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={selectedSectionsIds.has(section.id)}
                        onChange={(val: boolean) => {
                            const set = new Set(selectedSectionsIds);

                            if (val) {
                                set.add(section.id);
                            } else {
                                set.delete(section.id);
                            }

                            setSelectedSectionsIds(set);
                        }}
                    />
                </div>
            </div>
            <div className={cx('base-text-body')}>
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={isLandscape}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

BaseText.propTypes = {
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseText.propTypes> & {
    section: AdminSingleChapterDataSection,
};

export default BaseText;
