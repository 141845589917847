import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Drawer.module.scss';

const cx: CX = classnames.bind(styles);

function Drawer(props: Props) {
    const { isOpen, onClose, children } = props;

    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(isOpen);
    }, [isOpen]);

    function handleClose() {
        setShow(false);

        setTimeout(() => {
            onClose();
        }, 400);
    }

    if (!isOpen) return null;

    return (
        <div className={cx('drawer')}>
            <div
                tabIndex={0}
                role="button"
                className={cx('bg', {
                    show,
                })}
                onClick={handleClose}
            />

            <div
                className={cx('drawer-wrapper', {
                    show,
                })}
            >
                {children({
                    onClose: handleClose,
                })}
            </div>
        </div>
    );
}

Drawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Drawer.propTypes>;

export default Drawer;
