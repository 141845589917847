import React, { RefObject, useEffect, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { notify } from '@NOTIFICATION/Notificator';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import BaseModal from '@MODALS/BaseModal';

import styles from './NewUserFormModal.module.scss';
import BasicInputValidatable from '@COMPONENTS/COMMON/inputs/input/BasicInput/BasicInputValidatable.component';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useCreateUser } from '@SERVICES';

const cx: CX = classnames.bind(styles);

enum Form {
    FULL_NAME = 'fullName',
    EMAIL = 'email',
    COUNTRY = 'country',
    MOBILE_PHONE = 'mobilePhone',
    OFFICE_PHONE = 'officePhone',

}

yup.setLocale({
    mixed: {
        required: () => 'Field is required',
    },
});

const schema = yup.object({
    [Form.FULL_NAME]: yup.string(),
    [Form.EMAIL]: yup.string().email().required(),
    [Form.COUNTRY]: yup.string(),
    [Form.MOBILE_PHONE]: yup.string(),
    [Form.OFFICE_PHONE]: yup.string(),
});

export type FormValues = {
    [Form.FULL_NAME]: string;
    [Form.EMAIL]: string;
    [Form.COUNTRY]: string;
    [Form.MOBILE_PHONE]: string;
    [Form.OFFICE_PHONE]: string;
};

function EditUserFormModal(props: Props) {
    const {
        onClose,
        modalTitle,
    } = props;

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const [createUser, {
        isLoading, isSuccess, isError, error,
    }] = useCreateUser();

    useEffect(() => {
        if (isSuccess) {
            notify.info('New user has been created', {
                toastId: 'create-user',
            });

            onClose();
        }
    }, [isSuccess, onClose]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-document-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-document-error',
                });
            }
        }
    }, [isError, error]);

    const { handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            [Form.FULL_NAME]: '',
            [Form.EMAIL]: '',
            [Form.COUNTRY]: '',
            [Form.MOBILE_PHONE]: '',
            [Form.OFFICE_PHONE]: '',
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const onSave = (formValues: any) => {
        createUser({
            full_name: formValues.fullName,
            email: formValues.email,
            country: formValues.country,
            mobile_phone: formValues.mobilePhone,
            office_phone: formValues.officePhone,
        });
    };

    return (
        <BaseModal
            style={{
                top: '30%',
                transform: 'translate(-50%, -30%)',
            }}
            onClose={onClose}
        >
            <div className={cx('form')}>
                <div className={cx('title')}>
                    {modalTitle}
                </div>
                <form onSubmit={handleSubmit(onSave)}>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            label="Full Name"
                            placeholder="Full Name"
                            validation={{
                                name: Form.FULL_NAME,
                                control,
                            }}
                        />
                    </div>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            label="Email"
                            placeholder="Email"
                            validation={{
                                name: Form.EMAIL,
                                control,
                            }}
                        />
                    </div>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            label="Country"
                            placeholder="Country"
                            validation={{
                                name: Form.COUNTRY,
                                control,
                            }}
                        />
                    </div>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            label="Mobile Phone"
                            placeholder="Mobile Phone"
                            validation={{
                                name: Form.MOBILE_PHONE,
                                control,
                            }}
                        />
                    </div>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            label="Office Phone"
                            placeholder="Office Phone"
                            validation={{
                                name: Form.OFFICE_PHONE,
                                control,
                            }}
                        />
                    </div>
                    <HiddenFormButton ref={formButtonRef} />
                </form>
                <BasicButton
                    title="Save"
                    isProcessing={isLoading}
                    style={{
                        height: 40,
                        width: '100%',
                        fontSize: 14,
                        backgroundColor: COLOR['app-green'],
                    }}
                    onClick={() => {
                        formButtonRef.current?.click();
                    }}
                />
            </div>
        </BaseModal>
    );
}

EditUserFormModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
};

type Props = InferProps<typeof EditUserFormModal.propTypes>;

export default EditUserFormModal;
