import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@STORE/index';

import { chapterNameModalSlice, ChapterNameModalDispatchParams, ChapterNameModalState } from '@REDUCERS';

function createChapterNameModalHook() {
    return function useChapterNameModal(equalityFn?: EqualityFn<ChapterNameModalState>) {
        const state = useAppSelector((store) => store.chapterNameModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: ChapterNameModalDispatchParams) => dispatch(chapterNameModalSlice.actions.open(params)),
            close: () => dispatch(chapterNameModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createChapterNameModalHook();
