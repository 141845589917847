import React, { useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { useChapterPreview } from '@SERVICES';

import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import { SelectedOptions } from '@MODALS/Previews/components/BasicFilters/BasicFilters.component';
import Content from '@MODALS/Previews/PreviewChapterModal/compontents/Content';
import SidebarHeader from '@MODALS/Previews/components/SidebarHeader';
import BasicFilters from '@MODALS/Previews/components/BasicFilters';
import getFilteredByOptionsPreviewVariants from '@MODALS/Previews/helpers/getFilteredByOptionsPreviewVariants';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const {
        chapterData,
        showUnpublished,
    } = props;

    const {
        data, isLoading, isError, error,
    } = useChapterPreview(
        chapterData.id,
    );

    const previewData = data?.data;
    const previewMeta = data?.meta;

    const [view, setView] = useState<'info' | 'filters'>('info');

    const [isDefaultOnly, setDefaultOnly] = useState<boolean>(false);

    const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
        brand: null,
        region: null,
        area: null,
        country: null,
        propertyType: null,
    });

    const filters = useMemo(() => {
        if (!previewMeta) return previewMeta;

        const { filters: fi } = previewMeta;

        function transform(properties: any[]) {
            return properties.map((property) => ({
                label: property.name,
                value: property.id,
            }));
        }

        return {
            brands: transform(fi.brands),
            regions: transform(fi.regions),
            areas: transform(fi.areas),
            countries: transform(fi.countries),
            propertyTypes: transform(fi.propertyTypes),
        };
    }, [previewMeta]);

    const filteredByOptionsData = useMemo(() => {
        if (!previewData) return previewData;

        const optionsIsNotSelected = (
            selectedOptions.brand === null
            && selectedOptions.region === null
            && selectedOptions.area === null
            && selectedOptions.country === null
            && selectedOptions.propertyType === null
        );

        return {
            ...previewData,
            sections: previewData.sections.map((section) => {
                const { variants } = section;

                return {
                    ...section,
                    variants: getFilteredByOptionsPreviewVariants(variants, selectedOptions),
                };
            }).filter((section) => (
                optionsIsNotSelected
                    ? true
                    : section.variants.length > 0
            )),
        };
    }, [previewData, selectedOptions]);

    const filteredByDefaultData = useMemo(() => {
        if (!previewData || !isDefaultOnly) return previewData;

        return {
            ...previewData,
            sections: previewData.sections.map((section) => ({
                ...section,
                variants: section.variants.filter((v) => v.isDefault),
            })).filter((section) => section.variants.length > 0),
        };
    }, [previewData, isDefaultOnly]);

    return (
        <div className={cx('modal-body')}>
            <DataLoader
                isLoading={isLoading}
                isError={isError}
                error={error}
            >
                <div className={cx('body')}>
                    <div
                        className={cx('pseudo-sidebar', {
                            'show-filters': view === 'filters',
                        })}
                    >
                        <SidebarHeader
                            view={view}
                            setView={setView}
                        />

                        {
                            view === 'filters'
                            && (
                                <BasicFilters
                                    isDefaultOnly={isDefaultOnly}
                                    setDefaultOnly={setDefaultOnly}
                                    filters={filters!}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                            )
                        }
                    </div>

                    <Content
                        previewData={isDefaultOnly ? filteredByDefaultData! : filteredByOptionsData!}
                        showUnpublished={showUnpublished}
                        view={view}
                    />
                </div>
            </DataLoader>
        </div>
    );
}

ModalBody.propTypes = {
    showUnpublished: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    chapterData: AdminSingleChapterData,
};

export default ModalBody;
