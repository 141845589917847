import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';
import { FileWithPath, useDropzone } from 'react-dropzone';

import COLOR from '@CONSTANTS/COLOR.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';

import styles from './DropFileInput.module.scss';

const cx: CX = classnames.bind(styles);

const baseStyle = {
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 20px',
    borderWidth: 1,
    borderRadius: DESIGN['main-border-radius'],
    borderColor: COLOR['marriott-light-grey'],
    backgroundColor: COLOR['marriott-light-grey-2'],
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out, background-color .24s ease-in-out',
};

const focusedStyle = {
    borderColor: COLOR['marriott-primary'],
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: COLOR['marriott-orange'],
};

function DropFileInput(props: Props) {
    const { title, onDrop } = props;

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple: false,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        onDrop: (acceptedFiles: Array<FileWithPath>) => {
            onDrop(acceptedFiles);
        },
    });

    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
    ]);

    return (
        <div className={cx('drop-file-input')}>
            <div
                {...getRootProps({ style })}
                className={cx('input')}
            >
                <input {...getInputProps()} />
                <div className={cx('title')}>
                    {title}
                </div>
            </div>
        </div>
    );
}

DropFileInput.propTypes = {
    title: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
};

type Props = InferProps<typeof DropFileInput.propTypes>;

export default DropFileInput;
