import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { addEditUserModalSlice, AddEditUserModalDispatchParams, AddEditUserModalState } from '@REDUCERS';

function createAddEditUserModalHook() {
    return function useAddEditUserModal(equalityFn?: EqualityFn<AddEditUserModalState>) {
        const state = useAppSelector((store) => store.addEditUserModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: AddEditUserModalDispatchParams) => dispatch(addEditUserModalSlice.actions.open(params)),
            close: () => dispatch(addEditUserModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createAddEditUserModalHook();
