import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';
import { AdminSingleChapterDataIntroductionText } from '@INTERFACES/admin-api/chapters';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';
import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';

import styles from './IntroductionText.module.scss';

const cx: CX = classnames.bind(styles);

function IntroductionText(props: Props) {
    const {
        introductionText,
        includeIntroductionText,
        setIncludeIntroductionText,
    } = props;

    const oldVersion = (
        introductionText.versions.length > 0
            ? introductionText.versions[introductionText.versions.length - 1].body
            : null
    );

    const newVersion = introductionText.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('introduction-text')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={includeIntroductionText}
                        onChange={() => {
                            setIncludeIntroductionText((val: boolean) => !val);
                        }}
                    />
                </div>
            </div>
            <div className={cx('introduction-text-body')}>
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={false}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

IntroductionText.propTypes = {
    includeIntroductionText: PropTypes.bool.isRequired,
    setIncludeIntroductionText: PropTypes.func.isRequired,
};

type Props = InferProps<typeof IntroductionText.propTypes> & {
    introductionText: AdminSingleChapterDataIntroductionText,
};

export default IntroductionText;
