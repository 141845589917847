import { DocumentGenerationStepsStep } from '@REDUCERS';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

export const stepsRules = {
    [GenerationSteps.DOCUMENT]: {
        isRequired: true,
    },
    // [GenerationSteps.BRAND]: {
    //     isRequired: true,
    // },
    // [GenerationSteps.REGION]: {
    //     isRequired: true,
    // },
    // [GenerationSteps.AREA]: {
    //     isRequired: true,
    // },
    // [GenerationSteps.COUNTRY]: {
    //     isRequired: true,
    // },
    // [GenerationSteps.TYPE]: {
    //     isRequired: true,
    // },
    [GenerationSteps.OPTIONS]: {
        isRequired: true,
    },
    [GenerationSteps.SELECT_CONTENT]: {
        isRequired: true,
    },
    [GenerationSteps.PREVIEW]: {
        isRequired: true,
    },
    [GenerationSteps.DOWNLOAD_DOCUMENT]: {
        isRequired: true,
    },
};

type ReturnType = { canActivate: boolean, redirect: GenerationSteps };

const DISABLED = false;

export default function validateSteps(steps: DocumentGenerationStepsStep[], current: GenerationSteps): ReturnType {
    let canActivate = true;
    let redirect = GenerationSteps.DOCUMENT;

    if (DISABLED) {
        return {
            canActivate: true,
            redirect: GenerationSteps.DOCUMENT,
        };
    }

    const currentIndex = steps.findIndex((s) => s.id === current);

    if (currentIndex === -1) return { canActivate, redirect };

    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        const rule = stepsRules[step.id];

        if (currentIndex > i && rule.isRequired && !step.isComplete) {
            canActivate = false;
            redirect = step.id;

            break;
        }
    }

    return {
        canActivate,
        redirect,
    };
}
