import React, { ChangeEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as SearchIcon } from '@ICONS/search-icon.svg';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './SearchInput.module.scss';

const cx: CX = classnames.bind(styles);

function SearchbarInput(props: Props) {
    const {
        isLoading,
        placeholder,
        value,
        onChange,
        onFocus,
    } = props;

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
    }

    return (
        <div className={cx('searchbar-input')}>
            <div className={cx('input-wrapper')}>
                <div className={cx('icon')}>
                    {
                        isLoading
                            ? (
                                <CircularProgressIndicator
                                    size={18}
                                    thickness={2}
                                    color={COLOR['marriott-light-grey']}
                                />
                            ) : (
                                <SearchIcon />
                            )
                    }
                </div>
                <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                {
                    value.length > 0
                    && (
                        <button
                            type="button"
                            className={cx('clear-input-button', 'clear-button')}
                            onClick={() => {
                                onChange('');
                            }}
                        >
                            &times;
                        </button>
                    )
                }
            </div>
        </div>
    );
}

SearchbarInput.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SearchbarInput.propTypes>;

export default SearchbarInput;
